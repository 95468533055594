<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]='subtext'
/>

<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-target icon-gradient bg-mean-fruit"></i>
      </div>
      <div style="margin-right: 8px;">
        ДРР
        <i class="fa fa-question-circle" aria-hidden="true"
          pTooltip="Анализ внутренней рекламы"></i>
      </div>
      <app-mp-video-play [videoLink]="getVideoLinkByPage('adv/company')" />
      <app-mp-ngb-range-datepicker [initialData]="selectedDate" (selectedDateEmitter)="dateChanged($event)" />
      <p-multiSelect
        (onChange)="onChange($event)"
        (onClear)="onClear()"
        [(ngModel)]="selectedNm"
        [maxSelectedLabels]="1"
        [options]="articles"
        [showClear]="true"
        [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '334px' }"
        defaultLabel="Артикул"
        emptyMessage="Артикулов не найдено"
        itemSize="30"
        optionLabel="name"
        selectedItemsLabel="Выбрано {0}" 
      />
      <p-inputNumber 
        [(ngModel)]="threshold" 
        [maxFractionDigits]="1"
        [min]="0" 
        [max]="100"
        prefix="%"
        placeholder="Предельно допустимый % ДРР"
        (ngModelChange)="onDrrPercentChange($event)"
      />
    </div>
  </div>
</div>
<div class="d-flex abc-cards">
  <app-mp-card-info *ngFor="let card of cards"
    [card]="card"
  >
  </app-mp-card-info>
  <ag-grid-angular
      (gridReady)="onGridReady($event)"
      (dragStopped)="onDragStopped($event)"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultDef"
      [rowData]="rowData"
      [rowHeight]="30"
      [pagination]="true"
      [paginationPageSize]="15"
      [domLayout]="'autoHeight'"
      [enableCellTextSelection]="true"
      [localeText]="localeTextForFilters"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      style="width: 100%;"
    />
</div>
