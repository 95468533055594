import { LocaleConfig } from 'ngx-daterangepicker-material';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { DecimalPipe } from '@angular/common';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';
import { DateSortFunc } from '../../../shared/utils/functions/date-sort-func';

const decimalPipe = new DecimalPipe('ru-RU')


export const DATEPICKER_CONFIG: LocaleConfig = {
  applyLabel: 'применить',
  direction: 'ltr',
  format: 'DD.MM.YYYY',
  daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  customRangeLabel: 'Свой диапазон',
  firstDay: 1,
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export const MAIN_TAB_COLUMN_DEFAULT_DEF = {
  flex: 1,
  minWidth: 20,
  maxWidth: 400,
  resizable: true,
  suppressSizeToFit: true,
  suppressResize: true,
  sortable: true
};

export const MAIN_TAB_COLUMN_DEFS_COMMON: (ColDef | ColGroupDef)[] = [
  {
    field: 'returnsCount',
    headerName: 'Возвраты (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'returnsTotal',
    headerName: 'Возвраты (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
];

/*
  ************* WB CONFIG ***************
*/
export const WB_MAIN_TAB_COLUMN_DEFS_BY_DATE: (ColDef | ColGroupDef)[] = [
  {
    field: 'date',
    headerName: 'Дата',
    filter: false,
    comparator: DateSortFunc,
    sortable: true
  },
  {
    field: 'ordersCount',
    headerName: 'Заказы (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'ordersTotal',
    headerName: 'Заказы (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc

  },
  {
    field: 'cancelsCount',
    headerName: 'Отмены (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'cancelsTotal',
    headerName: 'Отмены (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'salesCount',
    headerName: 'Продажи (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc

  },
  {
    field: 'salesTotal',
    headerName: 'Продажи (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  ...MAIN_TAB_COLUMN_DEFS_COMMON,
];

export const WB_MAIN_TAB_COLUMN_DEFS_BY_PRODUCT: (ColDef | ColGroupDef)[] = [
  {
    headerName: '',
    field: 'photo_new',
    width: 40,
    pinned: 'left',
    cellStyle: {
      paddingLeft: '0px',
      paddingRight: '0px'
    },
    cellRenderer: (params) => {
      if(params.data.photo_new === 'Итого'){
        return 'Итого'
      }
      if(!params.data.photo_new){
        return `<img style="height: 40px; width: 40px" src="/assets/images/nophoto.jpg" />`
      }
      return `<img style="height: 40px; width: 40px" src=${params.data.photo_new} />`
    } 
  },
  {
    field: 'sku',
    headerName: 'Артикул',
    filter: false,
  },
  {
    field: 'ordersCount',
    headerName: 'Заказы (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'ordersTotal',
    headerName: 'Заказы (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'cancelsCount',
    headerName: 'Отмены (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'cancelsTotal',
    headerName: 'Отмены (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'salesCount',
    headerName: 'Продажи (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'salesTotal',
    headerName: 'Продажи (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  ...MAIN_TAB_COLUMN_DEFS_COMMON,
];

/*
  ************** OZON CONFIG *************
*/
export const OZON_MAIN_TAB_COLUMN_DEFS_BY_DATE: (ColDef | ColGroupDef)[] = [
  {
    field: 'date',
    headerName: 'Дата',
    filter: false,
    comparator: DateSortFunc,
    sortable: true
  },
  {
    field: 'salesCount',
    headerName: 'Продажи (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'salesTotal',
    headerName: 'Продажи (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  ...MAIN_TAB_COLUMN_DEFS_COMMON,
];

export const OZON_MAIN_TAB_COLUMN_DEFS_BY_PRODUCT: (ColDef | ColGroupDef)[] = [
  {
    field: 'sku',
    headerName: 'Артикул',
    filter: false,
  },
  {
    field: 'salesCount',
    headerName: 'Продажи (шт)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    field: 'salesTotal',
    headerName: 'Продажи (руб)',
    filter: false,
    type: 'rightAligned',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  ...MAIN_TAB_COLUMN_DEFS_COMMON,
];
