import {
  ITariffItem,
  ITariffPercents,
  ITariffPrices,
  ITariffPricesItem,
  ITariffTablePrices,
} from './tariff.interface';

export const TARIFF_PLANS_VALUES = {
  free: 1,
  base: 2,
  extended: 3,
  professional: 4,
};

export const TARIFF_PLAN_CODE = {
  free:'free',
  base: 'base',
  extended: 'extended',
  professional: 'professional',
};

export const TARIFF_PERCENTS_CODES = {
  one_month_selector: '1 месяцев',
  three_month_selector: '3 месяцев',
  six_month_selector: '6 месяцев',
  twelve_month_selector: '12 месяцев',
  recount: 'Перерасчет',
};

export const STANDALONE_PERCENT: ITariffPercents = {
  id: 1,
  selector: 'one_month_selector',
  name: '1 месяц (только тариф S)',
  days: 30,
  sale: '',
  months: 1,
  ids: {
    M: 27,
    L: 27,
    XL: 27,
  },
}

export const TARIFF_PERCENTS: ITariffPercents[] = [
  {
    id: 1,
    selector: 'three_month_selector',
    name: '3 месяца',
    sale: '',
    days: 90,
    months: 3,
    ids: {
      M: 18,
      L: 19,
      XL: 20,
    },
  },
  {
    id: 2,
    selector: 'six_month_selector',
    name: '6 месяцев',
    sale: '-20%',
    days: 180,
    months: 6,
    ids: {
      M: 21,
      L: 22,
      XL: 23,
    },
  },
  {
    id: 3,
    selector: 'twelve_month_selector',
    name: '12 месяцев',
    sale: '-25%',
    days: 360,
    months: 12,
    ids: {
      M: 24,
      L: 25,
      XL: 26,
    },
  },
  {
    id: 0,
    selector: 'recalculation',
    name: 'Перерасчет',
    sale: '',
    days: 0,
    months: 0,
  },
];

export const TARIFF_PRICES: ITariffPrices = {
  recalculation: {
    months: null,
    base: null,
    extended: null,
    professional: null,
    corporate: null,
  },
  one_month_selector: {
    months: 1,
    base: 4200,
    extended: 10000,
    professional: 20000,
    corporate: 25000,
  },
  three_month_selector: {
    months: 3,
    base: 3360,
    extended: 8000,
    professional: 16000,
    corporate: 20000,
  },
  six_month_selector: {
    months: 6,
    base: 2940,
    extended: 7000,
    professional: 14000,
    corporate: 17500,
  },
  twelve_month_selector: {
    months: 12,
    base: 2520,
    extended: 6000,
    professional: 12000,
    corporate: 15000,
  },
};

export const TARIFF_PLANS: { id: number; title: string; code: string }[] = [
  {
    id: 1,
    title: 'S',
    code: 'free',
  },
  {
    id: 2,
    title: 'M',
    code: 'base',
  },
  {
    id: 3,
    title: 'L',
    code: 'extended',
  },
  {
    id: 4,
    title: 'XL',
    code: 'professional',
  },
];

export const TARIFF_TABLE_DETAILS: ITariffTablePrices[] = [
  {
    title: 'Количество пользователей',
    saleType1: '1',
    saleType2: '1',
    saleType3: '2',
    saleType4: '3',
    saleType5: 'более 3',
  },
  {
    title: 'Количество юридических лиц',
    saleType1: '1',
    saleType2: '1-3',
    saleType3: '1-5',
    saleType4: '1-10',
    saleType5: 'до 75',
  },
  {
    title: 'Продажи в кабинете, руб.',
    saleType1: 'до 250 тыс продаж',
    saleType2: 'без ограничений',
    saleType3: 'без ограничений',
    saleType4: 'без ограничений',
    saleType5: 'без ограничений',
  },
];

export const TARIFF_PLANS_PRICE_PER_DAY: ITariffPricesItem = {
  base: 140,
  extended: 333,
  professional: 667,
  corporate: 833,
};


export const TARIFF_ITEMS: ITariffItem[] = [
  {
    name: 'S',
    weight: 1,
    rows: [
      { title: 'Маркетплейс', logos: ['ozon', 'wb'] },
      { title: 'Кол-во кабинетов', description: 1, tooltip: 'Количество подключенных кабинетов МП к сервису' },
      { title: 'Кол-во доп. пользователей', description: 0, tooltip: 'Количество пользователей, которым вы дадите доступ к своим кабинетам' },
      { title: 'Объем выручки', description: 'до 250 000 ₽', tooltip: 'Сумма продаж за последние 30 дней, не включая сегодняшний день' },
      { title: 'Ограничения', description: 'да', tooltip: 'Ограничение на скачивание в excel' },
    ],
    month_summary: '890 ₽/мес',
    calculation_period: 'за 1 месяц',
    calculation_result: '890 ₽',
    button_text: 'Применить'
  },
  {
    name: 'M',
    weight: 2,
    rows: [
      { title: 'Маркетплейс', logos: ['ozon', 'wb'] },
      { title: 'Кол-во кабинетов', description: 2, tooltip: 'Количество подключенных кабинетов МП к сервису' },
      { title: 'Кол-во доп. пользователей', description: 2, tooltip: 'Количество пользователей, которым вы дадите доступ к своим кабинетам' },
      { title: 'Объем выручки', description: '∞', tooltip: 'Сумма продаж за последние 30 дней, не включая сегодняшний день' },
      { title: 'Ограничения', description: 'нет' },
    ],
    month_summary: '3 675 ₽/мес',
    calculation_period: 'за 12 месяцев',
    calculation_result: '44 100 ₽',
    calculation_result_old: '58 800 ₽',
    button_text: 'Применить'
  },
  {
    name: 'L',
    weight: 3,
    rows: [
      { title: 'Маркетплейс', logos: ['ozon', 'wb'] },
      { title: 'Кол-во кабинетов', description: 4, tooltip: 'Количество подключенных кабинетов МП к сервису' },
      { title: 'Кол-во доп. пользователей', description: 5, tooltip: 'Количество пользователей, которым вы дадите доступ к своим кабинетам' },
      { title: 'Объем выручки', description: '∞', tooltip: 'Сумма продаж за последние 30 дней, не включая сегодняшний день' },
      { title: 'Ограничения', description: 'нет' },
    ],
    month_summary: '4 875 ₽/мес',
    calculation_period: 'за 12 месяцев',
    calculation_result: '58 500 ₽',
    calculation_result_old: '78 000 ₽',
    button_text: 'Применить'
  },
  {
    name: 'XL',
    weight: 4,
    rows: [
      { title: 'Маркетплейс', logos: ['ozon', 'wb'] },
      { title: 'Кол-во кабинетов', description: 8, tooltip: 'Количество подключенных кабинетов МП к сервису' },
      { title: 'Кол-во доп. пользователей', description: 10, tooltip: 'Количество пользователей, которым вы дадите доступ к своим кабинетам' },
      { title: 'Объем выручки', description: '∞', tooltip: 'Сумма продаж за последние 30 дней, не включая сегодняшний день' },
      { title: 'Ограничения', description: 'нет' },
    ],
    month_summary: '8 250 ₽/мес',
    calculation_period: 'за 12 месяцев',
    calculation_result: '99 000 ₽',
    calculation_result_old: '132 000 ₽',
    button_text: 'Применить'
  }
]