import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FinoperationsComponent } from './finoperations/finoperations.component';
import { FinoperationsExcelComponent } from './finoperations/components/finoperations-excel/finoperations-excel.component';
import { BtnCellRendererFinoperationComponent } from './finoperations/components/aggrid-components/btn-cell-renderer-finoperation.component';
import { FinOperationActionComponent } from './finoperations/components/aggrid-components/fin-operation-action.component';
import { FinoperationExcelRenderCellsComponent } from './finoperations/components/aggrid-components/finoperation-excel-render-cells.component';
import { FinoperationSpecificCellsComponent } from './finoperations/components/aggrid-components/finoperation-specific-cells.component';
import { FinoperationSpecificRenderCellsComponent } from './finoperations/components/aggrid-components/finoperation-specific-render-cells.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { AccountsComponent } from './settings/accounts/accounts.component';
import { AccountsSpecificCellsComponent } from './settings/accounts/components/accounts-specific-cells.component';
import { BtnAccountsCellRendererComponent } from './settings/accounts/components/btn-accounts-cell-renderer.component';
import { ItemsComponent } from './settings/items/items.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeModule } from 'primeng/tree';
import { BtnPartnersCellRendererComponent } from './settings/partners/cell-component/btn-partners-cell-renderer.component';
import { PartnersComponent } from './settings/partners/partners.component';
import { DropdownModule } from 'primeng/dropdown';
import { FinddsComponent } from './findds/findds.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeTableModule } from 'primeng/treetable';
import { FinpnlComponent } from './finpnl/finpnl.component';
import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { InputNumberModule } from 'primeng/inputnumber';
import { ReconciliationComponent } from './reports/reconciliation/reconciliation.component';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { PnlConsolidationComponent } from './pnl-consolidation/pnl-consolidation.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AuthGuard } from 'app/shared/guards/auth.guard';
import { UiComponentsModule } from 'app/shared/ui/components/ui-components.module';
import { ModalModule } from 'app/shared/common-components/modal/modal.module';
import { InputTextModule } from 'primeng/inputtext';

const routes: Routes = [
  {
    path: 'operations',
    component: FinoperationsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'dds',
    component: FinddsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'pnl',
    component: FinpnlComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'pnl-consolidation',
    component: PnlConsolidationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'reports/reconciliation',
    component: ReconciliationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'settings/items',
    component: ItemsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'settings/partners',
    component: PartnersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'settings/accounts',
    component: AccountsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    FinoperationsComponent,
    FinoperationsExcelComponent,
    AccountsComponent,
    ItemsComponent,
    PartnersComponent,
    FinddsComponent,
    FinpnlComponent,

    BtnCellRendererFinoperationComponent,
    FinOperationActionComponent,
    FinoperationExcelRenderCellsComponent,
    FinoperationSpecificCellsComponent,
    FinoperationSpecificRenderCellsComponent,
    AccountsSpecificCellsComponent,
    BtnAccountsCellRendererComponent,
    BtnPartnersCellRendererComponent,
    ReconciliationComponent,
    PnlConsolidationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,

    AgGridModule,

    ProgressSpinnerModule,
    ToastModule,
    FileUploadModule,
    TooltipModule,
    NgBootstrapFormValidationModule,
    NgSelectModule,
    ContextMenuModule,
    ConfirmDialogModule,
    TreeModule,
    DropdownModule,
    SelectButtonModule,
    NgxDaterangepickerMd,
    MultiSelectModule,
    TreeTableModule,
    NgbDropdownModule,
    InputNumberModule,
    ModalModule,
    NgbDatepickerModule,
    PanelModule,
    CheckboxModule,
    UiComponentsModule,
    NgApexchartsModule,
    NgbAccordionModule,
    NgbTooltipModule,
    InputTextModule,
  ],
  exports: [
    FinoperationsExcelComponent,
    BtnCellRendererFinoperationComponent,
    FinOperationActionComponent,
    FinoperationExcelRenderCellsComponent,
    FinoperationSpecificCellsComponent,
    FinoperationSpecificRenderCellsComponent,
    AccountsSpecificCellsComponent,
    BtnAccountsCellRendererComponent,
    BtnPartnersCellRendererComponent,
  ],
})
export class FinancesModule {}
