import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import {
  IChartPoint,
  ISalesFilterData,
  IGeneralPayload,
  IMySalesTableData,
  IMySalesRowData,
} from './mysales.interface';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';

import * as moment from 'moment';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { ApexOptions } from 'ng-apexcharts';
import { generateColumnDefsByDate, generateSummary, getChartOptions } from './mysales.func';
import { ICardWidget } from '../main-test/main-test.interface';
import { SALES_TABLE_COLUMN_DEFS, SALES_TABLE_COLUMN_DEFS_OZON } from './mysales.mock';
import { ColDef, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-mysales',
  templateUrl: './mysales.component.html',
  styleUrls: ['./mysales.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class MysalesComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  readonly ranges = FIRST_RANGE_TYPE;
  salesChartData: Partial<ApexOptions> = null;
  salesGridOptions: GridOptions;

  typeOptions = [
    { name: 'Лента', value: '1' },
    { name: 'Таблица', value: '2' },
  ];

  selectedType = this.typeOptions[0].value;

  columnDefs = SALES_TABLE_COLUMN_DEFS;

  summaryRow: Partial<IMySalesRowData>[] | null = [];

  showOnlyReturns = false;
  showInRubles = false;

  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    sortable: true,
    width: 160
  };

  chartOptions: Partial<ApexOptions> | null = null;

  warehouses: ISalesFilterData[] = [];
  categories: ISalesFilterData[] = [];
  subjects: ISalesFilterData[] = [];
  nmids: ISalesFilterData[] = [];

  selectedWarehouse: ISalesFilterData[] = [];
  selectedCategory: ISalesFilterData[] = [];
  selectedSubject: ISalesFilterData[] = [];
  selectedNm: ISalesFilterData[] = [];
  selectedDate: ISelectedNgbDate = { startDate: moment(), endDate: moment() };

  visibleBtn = 1;
  isDisplayGoogleTableInfo = true;
  salesTableData: IMySalesRowData[] = [];
  isOzon = false;

  cardData: ICardWidget = null;

  text = '';
  subtext = '';

  constructor() {
    super();
    this.isDisplayGoogleTableInfo =
      localStorage.getItem('googleMsgInfo') == null;
    this.salesGridOptions = {
        context: { componentParent: this },
        enableCellTextSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    if (!this.SHOP_ID) {
      this.initShopID();
    }
    this.isOzon =
      JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data?.tutorialVideoLink?.find(
          item => item.pageTitle === 'dashboard/sales'
        ) || { die_text: null, die_title: null };
        this.text = die_title;
        this.subtext = die_text;
      });
  }

  private get generalRequest(): IGeneralPayload & { barcodes: string[], warehouses: string[], categories: string[], subjects: string[] } {
    return {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      barcodes: Array.isArray(this.selectedNm) ? this.selectedNm.map(i => i.code) : [],
      warehouses: Array.isArray(this.selectedWarehouse) ? this.selectedWarehouse.map(i => i.code) : [],
      categories: Array.isArray(this.selectedCategory) ? this.selectedCategory.map(i => i.code) : [],
      subjects: Array.isArray(this.selectedSubject) ? this.selectedSubject.map(i => i.code) : [],
    };
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  onGridFilterChanged(): void {
    const rows = [];
    this.gridApi.forEachNodeAfterFilter((node : RowNode) => {
      rows.push(node.data)
    })
    this.summaryRow = generateSummary(rows, this.selectedType, this.columnDefs);
  }

  changeType(event: {originalEvent: PointerEvent, value: string}) {
    this.selectedType = event.value;
    this.loadPageData()
  }

  closeGoogleTableMsg(param: number) {
    if (param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.isDisplayGoogleTableInfo = false;
    }
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = [];
        break;
      case 'warehouse':
        this.selectedWarehouse = [];
        break;
      case 'category':
        this.selectedCategory = [];
        break;
      case 'subject':
        this.selectedSubject = [];
        break;
      default:
        console.error('Unsupported filter');
    }
    this.loadPageData();
  }

  onFilterChange(): void {
    this.loadPageData()
  }

  onShowOnlyReturnsChange(event: {checked: boolean}): void {
    this.showOnlyReturns = event.checked;
    this.loadPageData()
  }

  onShowInRublesChange(event: {checked: boolean}): void {
    this.showInRubles = event.checked;
    this.columnDefs = generateColumnDefsByDate(this.salesTableData[0], this.isOzon, this.showInRubles);
  }

  loadPageData() {
    this.getSales();
    this.getChart();
    if(this.selectedType === '1') {
      this.getCard();
    }
  }

  dateChanged(selectedDate: ISelectedNgbDate) {
    this.selectedDate = selectedDate;
    this.getFilters()
    this.loadPageData();
  }

  private getSales() {
    const body = {
      ...this.generalRequest, 
      type: this.selectedType, 
    }
    if(this.selectedType === '1') {
      body['only_returns'] = this.showOnlyReturns ? 1 : 0
    }
    this._mpSurfService
      .loadByPost(body, 'sales/table')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: (data: IMySalesTableData) => {
          if(this.selectedType === '1') {
            this.columnDefs = this.isOzon ? SALES_TABLE_COLUMN_DEFS_OZON : SALES_TABLE_COLUMN_DEFS
          } else {
            this.columnDefs = generateColumnDefsByDate(data.table[0], this.isOzon, this.showInRubles);
          }
          this.salesTableData = data.table;
          this.gridApi.setFilterModel(this.gridApi.getFilterModel());
          this.gridApi.onFilterChanged()
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        },
      });
  }

  private getChart() {
    this._mpSurfService
      .loadByPost(this.generalRequest, 'sales/chart')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { chart: IChartPoint[] }) => {
          this.chartOptions = getChartOptions(data);
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        },
      });
  }

  private getCard() {
    this._mpSurfService
      .loadByPost(this.generalRequest, 'sales/card')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { card: ICardWidget[] }) => {
          this.cardData = data.card[0];
          this._cdr.markForCheck();
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        },
      });
  }

  private getWarehousesList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getSalesWh', 'data');
  }

  private getCategoriesList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getSalesCategory', 'data');
  }

  private getSubjectsList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getSalesSubject', 'data');
  }

  private getNmList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getSalesNm', 'data');
  }

  private getFilters(): void {
    const body = this.generalRequest;
    forkJoin([
      this.getWarehousesList(body),
      this.getCategoriesList(body),
      this.getSubjectsList(body),
      this.getNmList(body),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([warehouses, categories, subjects, nmList]) => {
        (this.warehouses = warehouses.data),
        (this.categories = categories.data),
        (this.subjects = subjects.data),
        (this.nmids = nmList.data);
      });
  }
}
