import { CellStyle, ColGroupDef } from 'ag-grid-community';
import { UnitPipe } from '../../../shared/pipes/unit.pipe';
import { DecimalPipe } from '@angular/common';
import { Column } from 'exceljs';

const ABC_COLOR_MAP: Map<string, CellStyle> = new Map()
  .set('AAA', {'background-color': 'rgb(188 233 222)'})
  .set('AAB', {'background-color': 'rgb(192 233 221)'})
  .set('AAC', {'background-color': 'rgb(197 233 220)'})
  .set('ABA', {'background-color': 'rgb(202 234 219)'})
  .set('ABB', {'background-color': 'rgb(207 234 218)'})
  .set('ABC', {'background-color': 'rgb(212 234 217)'})
  .set('ACA', {'background-color': 'rgb(217 235 216)'})
  .set('ACB', {'background-color': 'rgb(222 235 215)'})
  .set('ACC', {'background-color': 'rgb(227 235 214)'})
  .set('BAA', {'background-color': 'rgb(232 236 213)'})
  .set('BAB', {'background-color': 'rgb(237 236 212)'})
  .set('BAC', {'background-color': 'rgb(242 237 211)'})
  .set('BBA', {'background-color': 'rgb(247 237 210)'})
  .set('BBB', {'background-color': 'rgb(252 238 209)'})
  .set('BBC', {'background-color': 'rgb(250 233 207)'})
  .set('BCA', {'background-color': 'rgb(249 229 205)'})
  .set('BCB', {'background-color': 'rgb(247 225 204)'})
  .set('BCC', {'background-color': 'rgb(246 221 202)'})
  .set('CAA', {'background-color': 'rgb(244 217 200)'})
  .set('CAB', {'background-color': 'rgb(243 213 199)'})
  .set('CAC', {'background-color': 'rgb(241 209 197)'})
  .set('CBA', {'background-color': 'rgb(240 205 196)'})
  .set('CBB', {'background-color': 'rgb(238 201 194)'})
  .set('CBC', {'background-color': 'rgb(237 197 192)'})
  .set('CCA', {'background-color': 'rgb(235 193 191)'})
  .set('CCB', {'background-color': 'rgb(234 189 189)'})
  .set('CCC', {'background-color': 'rgb(233 185 188)'})

const decimalPipe = new DecimalPipe('ru-RU')
const unitPipe = new UnitPipe()

export const ABS_ANALYST_COLUMN_DEFS: ColGroupDef[] = [
  {
    children: [
      {
        headerName: '',
        field: 'photo_new',
        width: 30,
        cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
        pinned: 'left',
        cellRenderer: (params) => {
          if(params.data.photo_new === 'empty'){
            return ''
          }
          else if(!params.data.photo_new){
            return `<img style="height: 30px; width: 30px" src="/assets/images/nophoto.jpg" />`
          }
          return `<img style="height: 30px; width: 30px" src=${params.data.photo_new} />`
        } 
      },
      {
        headerName: 'Артикул МП',
        cellStyle: { textAlign: 'left' },
        cellRenderer: (params) => {
          if(params.data.nmID === 'Итого') {
            return `<strong>Итого</strong>`
          }
          return params.data.nmID
        },
        field: 'nmID',
        width: 100,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Предмет',
        cellStyle: { textAlign: 'left' },
        field: 'object',
        width: 120,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Артикул поставщика',
        cellStyle: { textAlign: 'left' },
        field: 'sku',
        width: 200,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'ABC',
        field: 'abc',
        width: 70,
        filter: 'agTextColumnFilter',
        pinned: 'left',
        cellStyle: params => {
          return ABC_COLOR_MAP.get(params.value) || {'background-color': 'null'}
        }
      },
    ]
  },
  {
    headerName: 'ABC Выручка',
    children: [
      {
        headerName: 'Выручка, руб.',
        cellStyle: { fontWeight: '600' },
        field: 'sales',
        width: 137,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'В выручке учтены продажи (без вычета СПП/ баллов), возвраты, сторно'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Продажи, шт',
        field: 'sales_count',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Кол-во продаж за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: '% от выручки ',
        field: 'percent_sales',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Процент продажи от общей суммы продаж всех артикулов за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Заказы, руб.',
        field: 'orders_sum',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Процент продажи от общей суммы продаж всех артикулов за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Заказы, шт.',
        field: 'orders',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Кол-во заказов сделанное за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Продано из заказанных',
        field: 'sales_from_orders',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Кол-во продаж из заказов сделанных за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: '% выкупа (по доехавшим)',
        field: 'percent_sales_delivered',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: '% выкупа по заказам по которым уже есть решение'
      },
      {
        columnGroupShow: 'closed',
        headerName: '% выкупа (по заказанным)',
        field: 'percent_sales_redeemed',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: '% выкупа по всем заказам, показатель может меняться из-за товаров "В пути"'
      },
    ]
  },
  {
    headerName: 'ABC Валовая прибыль',
    children: [
      {
        headerName: 'Валовая прибыль, руб',
        cellStyle: { fontWeight: '600' },
        field: 'profit',
        width: 190,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Валовая прибыль, %',
        headerClass: 'header-centered',
        field: 'percent_profit',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Процент прибыли от общей суммы прибыли всех артикулов за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Рентабельность товара по с/с, %',
        field: 'profitability',
        width: 180,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Процентное соотношение прибыли к себестоимости'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Себес-ть (закупка), руб.',
        field: 'cost_price',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Вносится на листе Мои товары'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Логистика, руб',
        field: 'logistic',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Комиссия ВБ, руб',
        field: 'commission',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Эквайринг',
        field: 'acquiring',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'ДРР по заказам, %',
        field: 'drr',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Доля рекламных расходам по заказам'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Реклама внутренняя',
        field: 'adv',
        width: 130,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Если данные в столбец не передаются, то они в составе прочих удержаний'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Реклама внешняя',
        field: 'adv_external',
        width: 130,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Вносится на листе операции статья "Реклама таргет / блогеры"'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Маркетинг',
        field: 'marketing',
        width: 130,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Вносится на листе операции статья "Дизайнеры / Фотографы"'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Прочие удержания',
        field: 'other_deductions',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Прочие прямые затраты которые можно распределить по артикулам'
      },
    ]
  },
  {
    headerName: 'АВС Оборачиваемость',
    children: [
      {
        headerName: 'Оборачиваемость по заказам, дней',
        cellStyle: { fontWeight: '600' },
        field: 'turnover',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'За сколько дней продастся весь товар, считается по заказам в день. Считается на сегодня'
      },
      {
        headerName: 'Оборачиваемость по продажам, дней',
        cellStyle: { fontWeight: '600' },
        field: 'turnover_sales',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'За сколько дней продастся весь товар с учетом % выкупа. Считается на сегодня'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Среднее кол-во заказов в день на сегодня',
        field: 'avg_orders_day',
        width: 180,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Остатки FBO',
        field: 'total_stocks',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Остаток на всех складах + товар, что занесен на лист Мои товары'
      },
    ]
  },
  {
    headerName: 'Юнит-экономика (Затраты на ед.)',
    children: [
      {
        headerName: 'Ср. цена продажи, руб',//?
        field: 'unit_avg_sale_sum',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        cellStyle: { fontWeight: '600' },
      },
      {
        headerName: 'Прибыль на ед., руб',
        field: 'unit_profit_per',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: '% от прибыли',
        field: 'unit_percent_profit',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Отношение прибыли к выручке',
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Итого затрат на ед., руб.',
        field: 'total_costs_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        cellStyle: { fontWeight: '600' },
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Итого затрат на ед., %',
        field: 'total_costs_unit_per',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Себестоимость на ед., руб.',//?
        field: 'unit_cost_price_per',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Себестоимость на ед., % ',
        field: 'unit_percent_cost_price_per',//?
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Комиссия на ед., руб.',
        field: 'commission_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Комиссия на ед., %',
        field: 'commission_unit_per',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Отношение комиссии к стоимости в ценах поставщика'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Логистика на ед., руб.',
        field: 'logistic_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Логистика на ед., %',
        field: 'logistic_unit_per',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Отношение логистики к стоимости в ценах поставщика'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Эквайринг на ед., руб.',
        field: 'acquiring_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Реклама внеш. на ед., руб',
        field: 'adv_external_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Реклама внутр. на ед., руб',
        field: 'adv_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Маркетинг на ед., руб.',
        field: 'marketing_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Прочие удержания на ед., руб',
        field: 'other_deductions_unit_rub',
        width: 120,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      },
    ]
  },
];


const FBO_FBS_COL_DEF = {
  headerName: 'АВС Оборачиваемость',
  children: [
    {
      headerName: 'Оборачиваемость по заказам, дней',
      cellStyle: { fontWeight: '600' },
      field: 'turnover',
      width: 120,
      sortable: true,
      filter: 'agNumberColumnFilter',
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      headerTooltip: 'За сколько дней продастся весь товар, считается по заказам в день. Считается на сегодня'
    },
    {
      headerName: 'Оборачиваемость по продажам, дней',
      cellStyle: { fontWeight: '600' },
      field: 'turnover_sales',
      width: 120,
      sortable: true,
      filter: 'agNumberColumnFilter',
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      headerTooltip: 'За сколько дней продастся весь товар с учетом % выкупа. Считается на сегодня'
    },
    {
      columnGroupShow: 'closed',
      headerName: 'Среднее кол-во заказов в день на сегодня',
      field: 'avg_orders_day',
      width: 180,
      sortable: true,
      filter: 'agNumberColumnFilter',
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
    },
    {
      columnGroupShow: 'closed',
      headerName: 'Остатки FBO+FBS',
      field: 'total_stocks',
      width: 120,
      sortable: true,
      filter: 'agNumberColumnFilter',
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      headerTooltip: 'Остаток на всех складах + товар, что занесен на лист Мои товары'
    },
  ]
}

export function generateColumnDefs(isWbChecked: boolean, isFboChecked: boolean): ColGroupDef[] {

  if(!isWbChecked) {
    if(isFboChecked) {
      return [
        ABS_ANALYST_COLUMN_DEFS[0],
        ABS_ANALYST_COLUMN_DEFS[1],
        ABS_ANALYST_COLUMN_DEFS[2],
        FBO_FBS_COL_DEF,
        ABS_ANALYST_COLUMN_DEFS[4],
      ]
    }
    return ABS_ANALYST_COLUMN_DEFS
  }

  return [
    {
      children: [
        {
          headerName: '',
          field: 'photo_new',
          width: 40,
          cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
          pinned: 'left',
          cellRenderer: (params) => {
            if(params.data.photo_new === 'Итого'){
              return 'Итого'
            }
            if(!params.data.photo_new){
              return `<img style="height: 40px; width: 40px" src="/assets/images/nophoto.jpg" />`
            }
            return `<img style="height: 40px; width: 40px" src=${params.data.photo_new} />`
          } 
        },
        {
          headerName: 'Артикул МП',
          cellStyle: { textAlign: 'left' },
          field: 'nmID',
          width: 100,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Предмет',
          cellStyle: { textAlign: 'left' },
          field: 'object',
          width: 120,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Размер',
          cellStyle: { textAlign: 'left' },
          field: 'size',
          width: 80,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Артикул поставщика',
          cellStyle: { textAlign: 'left' },
          field: 'sku',
          width: 200,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'ABC',
          field: 'abc',
          width: 70,
          filter: 'agTextColumnFilter',
          pinned: 'left',
          cellStyle: params => {
            return ABC_COLOR_MAP.get(params.value) || {'background-color': 'null'}
          }
        },
      ]
    },
    ABS_ANALYST_COLUMN_DEFS[1],
    ABS_ANALYST_COLUMN_DEFS[2],
    isFboChecked ? FBO_FBS_COL_DEF : ABS_ANALYST_COLUMN_DEFS[3],
    ABS_ANALYST_COLUMN_DEFS[4],
  ];

  // const storedDefs = localStorage.getItem('abc-column-defs');

  //   if(!storedDefs) {
  //       return ABS_ANALYST_COLUMN_DEFS
  //   }
  //   const parsedDefs = JSON.parse(storedDefs) as ColGroupDef[]
  //   const flattedChildren: ColDef[] = []
  //   ABS_ANALYST_COLUMN_DEFS.forEach(group => flattedChildren.push(...group.children))

  //   const newDefs: ColGroupDef[] = parsedDefs.map(d => {
  //     return {
  //       ...d,
  //       children: (d.children as ColDef[]).map(ch => flattedChildren.find(fch => fch.field === ch.field))
  //     }
  //   })
  //   return newDefs;
}


export const ABC_EXPORT_COLUMNS: Partial<Column>[] = [
  { header: 'Артикул МП', key: 'Артикул МП', width: 15 },
  { header: 'Артикул поставщика', key: 'Артикул поставщика', width: 15 },
  { header: 'Предмет', key: 'Предмет', width: 25 },
  { header: 'ABC', key: 'ABC', width: 20 },
  { header: 'Продажи (без вычета СПП), руб.', key: 'Продажи (без вычета СПП), руб.', width: 20 },
  { header: '% от выручки ', key: '% от выручки ', width: 20 },
  { header: 'Заказы (всего)', key: 'Заказы (всего)', width: 15 },
  { header: 'Продано из заказанных', key: 'Продано из заказанных', width: 20 },
  { header: 'Продажи, шт', key: 'Продажи, шт', width: 20 },
  { header: '% выкупа (по заказанным)', key: '% выкупа (по заказанным)', width: 20 },
  { header: 'Прибыль, руб', key: 'Прибыль, руб', width: 20 },
  { header: 'Прибыльность, %', key: 'Прибыльность, %', width: 35 },
  { header: 'Рентабельность товара по с/с, %', key: 'Рентабельность товара по с/с, %', width: 20 },
  { header: 'Себес-ть (закупка), руб.', key: 'Себес-ть (закупка), руб.', width: 30 },
  { header: 'Логистика, руб', key: 'Логистика, руб', width: 30 },
  { header: 'Комиссия ВБ, руб', key: 'Комиссия ВБ, руб', width: 30 },
  { header: 'Эквайринг', key: 'Эквайринг', width: 30 },
  { header: 'Реклама и маркетинг суммарно', key: 'Реклама и маркетинг суммарно', width: 40 },
  { header: 'Прочие удержания', key: 'Прочие удержания', width: 20 },
  { header: 'Оборачиваемость, дней', key: 'Оборачиваемость, дней', width: 20 },
  { header: 'Среднее кол-во заказов в день на сегодня', key: 'Среднее кол-во заказов в день на сегодня', width: 20 },
  { header: 'Остатки TOTAL', key: 'Остатки TOTAL', width: 20 },
];