import { ColDef } from "ag-grid-community";
import { ButtonRendererComponent } from "./buttons/button-renderer.component";
import { PlanFactControlsRendererComponent } from "./buttons/controls-renderer.component";
import { IGeneralInfoTableItem } from "./plan-fact.interface";
import { DecimalPipe } from "@angular/common";
import { GENERAL_STATUSES } from "app/menu-tabs/guides/products/products.mock";

const decimalPipe = new DecimalPipe('ru-RU')

export const GENERAL_INFO_COLUMN_DEFS: ColDef<IGeneralInfoTableItem>[] = [

    {
        headerName: '',
        field: 'name',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },
    {
        headerName: '',
        field: 'sum_day',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },
    {
        headerName: '',
        field: 'sum_today',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },
    {
        headerName: '',
        field: 'name',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },

]

export const PLAN_FACT_COLUMN_DEFS: ColDef[] = [

    {
        headerName: '',
        width: 85,
        cellRenderer: ButtonRendererComponent,
        cellStyle: { paddingLeft: '0px', paddingRight: '0px' },
    },
    {
        headerName: 'Дата формирования плана',
        field: 'created_at',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Название плана',
        field: 'plan_name',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Месяц планирования',
        field: 'dateFrom',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Цель',
        field: 'type',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Способ планироования',
        field: 'method',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Цель в рублях',
        field: 'goal_rub',
        width: 162,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
    },
    {
        headerName: 'Статус',
        field: 'status',
        width: 100,
    },
    {
        headerName: '',
        width: 140,
        cellRenderer: PlanFactControlsRendererComponent,
    },
]

export const MODAL_COLUMNS_DEFS: ColDef[] = [
    {
        headerName: '',
        field: 'selection',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        filter: false,
        width: 60,
        checkboxSelection: true,
    },
    {
        headerName: 'Планируемая сумма (введите значение)',
        field: 'plan_sum',
        cellStyle: { background: '#f0f3f5' },
        valueFormatter: (params) => `${decimalPipe.transform(params.value, '1.0-0') || 0} руб ✎`,
        editable: true,
        cellEditor: "agNumberCellEditor",
    },
    {
      headerName: 'Артикул МП',
      field: 'nmid',
      sortable: true,
      resizable: true,
      width: 150,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
    },
    {
      headerName: 'Артикул поставщика',
      field: 'SKU',
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      headerName: 'Категория МП',
      field: 'category',
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Предмет',
      field: 'subject',
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Бренд',
      field: 'brand',
      sortable: true,
      resizable: true,
      width: 150,
      wrapText: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Категория собственная',
      field: 'category_pnl',
      sortable: false,
      resizable: true,
      width: 150,
      filter: true,
      suppressSizeToFit: true,
      editable: true,
    },
    {
      headerName: 'Статус',
      field: 'status_name',
      editable: true,
      sortable: true,
      resizable: true,
      width: 150,
      flex: 0,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: GENERAL_STATUSES,
      },
    },
  ];
  
