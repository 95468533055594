<p-toast></p-toast>
<div class="d-flex">
    <div class="pr-0">
        <div class="widget-content p-0">
            <div class="widget-content-wrapper">
                <div class="widget-content-left">
                    <div #dropDownModal="ngbDropdown" class="btn-group" ngbDropdown placement="bottom-right">
                        <div ngbDropdownToggle class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg">
                              <use xlink:href="assets/images/person.svg#person"></use>
                            </svg>
                          </div>
                        <div class="card-body" style="padding: 0;">
                            <div class="dropdown-menu-lg" ngbDropdownMenu>
                                <div class="dropdown-menu-header">
                                    <div class="dropdown-menu-header-inner bg-focus">
                                        <div class="menu-header-image opacity-3"></div>
                                        <div class="menu-header-content btn-pane-right">
                                            <div class="avatar-icon-wrapper me-3 avatar-icon-xl">
                                                <div class="avatar-icon rounded">
                                                    <img alt="Avatar 5"
                                                         src="../../../../../assets/images/avatars/logo_avatar.jpg">
                                                </div>
                                            </div>
                                            <div>
                                                <h5 class="menu-header-title">{{ 'Привет, ' + (profileName ? profileName : profilePhone) }}</h5>
                                                <h6 class="menu-header-subtitle">
                                                    Подписка&nbsp;до&nbsp;{{ profileSubscribeExpiredDate }}</h6>
                                            </div>
                                            <div class="menu-header-btn-pane">
                                                <button (click)="logout()" class="btn btn-pill btn-info">Выйти</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="p-0 list-group-item">
                                        <div class="grid-menu grid-menu-2col overflow-hidden">
                                            <div class="g-0 row">
                                                <div class="col-sm-6">
                                                    <button (click)="dropDownModal.close()"
                                                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                                                            routerLink="/profile/tariff">
                                                        <i class="pe-7s-note2 btn-icon-wrapper btn-icon-lg mb-3"></i>
                                                      Тарифы
                                                    </button>
                                                </div>
                                                <div class="col-sm-6">
                                                    <button (click)="dropDownModal.close()"
                                                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                                                            routerLink="/profile/bill">
                                                        <i class="pe-7s-cash btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                        Мои счета
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="p-0 list-group-item">
                                        <div class="grid-menu grid-menu-2col overflow-hidden">
                                            <div class="no-gutters row">
                                                <div class="col-sm-6">
                                                    <button (click)="dropDownModal.close()"
                                                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                                                            routerLink="/settings/shop">
                                                        <i class="pe-7s-users btn-icon-wrapper btn-icon-lg mb-3"></i>
                                                        Кабинеты
                                                    </button>
                                                </div>
                                                <div class="col-sm-6">
                                                    <button (click)="openModal(changePassword)"
                                                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                        <i class="pe-7s-lock btn-icon-wrapper btn-icon-lg mb-3"></i>
                                                        Сменить пароль
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="p-0 list-group-item">
                                        <div class="grid-menu grid-menu-2col overflow-hidden">
                                            <div class="no-gutters row">
                                                <div class="col-sm-6">
                                                    <button (click)="openModal(changeName)"
                                                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                        <i class="pe-7s-user btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                        Сменить имя
                                                    </button>
                                                </div>
                                                <div class="col-sm-6">
                                                    <button (click)="dropDownModal.close()"
                                                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                                                            routerLink="/profile/users">
                                                        <i class="pe-7s-add-user btn-icon-wrapper btn-icon-lg mb-3"></i>
                                                        Пользователи
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #changeName>
    <app-header-modal (closeEmitter)="closeModal()" [modalTitle]="'Смена имени'"/>
    <ng-container *ngIf="!updateStatus; else isUpdate">
        <form [formGroup]="formChangeName"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="card-body">
                        <input class="mb-2 form-control" formControlName="nameprofile"
                               maxlength="30"
                               placeholder="Введите новое имя"/>
                    </div>
                </div>
            </div>
            <app-footer-modal (cancelEmitter)="closeModal()"
                              (submitEmitter)="changeProfileName($event)"
                              [isCancel]="true"
                              [isCreate]="true"
                              [isDisabled]="formChangeName.invalid || formChangeName.disabled"/>
        </form>
    </ng-container>
</ng-template>

<ng-template #changePhone>
    <app-header-modal (closeEmitter)="closeModal()" [modalTitle]="'Изменение номера телефона'"/>
    <ng-container *ngIf="!updateStatus; else isUpdate">
        <form [formGroup]="formChangePhone"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="main-card mb-12 card">
                        <div class="card-body">
                            <input [textMask]="{mask: mask}" class="mb-2 form-control"
                                   formControlName="phoneprofile"
                                   maxlength="30" placeholder="+7(999) 111-22-33"/>
                        </div>
                    </div>
                </div>
            </div>
            <app-footer-modal (cancelEmitter)="closeModal()"
                              (submitEmitter)="changeProfilePhone($event)"
                              [isCancel]="true"
                              [isCreate]="true"
                              [isDisabled]="formChangePhone.invalid || formChangePhone.disabled"/>
        </form>
    </ng-container>
</ng-template>

<!-- /// ПАРОЛЬ -->
<ng-template #changePassword let-c="close" let-d="dismiss">
    <app-header-modal (closeEmitter)="closeModal()" [modalTitle]="'Смена пароля'"/>
    <ng-container *ngIf="!updateStatus; else isUpdate">
        <form [formGroup]="formChangePassword"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="main-card mb-12 p-0 card-body d-flex flex-column gap-2">
                        <div class="col-md-12 mb-12 form-group">
                            <label for="validationCustom01">Старый пароль</label>
                            <input class="form-control" formControlName="oldpass" id="validationCustom01"
                                   placeholder="Введите старый пароль"
                                   type="password">
                            <span *ngIf="this.formChangePassword.controls['oldpass'].hasError('invalidch')"
                                  class="invalid-feedback ng-star-inserted">Не верный пароль</span>
                        </div>

                        <div class="col-md-12 mb-12 form-group">
                            <label for="validationCustom02">Новый пароль</label>
                            <input class="form-control" formControlName="newpass" id="validationCustom02"
                                   placeholder="Введите новый пароль"
                                   type="password">
                        </div>

                        <div class="col-md-12 mb-12 form-group">
                            <label for="validationCustom03">Повторите новый пароль</label>
                            <input class="form-control" formControlName="newpassconfirm" id="validationCustom03"
                                   placeholder="Повторите новый пароль"
                                   type="password">
                            <span *ngIf="this.formChangePassword.errors?.mustMatch"
                                  class="invalid-feedback ng-star-inserted">Пароли не
                совпадают</span>
                        </div>
                    </div>
                </div>
            </div>
            <app-footer-modal (cancelEmitter)="closeModal()"
                              (submitEmitter)="changeProfilePassword($event)"
                              [isCancel]="true"
                              [isDisabled]="formChangePassword.invalid || formChangePassword.disabled"
                              [isSave]="true"/>

        </form>
    </ng-container>
</ng-template>

<ng-template #isUpdate>
    <div class="modal-body">
        <p>Обновление выполнено. Через 3 секунд окно закроется</p>
    </div>
</ng-template>