import {
  Component,
  inject,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { ApiService } from 'app/shared/services/api.service';
import {
  faAngleDown,
  faAngleUp,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { DOCUMENT } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DragStoppedEvent, GridOptions } from 'ag-grid';
import {
  IArticlesPurchase,
  IDataShopWBList,
  IFinPurchase,
  IFinPurchaseStorageFilters,
} from './finpurchase.interface';
import { DATA_SHOP_WEB_ALL, FINPURCHASE_COLUMN_DEFS } from './finpurchase.mock';
import { SECOND_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { CustomTooltipComponent } from '../products/components/prdcustomTooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map } from 'rxjs/operators';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { environment } from '../../../../environments/environment';
import { FILTER_PARAMS_FIELDS } from '../../../shared/utils/constants/filter-params-controller';
import { FilterParamsController } from '../../../shared/utils/controllers/filter-params.controller';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { Observable } from 'rxjs';
import { ISelectedNgbDate } from 'app/shared/interfaces/common.interface';
import { SharedDataService } from 'app/shared/services/shared-data.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-finpurchase',
  templateUrl: './finpurchase.component.html',
  styleUrls: ['./finpurchase.component.sass'],
  styles: [
    `
      :host ::ng-deep .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
      }
    `,
  ],
  providers: [MessageService, ConfirmationService],
})
export class FinPurchaseComponent {

  private readonly sharedService = inject(SharedDataService)

  isLoadingStatus = false;
  readonly DatePickerCommon: DatePickerCommon;
  readonly gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    tooltipComponent: CustomTooltipComponent,
  };
  readonly tooltipShowDelay = 0;
  readonly tooltipHideDelay = 2000;
  rowData: IFinPurchase[] = [];
  ranges = SECOND_RANGE_TYPE;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faCog = faCog;

  readonly FormPurchase: FormGroup;
  readonly FormContrAgent: FormGroup;

  selectedArticle: string | string[] = '';
  selectedDate: ISelectedNgbDate = this.sharedService.filterDate || {
    startDate: moment().add(-70, 'days'),
    endDate: moment(),
  };

  chsuccess = false;
  shop_list: IDataShopWBList[] = [];
  articlesList: IArticlesPurchase[] = [];
  nmid_personal: any;
  selectedSupplier: any | null = null;
  modalReference: any;
  dateOfPurchase: any;
  excel_lists: any;

  readonly URL_NEW: string;
  readonly TOKEN: string;
  statuses: SelectItem[] = [];
  products: any;
  list_partner: any;
  suppliersList: string[] = [];
  isEditModal = false;
  readonly SHOP_ID: any;
  @ViewChild('purchaseModal', { read: TemplateRef })
  purchaseModal: TemplateRef<any>;
  private excelFinPurchase: Record<string, any> = [];
  private isNewArticleActive = false;

  constructor(
    private api: ApiService,
    private mpSurfApi: MpSurfApiService,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.FormPurchase = formBuilder.group({
      id: new FormControl(null),
      photo_new: new FormControl(null),
      nmidGroup: new FormControl(null, [Validators.required]),
      nmid_label: new FormControl(null),
      nmid_personal: new FormControl(null),
      batch: new FormControl(null),
      batch_price: new FormControl(null, [Validators.required]),
      batch_price_one: new FormControl(null, [Validators.required]),
      date_purchase: new FormControl('', [Validators.required]),
      date_purchase_not_exp: new FormControl(null),
      supplier_id: new FormControl(null, [Validators.required]),
      qty: new FormControl(null, [Validators.required]),
      delivery_per_unit_supplier_ff_wh: new FormControl(null, [
        Validators.required,
      ]),
      pack_label_per_unit: new FormControl(null, [Validators.required]),
      pack_material_additionally: new FormControl(null, [Validators.required]),
      delivery_wh_ff: new FormControl(null, [Validators.required]),
      fix_price_per_unit: new FormControl(null, [Validators.required]),
      // UNUSED CONTROLS
      size: new FormControl(null),
      supplier_code: new FormControl(null),
    });
    this.FormContrAgent = formBuilder.group({
      shop: new FormControl(''),
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      inn: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      kpp: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      checking_account: new FormControl(''),
      description: new FormControl(''),
    });
    this.DatePickerCommon = new DatePickerCommon();
    this.gridOptions = {
      context: { componentParent: this },
      onDragStopped: (event: DragStoppedEvent) => this.onColumnMoved(event),
      suppressRowClickSelection: true,
    } as GridOptions;
    this.URL_NEW = environment.apiNew;
    this.TOKEN = localStorage.getItem('token');
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop')).shop;
  }

  get tooltipMessage() {
    return this.isNewArticleActive
      ? 'Укажите в комментарии временное название артикула и размера. При появлении баркода не забудьте скорректировать закупку!'
      : '';
  }

  get dataForColumnDefs() {
    return FINPURCHASE_COLUMN_DEFS;
  }

  get isSessionStorageHasFilters(): boolean {
    return !!sessionStorage.getItem(FILTER_PARAMS_FIELDS.purchaseFilterParams);
  }

  private get generateCommonRequest() {
    return {
      barcode: this.selectedArticle ?? '',
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      shop_id: this.SHOP_ID,
    };
  }

  ngOnInit(): void {
    this.filtersController();

    this.loadPartners();
    this.loadSuppliers();
    this.loadWBShops();
    this.statuses = [
      { label: 'INSTOCK', value: 'instock' },
      { label: 'LOWSTOCK', value: 'lowstock' },
      { label: 'OUTOFSTOCK', value: 'outofstock' },
    ];
  }

  onGridReady(params: GridReadyEvent<any>) {
    const columnState = localStorage.getItem('finPurchaseColumnState');
    if (columnState) {
      const columnStateJSON = JSON.parse(columnState);
      params.columnApi.applyColumnState({
        state: columnStateJSON,
        applyOrder: true,
      });
    }
  }

  filterChanged(filterId?: string): void {
    this.updateSessionStorageFromFilters();

    if (filterId === 'Date') {
      this.loadBarcodes();
    }
    this.loadPurchasesList();
  }

  openModalCreatePurchase(changeSupplier: TemplateRef<any>) {
    this.FormPurchase.reset();
    this.isEditModal = false;
    this.selectedSupplier = null;
    this.dateOfPurchase = this.calendar.getToday();
    const dataShop = JSON.parse(localStorage.getItem('mpall_shop'));
    if (dataShop.shop === '0') {
      this.modalReference = this.modalService.open(changeSupplier);
    }
    if (dataShop.shop !== '0') {
      this.modalReference = this.modalService.open(this.purchaseModal);
    }
  }

  createNewSupplier() {
    this.isLoadingStatus = true;
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      params: this.FormContrAgent.value,
    };
    this.api
      .userRegisterFin('getFinPartnersAdd', this.TOKEN, body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe(
        (data: any) => {
          this.isLoadingStatus = false;
          if (data.is_error === 0) {
            this.modalReference.close();
            this.showPopUpMessage('success', 'Поставщик добавлен', '');
            this.FormContrAgent.reset();
            this.loadSuppliers();
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.deleteFailedSummary,
            PopUpMessages.deleteFailedMessage
          );
        }
      );
  }

  createUpdatePurchase() {
    this.isLoadingStatus = true;
    const body = this.FormPurchase.getRawValue();
    this.mpSurfApi
      .createOrUpdateWithAction('getPurchaseAdd', body, 'purchases')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe(
        (data: any) => {
          if (data.is_error === 0) {
            this.loadPurchasesList();
            this.modalService.dismissAll();
            this.showPopUpMessage(
              'success',
              `Закупка ${this.isEditModal ? 'изменена' : 'добавлена'}`,
              'xxx'
            );
          } else {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedMessage
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.updateFailedSummary,
            PopUpMessages.updateFailedMessage
          );
        }
      );
  }

  deletePurchase(purchaseData: IFinPurchase) {
    this.confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить закупку?',
      header: 'Подтверждение удаления',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      accept: () => {
        const body = { id: purchaseData.id };
        this.isLoadingStatus = true;
        this.mpSurfApi
          .delete(body, 'purchases')
          .pipe(finalize(() => (this.isLoadingStatus = false)))
          .subscribe(
            (data: any) => {
              this.isLoadingStatus = false;
              if (data.is_error === 0) {
                this.loadPurchasesList();
                this.showPopUpMessage(
                  'success',
                  PopUpMessages.deleteSuccessSummary,
                  PopUpMessages.deleteSuccessMessage
                );
              }
            },
            () => {
              this.showPopUpMessage(
                'error',
                PopUpMessages.deleteFailedSummary,
                PopUpMessages.deleteFailedMessage
              );
            }
          );
      },
      reject: () => {},
    });
  }

  editPurchaseEmitter(purchaseData: IFinPurchase) {
    this.isEditModal = true;
    this.FormPurchase.reset();
    this.FormPurchase.controls.supplier_id.setValue(purchaseData.supplier_id);
    this.FormPurchase.patchValue(purchaseData);
    const data = this.articlesList.filter(
      a => a.nmid === purchaseData.nmidGroup.nmid
    );
    this.selectedSupplier = purchaseData?.supplier_id;
    purchaseData.date_purchase.year = +purchaseData?.date_purchase.year;
    purchaseData.date_purchase.month = +purchaseData?.date_purchase.month;
    purchaseData.date_purchase.day = +purchaseData?.date_purchase.day;
    this.dateOfPurchase = purchaseData?.date_purchase;
    this.modalService.open(this.purchaseModal);
  }

  selectedBarcodesNmClear(): void {
    this.selectedArticle = '';
    this.updateSessionStorageFromFilters();
    this.loadPurchasesList();
  }

  onBasicUploadAuto(event: any, fileUpload) {
    this.showPopUpMessage(
      'success',
      'Загружено',
      'Файл загружен. Данные обновлены'
    );
    fileUpload.clear(); // this will clear your file
    this.loadPurchasesList();
  }

  onBasicUploadError(event: any, fileUpload) {
    this.showPopUpMessage(
      'error',
      PopUpMessages.loadFailedSummary,
      PopUpMessages.loadFailedMessages
    );
    fileUpload.clear(); // this will clear your file
  }

  onSendData() {
    this.isLoadingStatus = true;
  }

  exportCollationExcel() {
    this.isLoadingStatus = true;
    this.mpSurfApi
      .load({}, 'purchases/export-collation')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe(
        response => {
          this.importExcel(response, 'Сверка закупки');
        },
        () => {
          this.showPopUpMessage(
            'error',
            'Ошибка экспорта!',
            'Не удалось экспортировать данные. Повторите попытку!'
          );
        }
      );
  }

  exportExcel() {
    if (this.excelFinPurchase.length) {
      this.importExcel(this.excelFinPurchase, 'Закупки');
    } else {
      this.isLoadingStatus = true;
      this.mpSurfApi
        .load({}, 'purchases/export')
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoadingStatus = false))
        )
        .subscribe(
          response => {
            this.excelFinPurchase = response;
            this.importExcel(this.excelFinPurchase, 'Закупки');
          },
          () => {
            this.showPopUpMessage(
              'error',
              'Ошибка экспорта!',
              'Не удалось экспортировать данные. Повторите попытку!'
            );
          }
        );
    }
  }

  openNewSupplierModal(supplierModal: any) {
    this.modalReference = this.modalService.open(supplierModal);
  }

  countAmountOfBanch(event: any) {
    const amount = this.FormPurchase.controls.qty.value;
    const batchPrice = this.FormPurchase.controls.batch_price.value;
    this.FormPurchase.controls.batch_price_one.setValue(null);
    if (amount !== null && batchPrice !== null) {
      this.FormPurchase.controls.batch_price_one.setValue(
        Math.round(batchPrice / amount)
      );
    }
  }

  countAmountOfCost(event: any) {
    const amount = this.FormPurchase.controls.qty.value;
    const batchPriceOne = this.FormPurchase.controls.batch_price_one.value;
    this.FormPurchase.controls.batch_price.setValue(null);
    if (amount !== null && batchPriceOne !== null) {
      this.FormPurchase.controls.batch_price.setValue(
        Math.round(amount * batchPriceOne)
      );
    }
  }

  countAllExpenses(): void {
    const unitDelivery =
      this.FormPurchase.controls.delivery_per_unit_supplier_ff_wh.value;
    const costOfMarking = this.FormPurchase.controls.pack_label_per_unit.value;
    const packingMaterial =
      this.FormPurchase.controls.pack_material_additionally.value;
    const shipmentDelivery = this.FormPurchase.controls.delivery_wh_ff.value;
    if (
      unitDelivery !== null &&
      costOfMarking !== null &&
      packingMaterial !== null &&
      shipmentDelivery !== null
    ) {
      const sum =
        unitDelivery + costOfMarking + packingMaterial + shipmentDelivery;
      this.FormPurchase.controls.fix_price_per_unit.setValue(sum);
    }
  }

  clearSessionStorage(): void {
    sessionStorage.removeItem(FILTER_PARAMS_FIELDS.purchaseFilterParams);
  }

  addNewArticle() {
    this.isNewArticleActive = true;
    setTimeout(() => (this.isNewArticleActive = false), 10000);
    document.getElementById('comment_field').focus();
  }

  filterData(
    data: Observable<IArticlesPurchase[]>
  ): Observable<IArticlesPurchase[]> {
    let result;
    data.subscribe(a => {
      result = a.map(x => {
        return {
          SKU: x.SKU,
          nmid: x.nmid,
          Barcode: x.Barcode,
        };
      });
    });

    return result.asObservable();
  }

  private importExcel(purchases: any, fileName: string) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(purchases);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  private filtersController(): void {
    const request = FilterParamsController<IFinPurchaseStorageFilters>(
      FILTER_PARAMS_FIELDS.purchaseFilterParams,
      JSON.stringify(this.generateCommonRequest)
    );
    this.updateFiltersFromSessionStorage(request);
  }

  private updateFiltersFromSessionStorage(
    requestFields: IFinPurchaseStorageFilters
  ): void {
    this.selectedArticle = requestFields.barcode || '';
    this.selectedDate.startDate = moment(requestFields.startDate);
    this.selectedDate.endDate = moment(requestFields.endDate);
  }

  private updateSessionStorageFromFilters(): void {
    const request = {
      barcode: this.selectedArticle,
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    } as IFinPurchaseStorageFilters;
    sessionStorage.setItem(
      FILTER_PARAMS_FIELDS.purchaseFilterParams,
      JSON.stringify(request)
    );
  }

  private loadPurchasesList() {
    const body = this.generateCommonRequest;
    this.isLoadingStatus = true;
    this.dateOfPurchase = this.calendar.getToday();
    this.mpSurfApi
      .loadFinPurchase(body, 'purchases')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe(
        (response: IFinPurchase[]) => {
          this.rowData = response;
          this.excel_lists = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadWBShops() {
    this.api
      .userRegister('getDataShopWB', this.TOKEN, '')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IDataShopWBList[]) => {
          this.shop_list = response;
          this.shop_list.unshift(DATA_SHOP_WEB_ALL);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadBarcodes(): void {
    this.isLoadingStatus = true;
    this.mpSurfApi
      .load({}, 'products/important')
      .pipe(
        map((response: IArticlesPurchase[]) => {
          return response.map(item => {
            const { photo, ...rest } = item;
            rest.nmid = +rest.nmid;
            return rest;
          });
        })
      )
      .subscribe(
        (response: IArticlesPurchase[]) => {
          this.articlesList = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadSuppliers() {
    this.isLoadingStatus = true;
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
    };
    this.api
      .userRegisterFin('getFinPartners', this.TOKEN, body)
      .pipe(
        untilDestroyed(this)
        // finalize(() => this.isLoadingStatus = false)
      )
      .subscribe(
        (data: any[]) => {
          this.suppliersList = data;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadPartners() {
    this.isLoadingStatus = true;
    const body = {
      shop_id: this.SHOP_ID,
    };
    this.api
      .userRegisterFin('getContragetList', this.TOKEN, body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          this.list_partner = data;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private onColumnMoved(params: DragStoppedEvent) {
    const columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItesm('finPurchaseColumnState', columnState);
  }

  private showPopUpMessage(
    severity: PopUpStatus,
    summary: string,
    message: string
  ): void {
    this.isLoadingStatus = false;
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  private saveFilterParams(filterParams: any) {
    sessionStorage.setItem(
      'purchaseFilterParams',
      JSON.stringify(filterParams)
    );
  }
}
