import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OAuthGuard {
  canActivate(): boolean {
    const profilePhone = localStorage.getItem('profilePhone');
    const shop = localStorage.getItem('mpall_shop');

    if(!!localStorage.getItem('hasBrokenToken' + JSON.parse(shop).shop) && JSON.parse(profilePhone) !== "+79032632693"){
      return false;
    }
    return true;
  }
}

