<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]='subtext'
/>

<div class="app-page-title fin-pnl">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="btn-actions-pane-left">
        <div class="block-order-ftype" style="width: 100%;">
          <div class="page-title-icon">
            <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            <div>
              Отчет о прибылях и убытках
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                [pTooltip]="
                  'Строится на основе реализации. Отчет по реализации доступен до ' +
                  lastReportDate
                "></i>
            </div>
            <span routerLink="../../guides/products" style="color: #5046e5; font-size: 0.9rem; cursor: pointer; font-style: italic;">Добавить себестоимость</span>
          </div>

          <app-mp-video-play [videoLink]="getVideoLinkByPage('fin/pnl')" />

          <app-mp-ngb-range-datepicker
            *ngIf="!apiRealizationChecked"
            [initialData]="selectedDate"
            (selectedDateEmitter)="dateChanged($event)" />

        </div>
        <div class="fin-pnl__filters">
          <form [formGroup]="form" class="fin-pnl__filters_api-range" *ngIf="apiRealizationChecked"> 
            <div ngbDropdown (openChange)="onOpenChange($event, myDrop1, 'start')" #myDrop1="ngbDropdown">
              <span class="placeholder" *ngIf="showStartPlaceholder">Начало периода</span>
              <input 
                ngbTooltip='Выбрать начало периода'
                placement="top" 
                readonly 
                formControlName="dateFrom" 
                id="dropdownBasic1" 
                ngbDropdownToggle 
                style="height: 32px; width: 150px;" 
                type="text" 
                pInputText
              />
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <section style="display: flex; padding: 0 4px; justify-content: space-evenly;">
                  <div>
                    <div class="radio-group" style="justify-content: flex-end;" [class.selected]="form.controls['monthFrom'].value === month.key" *ngFor="let month of months">
                      <label>
                        <input 
                          style="width: 0;"
                          type="radio" 
                          formControlName="monthFrom"
                          [value]="month.key"
                        >
                        <span>
                          {{month.name}}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <section>
                      <div class="radio-group" style="justify-content: flex-start; width: 60px;" [class.selected]="form.controls['yearFrom'].value === year.key" *ngFor="let year of years">
                        <label>
                          <input 
                            style="width: 0;"
                            type="radio" 
                            formControlName="yearFrom"
                            [value]="year.key"
                          >
                          <span>
                            {{year.name}}
                          </span>
                        </label>
                      </div>
                    </section>
                  </div>
                </section>
              </div>
            </div>
            <div ngbDropdown (openChange)="onOpenChange($event, myDrop2, 'end')" #myDrop2="ngbDropdown">
              <span class="placeholder" *ngIf="showEndPlaceholder">Конец периода</span>
              <input 
                ngbTooltip='Выбрать конец периода'
                placement="top" 
                readonly 
                formControlName="dateTo" 
                id="dropdownBasic2" 
                ngbDropdownToggle 
                style="height: 32px; width: 150px;" 
                type="text" 
                pInputText
              />
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <section style="display: flex; padding: 0 4px; justify-content: space-evenly;">
                  <div>
                    <div class="radio-group" style="justify-content: flex-end;" [class.selected]="form.controls['monthTo'].value === month.key" *ngFor="let month of months">
                      <label>
                        <input 
                          style="width: 0;"
                          type="radio" 
                          formControlName="monthTo"
                          [value]="month.key"
                        >
                        <span>
                          {{month.name}}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <section>
                      <div class="radio-group" style="justify-content: flex-start; width: 60px;" [class.selected]="form.controls['yearTo'].value === year.key" *ngFor="let year of years">
                        <label>
                          <input 
                            style="width: 0;"
                            type="radio" 
                            formControlName="yearTo"
                            [value]="year.key"
                          >
                          <span>
                            {{year.name}}
                          </span>
                        </label>
                      </div>
                    </section>
                  </div>
                </section>
              </div>
            </div>
          </form>
          <div class="select-button">
            <p-selectButton
              *ngIf="!apiRealizationChecked"
              (onChange)="changeType($event)"
              [(ngModel)]="selectedDateType"
              [options]="[
                { name: 'По неделям', value: 2 },
                { name: 'По месяцам', value: 3 },
              ]"
              optionLabel="name"
              optionValue="value"
              selectionMode="single"
              styleClass="dark-panel"
              [ngStyle]="{ fontSize: '14px', width: '100%', display: 'block' }"
            >
            </p-selectButton>
          </div>
          <p-multiSelect
            (onChange)="selectedParameters()"
            (onClear)="onClearParameters('article')"
            [(ngModel)]="selectedNm"
            [maxSelectedLabels]="1"
            [options]="articles"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '300px' }"
            defaultLabel="Артикул"
            emptyMessage="Артикулов не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <p-multiSelect
            (onChange)="selectedParameters()"
            (onClear)="onClearParameters('subject')"
            [(ngModel)]="selectedSubject"
            [maxSelectedLabels]="1"
            [options]="subjectsList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '300px' }"
            defaultLabel="Предмет"
            emptyMessage="Предметов не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <p-multiSelect
              (onChange)="selectCategory()"
              (onClear)="onClearCategory()"
              [(ngModel)]="selectedCategories"
              [maxSelectedLabels]="1"
              [options]="categoriesList"
              [showClear]="true"
              [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '300px' }"
              defaultLabel="Категория собственная"
              emptyMessage="Категорий не найдено"
              itemSize="30"
              optionLabel="name"
              selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <p-checkbox
            *ngIf="!isOzon"
            ngbTooltip='Исключает строку "Реклама по удержаниям"'
            placement="top"
            [(ngModel)]="checked"
            [binary]="true"
            inputId="binary"
            label="Показать рекламу по API"
            (onChange)="onCheckboxChange($event)" 
          />
          <p-checkbox
            *ngIf="isOzon"
            ngbTooltip='Отчёт появляется не позднее 8-го числа следующего месяца, соответствует Отчёту о реализации товара из ЛК'
            placement="top"
            [(ngModel)]="apiRealizationChecked"
            [binary]="true"
            inputId="binary"
            label="Показать по API Реализация"
            (onChange)="onApiRealizationCheckboxChange($event)" 
          />
        </div>
      </div>
    </div>
  </div>
</div>
<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelTitle>
      <span>Развернуть / Свернуть график</span>
    </ng-template>
    <ng-template ngbPanelContent>
      <apx-chart
        *ngIf="chartOptions"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [dataLabels]="chartOptions.dataLabels"
        [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend" />
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="card p-treetable" [style.width.px]="(pnlData?.columns.length) * 180 + 400" [style.maxWidth]="'100%'">
  <p-treeTable
    [(selection)]="selectedNodes"
    [columns]="pnlData?.columns"
    [frozenColumns]="[{ field: 'name', header: 'Статья' }]"
    [metaKeySelection]="true"
    [scrollable]="true"
    [value]="pnlData?.data"
    frozenWidth="400px"
    selectionMode="single"
    styleClass="p-treetable-sm">
    <ng-template let-columns pTemplate="colgroup">
      <colgroup>
        <col *ngFor="let col of columns" style="width: 180px" />
      </colgroup>
    </ng-template>
    <ng-template let-columns pTemplate="header">
      <tr>
        <th
          *ngFor="let col of columns"
          style="
          font-size: 18px; 
          text-align: center; 
          font-weight: 500; 
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;"
          [class.bc-EBEBEB]="col.header === 'Итого'"
        >
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData="rowData" pTemplate="body">
      <tr>
        <td
          *ngFor="let col of columns; let i = index"
          class="columns-wrap rowGray"
          [class.fs-18]="rowData.name === 'Выручка'"
          [class.fs-16]="fs_16_headers.includes(rowData.name)"
          [class.bc-c3e9ecab]="bc_c3e9ecab_headers.includes(rowData.name)"
          [class.bc-fceed1b5]="bc_fceed1b5_headers.includes(rowData.name)"
          [class.bc-bce9dedb]="rowData.name === 'Выручка'"
          [class.bc-ebc3fab8]="rowData.name === 'Чистая прибыль ИТОГ'"
          [class.bc-EBEBEB]="col.header === 'Итого' && !colored_headers.includes(rowData.name)"
          [class.centered]="col.header === 'Итого'"
          [class.align-right]="col.header !== 'Итого'"
        >
          <span
            [class.fw-500]="fw_500_headers_.includes(rowData.name)"
            [class.fw-600]="rowData.name === 'Чистая прибыль ИТОГ'"
            [class.fs-18]="fs_18_headers.includes(rowData.name)"
            [class.fs-1rem]="!fs_18_headers.includes(rowData.name)"
            [class.mr-12]="col.header !== 'Итого'"
            [class.darkGreen]="rowData.name === 'Чистая прибыль ИТОГ' && rowData[col.field] > 0"
            [class.darkRed]="rowData.name === 'Чистая прибыль ИТОГ' && rowData[col.field] < 0"
            [class.color-grey]="color_grey_headers.includes(rowData.name)"
          >
            {{
              (rowData &&
                (rowData[col.field]?.toString()?.includes('%')
                  ? rowData[col.field]
                  : (rowData[col.field] | currency: '' : ''))) ||
                ''
            }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template let-rowData="rowData" let-rowNode pTemplate="frozenbody">
      <tr [ngClass]="{ height: '50px' }">
        <td class="flex items-center"
          [class.fs-18]="rowData.name === 'Выручка'"
          [class.bc-bce9dedb]="rowData.name === 'Выручка'"
          [class.bc-c3e9ecab]="bc_c3e9ecab_headers.includes(rowData.name)"
          [class.bc-fceed1b5]="bc_fceed1b5_headers.includes(rowData.name)"
          [class.bc-ebc3fab8]="rowData.name === 'Чистая прибыль ИТОГ'"
        >
          <p-treeTableToggler [rowNode]="rowNode" />
          <span
            [pTooltip]="rowData.name"
            [tooltipDisabled]="false"
            [class.color-grey]="color_grey_headers.includes(rowData.name)"
            class="special-headers"
            [class.fw-500]="fw_500_headers_.includes(rowData.name)"
            [class.fw-600]="rowData.name === 'Чистая прибыль ИТОГ'"
            [class.fs-18]="fs_18_headers.includes(rowData.name)"
            [class.fw-15]="!fs_18_headers.includes(rowData.name)"
          >
            {{ rowData.name }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
<button
  [pTooltip]='!isAllowed ? "Недоступно на бесплатном тарифе" : null'
  [class.btn-disabled]="!isAllowed" 
  (click)="exportExcel()"
  class="btn btn-success button-export-fin-pnl"
  type="button"
>
  Скачать в Excel
</button>
<p-toast />
