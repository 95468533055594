<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<div [ngStyle]="{ display: isLoading ? 'none' : 'block' }">
  <div class="app-page-title abc">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-users icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          <div>
            ABC анализ
            <i
              class="fa fa-question-circle"
              aria-hidden="true"
              [pTooltip]="
                'Строится на основе реализации. Отчет по реализации доступен до ' +
                lastReportDate
              "></i>
          </div>
          <span
            routerLink="../../guides/products"
            style="
              color: #5046e5;
              font-size: 0.9rem;
              cursor: pointer;
              font-style: italic;
            "
            >Добавить себестоимость</span
          >
        </div>
        <app-mp-video-play [videoLink]="getVideoLinkByPage('dashboard/abc')" />
        <app-mp-ngb-range-datepicker
          [initialData]="selectedDate"
          (selectedDateEmitter)="dateChanged($event)" />
        <p-checkbox
          *ngIf="!isOzon"
          ngbTooltip="Компенсации по браку начисляются только на артикул, и по размерам не отображаются"
          placement="bottom"
          [(ngModel)]="apiRealizationChecked"
          [binary]="true"
          inputId="binary"
          label="Показать по размерам"
          (onChange)="onWbCheckboxChange($event)" />
        <p-multiSelect
          (onChange)="selectStatus()"
          (onClear)="onClearStatuses()"
          [(ngModel)]="selectedStatuses"
          [maxSelectedLabels]="1"
          [options]="statusesList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Статусы"
          emptyMessage="Статусов не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-multiSelect
          (onChange)="selectCategory()"
          (onClear)="onClearCategories()"
          [(ngModel)]="selectedCategories"
          [maxSelectedLabels]="1"
          [options]="categoriesList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Категория собственная"
          emptyMessage="Категорий не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-multiSelect
          *ngIf="!isOzon"
          (onChange)="selectTag()"
          (onClear)="onClearTags()"
          [(ngModel)]="selectedTags"
          [maxSelectedLabels]="1"
          [options]="tagsList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Ярлык"
          emptyMessage="Ярлыков не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-checkbox
          *ngIf="!isOzon"
          ngbTooltip='Исключает строки "Реклама по удержаниям" и "Прочие удержания/выплаты"'
          placement="bottom"
          [(ngModel)]="checked"
          [binary]="true"
          inputId="binary"
          label="Показать рекламу по API"
          (onChange)="onCheckboxChange($event)" />
        <p-checkbox
          [(ngModel)]="fboFbsChecked"
          [binary]="true"
          inputId="binary"
          label="Показать остатки FBO+FBS"
          (onChange)="onFboFbsCheckboxChange($event)" />
      </div>
    </div>
  </div>
  <div class="d-flex abc-cards">
    <app-mp-card-info *ngFor="let card of cards" [card]="card">
    </app-mp-card-info>
    <app-mp-card-info *ngIf="otherDeductionsData" [card]="otherDeductionsData">
    </app-mp-card-info>
  </div>

  <button
    class="btn btn-light settings-btn"
    type="button"
    (click)="openCustomizator(customizator)">
    Настройка таблицы
    <svg xmlns="http://www.w3.org/2000/svg">
      <use xlink:href="assets/images/settings.svg#settings"></use>
    </svg>
  </button>
  <div class="main-card card mt-3">
    <div class="abc-table">
      <ag-grid-angular
        #agGrid
        (gridReady)="onGridReady($event)"
        (filterChanged)="onGridFilterChanged()"
        (dragStopped)="onDragStopped($event)"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [paginationPageSize]="20"
        [rowData]="rowDataGeneral"
        [tooltipShowDelay]="0"
        [domLayout]="'autoHeight'"
        [rowHeight]="30"
        [pinnedBottomRowData]="summaryData"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        headerHeight="28"
        style="width: 100%">
      </ag-grid-angular>
      <button
        [pTooltip]="!isAllowed ? 'Недоступно на бесплатном тарифе' : null"
        [class.btn-disabled]="!isAllowed"
        (click)="exportExcel()"
        class="mb-2 btn btn-success button-export-adv"
        type="button">
        Скачать в Excel
      </button>
    </div>
  </div>
</div>
<p-toast />

<ng-template #customizator>
  <section>
    <h4>Выберите колонки и их расположение</h4>
    <div class="customizator-header">
      <button 
        class="btn btn-primary"
        type="button"
        (click)="onCheckboxesAll()"
      >
        Выбрать все
      </button>
      <button 
        class="btn btn-danger"
        type="button"
        (click)="onCheckboxesReset()"
      >
        Сбросить
      </button>
    </div>

    <section class="customizator-wrapper" cdkScrollable>
      <div
        class="example-list"
      >
        <div
          *ngFor="let category of general_categories"
          class="example-box"
        >
          <svg *ngIf="!category.dragDisabled" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="assets/images/drag.svg#drag"></use>
          </svg>

          <p-checkbox
            [(ngModel)]="category.checked"
            [binary]="true"
            [label]="category.name"
            [disabled]="category.disabled"
            inputId="binary"
            (onChange)="onCheckboxesChangeGeneral($event, category.key)"
          >
          </p-checkbox>
        </div>
      </div>

      <h5>ABC выручка</h5>

      <div
        cdkDropList
        class="example-list"
        (cdkDropListDropped)="dropAbc1($event)"
        [cdkDropListAutoScrollStep]="20"
      >
        <div
          *ngFor="let category of abc1_categories"
          class="example-box"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragData]="category"
          [cdkDragDisabled]="category.dragDisabled"
          [cdkDragHandleDisabled]="category.dragDisabled"
        >
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

          <svg *ngIf="!category.dragDisabled" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="assets/images/drag.svg#drag"></use>
          </svg>

          <p-checkbox
            [(ngModel)]="category.checked"
            [binary]="true"
            [label]="category.name"
            [disabled]="category.disabled"
            inputId="binary"
            (onChange)="onCheckboxesChangeAbc1($event, category.key)">
          </p-checkbox>
        </div>
      </div>

      <h5>ABC валовая прибыль</h5>

      <div
        cdkDropList
        class="example-list"
        (cdkDropListDropped)="dropAbc2($event)"
        [cdkDropListAutoScrollStep]="20"
      >
        <div
          *ngFor="let category of abc2_categories"
          class="example-box"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragData]="category"
          [cdkDragDisabled]="category.dragDisabled"
          [cdkDragHandleDisabled]="category.dragDisabled"

        >
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

          <svg *ngIf="!category.dragDisabled" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="assets/images/drag.svg#drag"></use>
          </svg>

          <p-checkbox
            [(ngModel)]="category.checked"
            [binary]="true"
            [label]="category.name"
            [disabled]="category.disabled"
            inputId="binary"
            (onChange)="onCheckboxesChangeAbc2($event, category.key)">
          </p-checkbox>
        </div>
      </div>

      <h5>ABC Оборачиваемость</h5>

      <div
        cdkDropList
        class="example-list"
        (cdkDropListDropped)="dropAbc3($event)"
        [cdkDropListAutoScrollStep]="20"
      >
        <div
          *ngFor="let category of abc3_categories"
          class="example-box"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragData]="category"
          [cdkDragDisabled]="category.dragDisabled"
          [cdkDragHandleDisabled]="category.dragDisabled"

        >
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

          <svg *ngIf="!category.dragDisabled" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="assets/images/drag.svg#drag"></use>
          </svg>

          <p-checkbox
            [(ngModel)]="category.checked"
            [binary]="true"
            [label]="category.name"
            [disabled]="category.disabled"
            inputId="binary"
            (onChange)="onCheckboxesChangeAbc3($event, category.key)">
          </p-checkbox>
        </div>
      </div>

      <h5>Юнит-экономика</h5>

      <div
        cdkDropList
        class="example-list"
        (cdkDropListDropped)="dropUnit($event)"
        [cdkDropListAutoScrollStep]="20"
      >
        <div
          *ngFor="let category of unit_categories"
          class="example-box"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragData]="category"
          [cdkDragDisabled]="category.dragDisabled"
          [cdkDragHandleDisabled]="category.dragDisabled"

        >
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

          <svg *ngIf="!category.dragDisabled" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="assets/images/drag.svg#drag"></use>
          </svg>

          <p-checkbox
            [(ngModel)]="category.checked"
            [binary]="true"
            [label]="category.name"
            [disabled]="category.disabled"
            inputId="binary"
            (onChange)="onCheckboxesChangeUnit($event, category.key)">
          </p-checkbox>
        </div>
      </div>

    </section>

    <div class="customizator-footer">
      <button 
        class="btn btn-success"
        type="button"
        (click)="onCheckboxesSave()"
      >
        Сохранить
      </button>
    </div>
  </section>
</ng-template>
