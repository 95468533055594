import { ColDef, ColGroupDef } from 'ag-grid-community';
import { CustomTooltipComponent } from './components/prdcustomTooltip';
import { FinoperationSpecificCellsComponent } from '../../finances/finoperations/components/aggrid-components/finoperation-specific-cells.component';
import { NumberSpacesFormatter } from '../../../shared/utils/functions/number-space-formatter';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';
import { ButtonRendererComponent } from './components/button-change-sebes.component';
import { SebesDeleteControlsRendererComponent } from './components/button-delete-sebes.component';
import { DecimalPipe } from '@angular/common';
import { UnitPipe } from 'app/shared/pipes/unit.pipe';

const decimalPipe = new DecimalPipe('ru-RU')
const unitPipe = new UnitPipe()

export const LOCALE_TEXT_FOR_FILTERS: any = {
  // Number Filter & Text Filter
  filterOoo: 'Фильтр...',
  equals: 'Равно',
  notEqual: 'не равно',
  empty: 'Пусто',
  /* for number filter */
  lessThan: 'Меньше чем',
  greaterThan: 'Больше чем',
  lessThanOrEqual: 'Меньше или равно',
  greaterThanOrEqual: 'Больше или равно',
  inRange: 'В промежутке',
  inRangeStart: 'от',
  inRangeEnd: 'до',
  // Text Filter
  contains: 'Содержит',
  notContains: 'Не Содержит',
  startsWith: 'Начинается с',
  endsWith: 'Заканчиваеться на',
  // Date Filter
  dateFormatOoo: 'dd.mm.yyyy',
  // Filter Conditions
  andCondition: 'И',
  orCondition: 'ИЛИ',
  // Filter Buttons
  resetFilter: 'Очистить',
  applyFilter: 'Применить',
  clearFilter: 'Clear',
  cancelFilter: 'Cancel',
  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',
};
export const FILTER_PARAMS_TEXT = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  suppressAndOrCondition: true,
  filterOptions: ['contains', 'notContains'],
  defaultOption: 'contains',
};
export const FILTER_PARAMS_NUM = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  suppressAndOrCondition: true,
  filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
};
export const FILTER_PARAMS_FULL_TEXT = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  suppressAndOrCondition: true,
  filterOptions: ['contains', 'notContains', 'equals'],
};
export const CUSTOM_STYLES = `
  .ball-clip-rotate_dop {
    background-color: #3f6ad8;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 2px solid #3f6ad8;
    border-bottom-color: transparent;
    height: 15px;
    width: 15px;
    background: transparent !important;
    display: inline-block;
    -webkit-animation: rotate 0.75s 0s linear infinite;
    animation: rotate 0.75s 0s linear infinite;
  }
  .price_list {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f0f3f5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  :host ::ng-deep .p-cell-editing {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;
export let CELL_CLASS = ['d-flex', 'justify-content-center', 'align-items-center'];
export let CELL_CLASS_LEFT = ['d-flex', 'justify-content-left', 'align-items-left'];

export const GENERAL_STATUSES = [];


export const SEBES_COL_DEF: ColDef[] = [
  {
    headerName: 'Баркод',
    field: 'Barcode',
    sortable: true,
    resizable: true,
    width: 40,
  },
  {
    headerName: 'Размер',
    field: 'techSize',
    sortable: true,
    resizable: true,
    width: 40,
  },
  {
    headerName: 'Закупка / производство, руб',
    field: 'cost_price',
    cellEditor: 'agTextCellEditor',
    editable: true,
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    sortable: true,
    resizable: true,
    width: 40,
  },
  {
    headerName: 'Доп. расходы, руб',
    field: 'other_deductions',
    cellEditor: 'agTextCellEditor',
    editable: true,
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    sortable: true,
    resizable: true,
    width: 40,
  },
];

export const SEBES_COL_DEF2: ColDef[] = [
  {
    headerName: 'Дата корректировки',
    field: 'date',
    sortable: true,
    resizable: true,
    flex: 1
  },
  {
    headerName: 'Закупка / производство, руб',
    field: 'cost_price',
    cellEditor: 'agTextCellEditor',
    editable: true,
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    sortable: true,
    resizable: true,
    flex: 1
  },
  {
    headerName: 'Доп. расходы, руб',
    field: 'other_deductions',
    cellEditor: 'agTextCellEditor',
    editable: true,
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    sortable: true,
    resizable: true,
    flex: 1
  },
  {
    sortable: false,
    filter: false,
    headerName: '',
    width: 40,
    cellRenderer: SebesDeleteControlsRendererComponent,
    cellStyle: { paddingLeft: '0px', paddingRight: '0px'},
  },
]

export let PRODUCTS_COLUMN_DEFS_SALES_ONINIT: ColDef[] = [
  {
    headerName: '',
    field: 'photo_new',
    cellRenderer: WBImgFormatter,
    sortable: true,
    resizable: true,
    width: 40,
    filter: false,
    suppressSizeToFit: true,
    flex: 0,
    cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
    cellClass: CELL_CLASS,
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'success',
      photoField: 'photo_new', // Field containing the photo information
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Артикул МП',
    field: 'nmid',
    // cellRenderer: WBProductsLinkEmitter,
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Баркод',
    field: 'Barcode',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Артикул поставщика',
    field: 'SKU',
    floatingFilter: true,
    valueGetter: (params) => {
      return params.data.SKU;
    },
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    width: 150,
    flex: 0
  },
  {
    headerName: 'Размер',
    field: 'techSize',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
  },
  {
    headerName: 'Цвета',
    field: 'colors',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
  {
    headerName: 'Категория МП',
    field: 'category',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Бренд',
    field: 'brand',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    wrapText: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0
  },
  {
    headerName: 'Себестоимость (общая)',
    field: 'sebes',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    comparator: NumberSortFunc,
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.2-2'), 'rubles'),
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 0,
    cellClass: CELL_CLASS,
    cellStyle : {
      fontWeight: '600'
    }
  },
  {
    headerName: 'Себестоимость закупки',
    field: 'cost_price',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    comparator: NumberSortFunc,
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.2-2'), 'rubles'),
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Себестоимость доп. расходов',
    field: 'other_deductions',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    comparator: NumberSortFunc,
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.2-2'), 'rubles'),
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: '',
    flex: 0,
    cellRenderer: ButtonRendererComponent,
    cellStyle: { paddingLeft: '0px', paddingRight: '0px'},
  },
  {
    headerName: 'Статус',
    field: 'status_name',
    floatingFilter: true,
    editable: true,
    sortable: true,
    resizable: true,
    cellStyle: { background: '#f0f3f5' },
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    width: 150,
    flex: 0,
    filter: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: GENERAL_STATUSES,
    },
  },
  {
    headerName: 'Категория собственная',
    field: 'category_pnl',
    floatingFilter: true,
    sortable: false,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    editable: true,
    cellStyle: { background: '#f0f3f5' },
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    flex: 0,
  },
  // {
  //   headerName: 'Дата последней продажи',
  //   field: 'last_sale',
  //   floatingFilter: true,
  //   sortable: true,
  //   resizable: true,
  //   width: 150,
  //   filter: true,
  //   filterParams: FILTER_PARAMS_TEXT,
  //   suppressSizeToFit: true,
  //   flex: 0,
  //   cellClass: CELL_CLASS_LEFT,
  // },
  // {
  //   headerName: 'Доступно на FBO',
  //   field: 'quantity',
  //   floatingFilter: true,
  //   sortable: true,
  //   resizable: true,
  //   width: 150,
  //   filter: true,
  //   filterParams: FILTER_PARAMS_TEXT,
  //   suppressSizeToFit: true,
  //   flex: 0,
  //   cellClass: CELL_CLASS,
  // },
  {
    headerName: 'Кабинет',
    field: 'shop_name',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
];

export let PRODUCTS_COLUMN_DEFS_SALES_ONINIT_WB: ColDef[] = [
  {
    headerName: '',
    field: 'photo_new',
    cellRenderer: WBImgFormatter,
    sortable: true,
    resizable: true,
    width: 40,
    filter: false,
    suppressSizeToFit: true,
    flex: 0,
    cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
    cellClass: CELL_CLASS,
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'success',
      photoField: 'photo_new', // Field containing the photo information
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Артикул МП',
    field: 'nmid',
    // cellRenderer: WBProductsLinkEmitter,
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Баркод',
    field: 'Barcode',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Артикул поставщика',
    field: 'SKU',
    floatingFilter: true,
    valueGetter: (params) => {
      return params.data.SKU;
    },
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    width: 150,
    flex: 0
  },
  {
    headerName: 'Размер',
    field: 'techSize',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
  },
  {
    headerName: 'Цвета',
    field: 'colors',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
  {
    headerName: 'Категория МП',
    field: 'category',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Бренд',
    field: 'brand',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    wrapText: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0
  },
  {
    headerName: 'Ярлык',
    field: 'tags',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    wrapText: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0
  },
  {
    headerName: 'Себестоимость (общая)',
    field: 'sebes',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    comparator: NumberSortFunc,
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.2-2'), 'rubles'),
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 0,
    cellClass: CELL_CLASS,
    cellStyle : {
      fontWeight: '600'
    }
  },
  {
    headerName: 'Себестоимость закупки',
    field: 'cost_price',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    comparator: NumberSortFunc,
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.2-2'), 'rubles'),
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Себестоимость доп. расходов',
    field: 'other_deductions',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    comparator: NumberSortFunc,
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.2-2'), 'rubles'),
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: '',
    flex: 0,
    cellRenderer: ButtonRendererComponent,
    cellStyle: { paddingLeft: '0px', paddingRight: '0px'},
  },
  {
    headerName: 'Статус',
    field: 'status_name',
    floatingFilter: true,
    editable: true,
    sortable: true,
    resizable: true,
    cellStyle: { background: '#f0f3f5' },
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    width: 150,
    flex: 0,
    filter: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: GENERAL_STATUSES,
    },
  },
  {
    headerName: 'Категория собственная',
    field: 'category_pnl',
    floatingFilter: true,
    sortable: false,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    editable: true,
    cellStyle: { background: '#f0f3f5' },
    valueFormatter: params =>  '✎ ' + (params.value || ''),
    flex: 0,
  },
  // {
  //   headerName: 'Дата последней продажи',
  //   field: 'last_sale',
  //   floatingFilter: true,
  //   sortable: true,
  //   resizable: true,
  //   width: 150,
  //   filter: true,
  //   filterParams: FILTER_PARAMS_TEXT,
  //   suppressSizeToFit: true,
  //   flex: 0,
  //   cellClass: CELL_CLASS_LEFT,
  // },
  // {
  //   headerName: 'Доступно на FBO',
  //   field: 'quantity',
  //   floatingFilter: true,
  //   sortable: true,
  //   resizable: true,
  //   width: 150,
  //   filter: true,
  //   filterParams: FILTER_PARAMS_TEXT,
  //   suppressSizeToFit: true,
  //   flex: 0,
  //   cellClass: CELL_CLASS,
  // },
  {
    headerName: 'Кабинет',
    field: 'shop_name',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
];

function editorHandler(value: any) {
  console.log(value);
}

function numberIsNan(params: any): boolean {
  return isNaN(params.newValue);
}

export function generateColumnDefs(isOzon: boolean): ColDef[] {
  const storedDefs = localStorage.getItem('products-column-defs');

  if(isOzon) {
    return PRODUCTS_COLUMN_DEFS_SALES_ONINIT
  }
  return PRODUCTS_COLUMN_DEFS_SALES_ONINIT_WB


    // if(!storedDefs) {
        // return PRODUCTS_COLUMN_DEFS_SALES_ONINIT
    // }
    // const newDefs: ColDef[] = []
    // const parsedDefs = JSON.parse(storedDefs) as ColDef[]

    // parsedDefs.forEach(def => {
    //   const foundDef: ColDef | undefined = PRODUCTS_COLUMN_DEFS_SALES_ONINIT.find(d => d.field === def.field);
    //   if(foundDef) {
    //     newDefs.push(foundDef)
    //   }
    // })
    // return newDefs;
}
