import {
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexYAxis,
    ApexTitleSubtitle,
    ApexFill,
    ApexTooltip,
  } from 'ng-apexcharts';
  
  export enum TableGroupBy {
    BY_DATE,
    BY_PRODUCT,
  }
  
  export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis | ApexYAxis[];
    title: ApexTitleSubtitle;
    labels: string[];
    stroke: any; // ApexStroke;
    dataLabels: any; // ApexDataLabels;
    fill: ApexFill;
    tooltip: ApexTooltip;
  };
  
  export interface ICardWidget {
    title: string;
    drr?: number | null;
    subfields: {
      name: string;
      value: number | string;
      unit: 'percent' | 'rubles' | 'item' | 'day';
      diffInPercents?: number;
      ratioInPercents?: number;
      description?: string;
    }[];
    salesPercent?: number;
    ordersPercent?: number;
    totalCount?: number | string;
    totalSum?: number | string;
    totalUnits?: number | string;
    diffInPercents?: number;
    underConstruction?: boolean;
    description?: string | null;
  }
  
  export interface IMainTabTableData {
    date: string;
    stockNumber: string;
    sku: number;
    ordersCount: number;
    ordersTotal: number;
    cancelsCount: number;
    cancelsTotal: number;
    salesCount: number;
    salesTotal: number;
    returnsCount: number;
    returnsTotal: number;
    category?: string;
    itemName?: string;
  }
  