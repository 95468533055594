import { Pipe, PipeTransform } from '@angular/core';
import { ICurrentTariff, ITariffItem, TariffPlansTitles } from '../tariff.interface';

@Pipe({
  name: 'buttonDisabledPipe'
})
export class ButtonDisabledPipe implements PipeTransform {

  transform(plan: ITariffItem, currentPLan: ICurrentTariff): boolean {
    return plan.weight < currentPLan.value
  }
}
