import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IGetChartOrders,
  IMyOrdersResponse,
} from '../../../../../dashboard/myorders/myorders.interface';
import { ApiService } from '../../../../../../shared/services/api.service';
import { ActionChartProductID, IProductChartRequest, ProductActionType, ProductIdType } from '../product-id.interface';
import { finalize } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-product-id-orders-chart',
  templateUrl: './product-id-charts.component.html',
  styleUrls: ['./product-id-charts.component.scss'],
})
export class ProductIdChartsComponent implements OnInit {
  items_count = 0;
  items_count_avg = 0;
  order_cancel_total = 0;
  percent_cancel = 0;
  order_money_total = 0;
  order_qty_total = 0;

  itemsCount: number;
  itemsCountAvg: number;

  chartOptions: any;

  bodyRequest = {};

  currentProductType: ProductIdType = 'Orders';

  @Input() resForResponseSales = null;

  @Output() stopLoading = new EventEmitter();

  constructor(
    private api: ApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  loadData(bodyRequest: IProductChartRequest, action: ProductActionType) {
    this.api
      .userRegister(action, localStorage.getItem('token'), bodyRequest)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.stopLoading.emit()),
      )
      .subscribe(
        (data: IMyOrdersResponse) => {
          this.items_count = data.count;
          if (+this.resForResponseSales === 0) {
            +data.count === 0 ? (this.items_count_avg = 0) : (this.items_count_avg = Math.round(data.count));
          } else {
            this.items_count_avg = Math.round(data.count / this.resForResponseSales);
          }
        },
        () => {},
      );
  }

  getChart<T>(bodyRequest: IProductChartRequest, actionChart: ActionChartProductID) {
    this.api
      .userRegister(actionChart, localStorage.getItem('token'), bodyRequest)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          if (actionChart === 'getChartOrders') {
            this.setOrdersChartOption(data);
            this.setterDetailsForOrders(data);
          }
          if (actionChart === 'getChartSales') {
            this.setSalesChartOption(data);
            this.setterDetailsForSales(data);
          }
        },
        () => {},
      );
  }

  setterDetailsForOrders(data: IGetChartOrders) {
    this.order_money_total = data.order_money_total;
    this.order_qty_total = data.order_qty_total;
    this.order_cancel_total = data.order_cancel_total;
    if (+this.order_qty_total === 0) {
      this.percent_cancel = 0;
    } else {
      this.percent_cancel = +((this.order_cancel_total * 100) / this.order_qty_total).toFixed(2);
    }
  }

  setterDetailsForSales(data: any) {
    this.order_money_total = data.sales_money_total;
    this.order_qty_total = data.sales_qty_total;
    this.order_cancel_total = data.sales_return_money_total;
    if (+this.order_qty_total === 0) {
      this.percent_cancel = 0;
    } else {
      this.percent_cancel = +((this.order_cancel_total * 100) / this.order_qty_total).toFixed(2);
    }
  }

  setOrdersChartOption(data: any) {
    this.chartOptions = null;
    this.chartOptions = {
      series: [
        { name: 'Заказы ₽', type: 'line', data: data.order_money },
        { name: 'Заказы шт.', type: 'column', data: data.order_qty },
      ],
      chart: { type: 'area', height: 300, stacked: false, animations: { enabled: true } },
      dataLabels: { enabled: false },
      legend: { horizontalAlign: 'left', offsetX: 40 },
      xaxis: { type: 'category', categories: data.category },
      yaxis: [
        {
          seriesName: 'Column A',
          floating: false,
          forceNiceScale: true,
          tickAmount: 6,
          show: false,
          axisTicks: { show: true },
          axisBorder: { show: true },
          labels: { show: false },
        },
        { floating: false, forceNiceScale: true, tickAmount: 6, show: true },
      ],
    };
  }

  setSalesChartOption(data: any) {
    this.chartOptions = null;

    this.chartOptions = {
      series: [
        { name: 'Продажи шт.', type: 'column', data: data.sales_qty },
        { name: 'Возвраты шт.', type: 'column', data: data.sales_return_qty },
        { name: 'Продажи ₽', type: 'line', data: data.sales_money },
      ],
      chart: { type: 'area', height: 330, stacked: false },
      dataLabels: { enabled: false },
      colors: ['#1A74E8', '#B33824', '#B22884'],
      stroke: { curve: 'smooth' },
      legend: { horizontalAlign: 'left', offsetX: 40 },
      xaxis: { type: 'category', categories: data.category },
      yaxis: [
        { seriesName: 'Column A', floating: false, forceNiceScale: true, tickAmount: 6 },
        { floating: false, forceNiceScale: true, tickAmount: 6, show: false },
        { forceNiceScale: true, floating: false, opposite: true },
      ],
    };
  }
}
