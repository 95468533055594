import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { GlobalConfig } from 'ngx-toastr';
import {
  CUSTOM_STYLES,
  GENERAL_STATUSES,
  generateColumnDefs,
  LOCALE_TEXT_FOR_FILTERS,
  PRODUCTS_COLUMN_DEFS_SALES_ONINIT,
  SEBES_COL_DEF,
  SEBES_COL_DEF2,
} from './products.mock';
import { HttpErrorResponse } from '@angular/common/http';
import { CellEditingStoppedEvent, CellValueChangedEvent, ColDef, DragStoppedEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CellChangesTypes,
  IBodyRequestCell,
  IProductsResponse,
  IProductsRowData,
  IStatus,
  ProductCellChanges,
} from './products.interface';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import * as FileSaver from 'file-saver';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { Nullable } from '../../../shared/interfaces/common.type';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { CommonResponseOperation1 } from '../../../shared/interfaces/common.interface';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '../../../shared/injection-tokens/window';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [MessageService, ConfirmationService],
  styles: [CUSTOM_STYLES],
})
export class ProductsComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  apiParams: any;
  readonly defaultColDef: ColDef = {
    sortable: true,
    flex: 1,
    minWidth: 50,
    filter: true,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    headerClass: 'fs-11'
  };
  readonly defaultColDef2: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    headerClass: 'fs-11'
  }
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly cellClass = [
    'd-flex',
    'justify-content-center',
    'align-items-center',
  ];
  readonly tooltipShowDelay = 0;
  readonly tooltipHideDelay = 2000;
  columnDefsSales: any;
  rowData: IProductsRowData[] = [];
  options: GlobalConfig;
  // AG-GRID
  productForm: FormGroup;
  loading: any = true;
  items: any;
  cost_price: any;
  rowHeight: any = 40;
  rowSelection: any = 'multiple';
  rowModelType: any = 'infinite';
  cacheOverflowSize: any = 2;
  paginationPageSize: any = 100;
  maxConcurrentDatasourceRequests: any = 3;
  infiniteInitialRowCount: any = 1000;
  maxBlocksInCache: any = 10;
  cacheBlockSize: any = 100;
  arr_par: any = [];
  readonly gridOptions: GridOptions = {};
  params_list: any;
  renderer: any;
  view_google_table_info = true;
  OBOROT = 0;
  SHOP_ID: number;
  customers: IProductsRowData[] = [];
  supplier_arr: any;
  // isLoading = false;
  excelList: any;
  categoryList = [];
  isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;

  statusesList: IStatus[] = null;
  readonly URL_NEW: string;
  readonly TOKEN: string;
  readonly statusesForm: UntypedFormGroup;
  @ViewChild('productStatuesModal', { read: TemplateRef })
  productStatuesModal: TemplateRef<any>;
  @ViewChild('tpl', { read: TemplateRef })
  tpl: TemplateRef<any>;
  protected gridApi;
  protected gridColumnApi;

  modalData:any;
  coldef = SEBES_COL_DEF;
  coldef2 = SEBES_COL_DEF2;
  text = ''
  subtext = ''
  date = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};
  history_changes = [];
  all_articles = [];
  cost_price_input = null;
  other_deductions_input = null;

  gridApi1: GridApi;
  gridApi2: GridApi;

  gridOptions2 = {
    context: { componentParent: this },
    enableCellTextSelection: true,
    suppressRowClickSelection: true,
    getRowNodeId: (data) => String(data.id)
  } as GridOptions;

  isError = false;

  private readonly STRING_REGEX = /^[\u0400-\u04FFa-zA-Z0-9 .,_-]+$/;

  constructor(
    private api: ApiService,
    private router: Router,
    private mpSurfService: MpSurfApiService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    @Inject(WINDOW) private readonly WINDOW: Window
  ) {
    super();

    this.SHOP_ID = +JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.supplier_arr = localStorage.getItem('supplier');
    this.productForm = this.fb.group({ products: this.fb.array([]) });
    this.view_google_table_info = localStorage.getItem('googleMsgInfo') == null;
    this.gridOptions = {
      context: { componentParent: this },
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
    } as GridOptions;
    this.URL_NEW = environment.apiNew;
    this.TOKEN = localStorage.getItem('token');
    this.statusesForm = new UntypedFormGroup({
      statuses: new FormArray([]),
    });
  }

  get FormArrayStatuses(): FormArray {
    return this.statusesForm.controls['statuses'] as FormArray;
  }

  get isDisabledModal(): boolean {
    if (this.FormArrayStatuses?.controls) {
      let value = this.FormArrayStatuses.getRawValue()
        .map((a: IStatus) => a?.name)
        .filter((x: Nullable<string>) => x !== null && x !== '');
      return value.length === 0;
    }
    return true;
  }

  getRowStyle = (params: any) => {
    const data = params?.data as IProductsRowData;
    const style = { background: 'transparent' };
    const lastDate = data?.last_sale;
    if (data && lastDate) {
      const daysDifference = moment().diff(lastDate, 'days');
      if (!data.status_cancel && +data.quantity === 0 && daysDifference > 30) {
        style.background = '#fff8e1';
      }
    }
    return style;
  };

  ngOnInit() {
    if (!this.supplier_arr) {
      this.router.navigate(['/settings/shop/']);
    }
    this.loadStatuses();
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'guides/products') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  onGridReady(params: any = null) {
    this.apiParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.loadProducts();
  }

  onGridReady1(params: GridReadyEvent<any>) {
      this.gridApi1 = params.api;
  }

  onGridReady2(params: GridReadyEvent<any>) {
      this.gridApi2 = params.api;
  }

  setFormControls(): void {
    for (let i = 0; i < 10; i++) {
      const isExisted = this.statusesList.find(a => a?.index === i + 1);
      this.FormArrayStatuses.controls.push(
        new FormGroup({
          index: new FormControl(i + 1),
          name: new FormControl(isExisted ? isExisted?.name : null),
        })
      );
    }
  }

  openModal() {
    this.modalService.open(this.productStatuesModal);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  switchProductsAvailibility(oborotState: number) {
    this.OBOROT = oborotState;
    this.columnDefsSales = generateColumnDefs(this.isOzon);
    this.loadProducts();
  }

  getCustomersMedium() {
    const body = { shop_id: this.SHOP_ID, oborot: this.OBOROT };
    this.api
      .userRegister('getProductList', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IProductsResponse) => {
          this.customers = response.data;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  onCellValueChanged(event: any) {
    const fieldName = event.colDef?.field;
    const rowData = event.data as IProductsRowData;
    if (
      event &&
      event.colDef &&
      CellChangesTypes.includes(fieldName) &&
      this.changedValueValid(rowData, fieldName)
    ) {
      const request = this.generateBodyRequestUpdateCell(rowData, fieldName, event);
      if(!request){
        return
      }
      this.mpSurfService
        .createOrUpdate(request.bodyRequest, request.URL)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response: CommonResponseOperation1) => {
            if (!response.is_error) {
              this.showPopUpMessage(
                'success',
                PopUpMessages.updateSuccessSummary,
                response.msg
              );
            } else {
              this.loadProducts();
              this.showPopUpMessage(
                'error',
                PopUpMessages.updateFailedSummary,
                PopUpMessages.updateFailedMessage
              );
            }
          },
          () => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedMessage
            );
            this.loadProducts();
          }
        );
    } else {
      this.showPopUpMessage(
        'error',
        'Ошибка введения данных',
        'Проверьте введенные данные и повторите попытку'
      );
      this.loadProducts();
    }
  }

  createStatuses(): void {
    const body = {
      statuses: this.FormArrayStatuses.getRawValue(),
    };

    this.mpSurfService
      .createOrUpdate(body, 'products-status')
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.modalService.dismissAll();
          this.showPopUpMessage(
            'success',
            PopUpMessages.createSuccessSummary,
            PopUpMessages.createSuccessMessage
          );

          this.WINDOW.location.reload();
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }

  changedValueValid(params: IProductsRowData, id: ProductCellChanges): boolean {
    let result = true;
    switch (id) {
      case 'category_pnl':
        if (params.category_pnl?.length === 0) {
          return true;
        }
        result =
          this.STRING_REGEX.test(params.category_pnl) && params.category_pnl.length < 100;
        break;
    }
    return result;
  }

  generateBodyRequestUpdateCell(
    params: IProductsRowData,
    id: ProductCellChanges,
    event: CellValueChangedEvent
  ): IBodyRequestCell {
    const request: IBodyRequestCell = {
      bodyRequest: {
        Barcode: params.Barcode,
        nmid: params.nmid,
      },
      URL: '',
    };
    switch (id) {
      case 'cost_price':
        request.bodyRequest.cost_price = +params.cost_price;
        request.URL = 'products-price';
        break;
      case 'mystockwh':
        request.bodyRequest.mystockwh = params.mystockwh;
        request.URL = 'products-stock-wh';
        break;
      case 'production_time':
        request.bodyRequest.production_time = params.production_time;
        request.URL = 'products-production';
        break;
      case 'delivery_time_ff':
        request.bodyRequest.delivery_time_ff = params.delivery_time_ff;
        request.URL = 'products-delivery';
        break;
      case 'category_pnl':
        request.bodyRequest = {};
        request.bodyRequest.id = params.id;
        request.bodyRequest.category_pnl = params.category_pnl;
        request.URL = 'products/category-pnl';
        break;
      case 'my_name':
        request.bodyRequest = {};
        request.bodyRequest.my_name = params.my_name;
        request.bodyRequest.id = params.id;
        request.URL = 'products/my-name';
        break;
      case 'status_name':
        if(!event.newValue && !event.oldValue){
          return
        }
        request.bodyRequest = {};
        const selectedStatus = this.statusesList.find(
          a => a.name === params.status_name
        ) as IStatus;
        request.bodyRequest.product_id = params.id;
        request.bodyRequest.status_id = selectedStatus?.id ? selectedStatus.id : null
        request.URL = 'products/status';
        break;
    }
    return request;
  }

  getActionName(fieldParam: string): string {
    let actionName = '';
    switch (fieldParam) {
      case 'cost_price':
        actionName = 'getProductUpdatePrice';
        break;
      case 'mystockwh':
        actionName = 'updateProductMyStock';
        break;
      default:
        actionName = 'non-action';
    }
    return actionName;
  }

  onRowSelected(event: any) {
    if (!!event.status_cancel === true) {
      this.changeOborotStatus(event.nmid, event.Barcode, event.status_cancel);
    }
  }

  exportExcel() {
    this.mpSurfService
      .load({ oborot: this.OBOROT }, 'products/export')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          this.importXlsx(response);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  onBasicUploadError(event: any, fileUpload: any) {
    this.showPopUpMessage(
      'error',
      'Ошибка загрузки',
      'Проверьте введенные данные и повторите попытку!'
    );
    fileUpload.clear(); // this will clear your file
  }

  onBasicUploadAuto(event: any, fileUpload: any) {
    console.log(event)
    this.showPopUpMessage(
      'success',
      'Загружено',
      event.originalEvent.body.message
    );
    fileUpload.clear(); // this will clear your file
    this.loadProducts();
  }

  onSendData() {}

  protected closeGoogleTableMsg(param) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.view_google_table_info = false;
    }
  }

  private updateCellData(data: IProductsRowData, params: any) {
    const request = {
      Barcode: data.Barcode,
      nmid: data.nmid,
      ...params,
    };
  }

  private loadProducts(): void {
    const requestParams = {
      oborot: this.OBOROT,
    };
    this.mpSurfService
      .load(requestParams, 'products')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IProductsResponse) => {
          this.rowData = response.data;
          this.excelList = response.data;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadStatuses(): void {
    this.mpSurfService
      .load({}, 'products-status')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IStatus[]) => {
          this.statusesList = response;
          this.FormArrayStatuses.reset();
          this.setFormControls();
          GENERAL_STATUSES.length = 0;
          GENERAL_STATUSES.push('')
          
          this.statusesList
            .map(a => a.name)
            .forEach(a => GENERAL_STATUSES.push(a));
          this.columnDefsSales = generateColumnDefs(this.isOzon);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private importXlsx(excelData: any): void {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'MyProducts');
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  private getRowData(
    shop_id: number,
    startRow: number,
    endRow: number,
    filter: any,
    sort: any,
    oborot
  ) {
    this.arr_par = {
      shop_id,
      params_list: this.params_list,
      startRow,
      endRow,
      filter,
      sort,
      oborot,
    };
    return this.api.userRegister(
      'getProductList',
      localStorage.getItem('token'),
      this.arr_par
    );
  }

  private changeOborotStatus(nmid: any, barcode: any, statusCancel: any) {
    const body = { shop_id: this.SHOP_ID, nmid, barcode, status: statusCancel };
    this.api
      .userRegister(
        'getProductUpdateOborot',
        localStorage.getItem('token'),
        body
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          if (+response.is_error === 0) {
            this.getCustomersMedium();
          }
        },
        (error: HttpErrorResponse) => {
          +error.status === 200
            ? this.getCustomersMedium()
            : this.showPopUpMessage(
                'error',
                PopUpMessages.loadFailedSummary,
                PopUpMessages.loadFailedMessages
              );
        }
      );
  }

  onCellEditingStopped(event: CellEditingStoppedEvent){
    if(event.colDef.field === 'category_pnl' && !event.newValue) {
      const request = {
        bodyRequest: {
          id: event.data.id,
          category_pnl: event.data.category_pnl ? event.data.category_pnl : null
        },
        URL: 'products/category-pnl'
      };
      this.mpSurfService
        .createOrUpdate(request.bodyRequest, request.URL)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response: CommonResponseOperation1) => {
            if (!response.is_error) {
              this.showPopUpMessage(
                'success',
                PopUpMessages.updateSuccessSummary,
                response.msg
              );
            } else {
              this.loadProducts();
              this.showPopUpMessage(
                'error',
                PopUpMessages.updateFailedSummary,
                PopUpMessages.updateFailedMessage
              );
            }
          },
          () => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedMessage
            );
            this.loadProducts();
          }
        );
    }
  }


  async onRowClick(id: string) {
    this.isError = false;
    this.modalData = null;
    this.cost_price_input = null;
    this.other_deductions_input = null;
    const data = await lastValueFrom(this._mpSurfService.load({}, `cost-price/${id}`));
    this.modalData = data;
    //@ts-ignore
    this.all_articles = data.all_articles;
    //@ts-ignore
    this.history_changes = data.history_changes;
    this._modalService.open(this.tpl, { centered: true, modalDialogClass: 'mw-700' });
  }

  async onSave(): Promise<void> {
    // if(!this.cost_price_input){
    //   this.isError = true
    //   return;
    // }
    const date = `${(this.date.year)}-${String(this.date.month).padStart(2, '0')}-${String(this.date.day).padStart(2, '0')}`;
    const all_articles = [];
    const history_changes = [];
    this.gridApi1.getModel().forEachNode(node => all_articles.push(node.data));
    this.gridApi2.getModel().forEachNode(node => history_changes.push(node.data));
    const body = {
      ...this.modalData,
      date,
      cost_price: this.cost_price_input,
      other_deductions: this.other_deductions_input,
      all_articles,
      history_changes
    }

    await lastValueFrom(this._mpSurfService.loadByPost(body, `cost-price/${this.modalData.id}`));
    this.loadProducts();
    this.loadStatuses();

    this._modalService.dismissAll();
  }

  onClose(): void {
    this._modalService.dismissAll()
  }

  apply(): void {
    if(!this.cost_price_input){
      return
    }
    this.all_articles = this.all_articles.map(a => ({...a, cost_price: this.cost_price_input}));

    if(this.other_deductions_input) {
      this.all_articles = this.all_articles.map(a => ({...a, other_deductions: this.other_deductions_input}));
    }
  }

  onDeleteClick(id: number): void {
    const rowNode = this.gridApi2.getRowNode(String(id)); // Get the row node by ID
    if (rowNode) {
      const updatedData = this.gridOptions2.rowData.filter((row) => row.id !== id); // Remove the row from the data
      this.gridApi2.applyTransaction({ remove: [rowNode.data] }); // Update the grid
      this.gridOptions2.rowData = updatedData; // Update the underlying data source
    } else {
      console.warn(`Row with ID ${id} not found.`);
    }
  }
}
