import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { ProductsComponent } from "../products.component";

@Component({
  selector: "app-button-renderer",
  template: `
    <button (click)="onRowClick()" style="
    font-size: .7rem;
    height: 25px;
    margin-top: -4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;" 
    class="btn btn-primary">Изменить себестоимость</button>
  `,
})
export class ButtonRendererComponent implements ICellRendererAngularComp {

  id: string | null = null;
  componentParent: ProductsComponent

  agInit(params: ICellRendererParams<any>): void {
    this.id = params.data['id'];
    this.componentParent = params.context.componentParent;
  }

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  onRowClick(): void {
    this.componentParent.onRowClick(this.id)
  }
}
