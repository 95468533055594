import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MpSurfApiService } from '../shared/services/mpsurf-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCALE_TEXT_FOR_FILTERS } from './guides/products/products.mock';
import {
  MpCommonResponse,
  Nullable,
  PageTitles,
  PopUpStatus,
} from '../shared/interfaces/common.type';
import { LOCALE_RU_FILTERS } from './finances/finoperations/finoperations.mock';
import { ColDef, GridApi } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IMpMetaData,
  PageVideoLink,
} from '../shared/interfaces/mp-common.interface';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { LOCAL_STORAGE_KEYS } from '../shared/contants/localStorage';
import { SharedDataService } from '../shared/services/shared-data.service';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable, of } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-abstract-menu-tab',
  template: '',
  providers: [MessageService, ConfirmationService],
})
export abstract class AbstractMenuTabComponent {
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly playIcon = faPlay;
  readonly LOCALE_RU_FILTERS = LOCALE_RU_FILTERS;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };

  readonly initialDate = {
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
  };

  protected readonly _router = inject(Router);
  protected readonly _route = inject(ActivatedRoute);
  protected readonly _cdr = inject(ChangeDetectorRef);
  protected readonly _mpSurfService = inject(MpSurfApiService);
  protected readonly _sharedDataService = inject(SharedDataService);
  protected readonly _messageService = inject(MessageService);
  protected readonly _confirmationService = inject(ConfirmationService);
  protected readonly _modalService = inject(NgbModal);

  protected SHOP_ID: number;
  protected readonly MARKETPLACE: number;
  protected readonly TOKEN: string;
  protected readonly URL_OLD: string;
  protected readonly API_NEW: string;
  protected readonly LAST_REPORT_DATE: string;

  //AG GRID
  protected gridApi: GridApi;
  protected gridOptions: GridOptions = {};
  protected isPromokodUsed$ = new BehaviorSubject<boolean>(false);

  isLoading = false;
  isEditModal = false;
  isExcelAllowed$ = new BehaviorSubject<number>(0);

  protected constructor() {
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop'))?.shop;
    this.MARKETPLACE =
      JSON.parse(localStorage.getItem('mpall_shop'))?.marketPlace ?? 1;
    this.TOKEN = localStorage.getItem('token');
    this.URL_OLD = environment.apiOld;
    this.API_NEW = environment.apiNew

    this.initMetaData();
  }

  get pageVideoLinks(): Nullable<string> {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.METADATA_VIDEO_LINKS);
  }

  getVideoLinkByPage(pageTitle: PageTitles): string {
    if (this.pageVideoLinks) {
      const videoLinks = JSON.parse(this.pageVideoLinks) as PageVideoLink[];

      return videoLinks.find(a => a.pageTitle === pageTitle)?.link;
    }
  }

  private initMetaData() {
    const timeNow = new Date().getTime();
    const lastUpdateTime = localStorage.getItem('mpMetaDatalastUpdateTime');

    if (!lastUpdateTime || (lastUpdateTime && timeNow > +lastUpdateTime + 1)) {
      this.loadMetaData();
    }
  }

  private loadMetaData(): void {
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: MpCommonResponse<IMpMetaData>) => {
          if (response?.is_error === 0) {
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.META_DATA_NOTIFICATIONS,
              JSON.stringify(response.data.notifications)
            );

            this._sharedDataService.setUpData(
              response.data.notifications,
              'notifications'
            );

            localStorage.setItem(
              LOCAL_STORAGE_KEYS.METADATA_VIDEO_LINKS,
              JSON.stringify(response.data.tutorialVideoLink)
            );

            localStorage.setItem(
              LOCAL_STORAGE_KEYS.META_DATA_LAST_UPDATE_TIME,
              new Date().getTime().toString()
            );
          }
          //@ts-ignore
          this.isPromokodUsed$.next(!!response?.data?.partners);
          this.isExcelAllowed$.next(response.data.button_active)
        },
      });
  }

  protected initShopID(): void {
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop'))?.shop;
  }

  protected getReportDate(): Observable<Record<string, string>> {
    return this._mpSurfService.load({}, 'api-reports')
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  showPopUpMessage(
    severity: PopUpStatus,
    summary: string,
    message: string
  ): void {
    this.isLoading = false;
    this._messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}