import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { MessageService, ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { CLUSTERS_BY_COUNTRIES_ROW_DATA, GRADES } from './clusters.mock';
import { ColumnApi, GridReadyEvent } from 'ag-grid-community';
import { generate_clusters } from './clusters.func';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { ICardWidget } from '../main-test/main-test.interface';
import { environment } from 'environments/environment';
import { IStatus } from 'app/menu-tabs/guides/products/products.interface';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-clusters',
  templateUrl: './clusters.component.html',
  styleUrls: ['./clusters.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class ClustersComponent extends AbstractMenuTabComponent implements AfterViewInit, OnInit {

  isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;

  selectedDate: ISelectedNgbDate = this._sharedDataService.filterDate || {
    startDate: moment().subtract(7, 'day'),
    endDate: moment().subtract(1, 'day'),
  };
  cards: ICardWidget[] = []
  countryData = CLUSTERS_BY_COUNTRIES_ROW_DATA
  grades = GRADES
  environment = environment

  tagsList = [];
  statusesList = [];
  categoriesList = [];
  selectedTags = [];
  selectedStatuses = [];
  selectedCategories = [];

  private gridColumnApi: ColumnApi;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if(!this.isOzon)  {
      this.loadStatuses();
      this.loadCategories();
      this.loadTags()
    }
  }

  ngAfterViewInit(): void {
    const elems = document.querySelectorAll('.pathComponent')
    elems.forEach(elem => elem.addEventListener('mouseover', () => {
      // console.log(elem.id)
    }))
  }

  onGridReady(params: GridReadyEvent<any>) {
    const columns = [
      'group-0',
      'group-1',
      'group-2',
      'group-3',
      'group-4',
      'group-5',
      'group-6',
    ]
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  collapse(){
    const columns = [
      'group-0',
      'group-1',
      'group-2',
      'group-3',
      'group-4',
      'group-5',
      'group-6',
    ]
    columns.forEach(groupId => {
      this.gridColumnApi.setColumnGroupOpened(groupId, true);
    });
  };

  dateChanged($event: any) {
    this._sharedDataService.setFilterDate($event)
    this.loadData()
  }

  loadData() {
    const body = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      tags: Array.isArray(this.selectedTags) ? this.selectedTags.map(s => s.name) : [],
      myStatus: Array.isArray(this.selectedStatuses) ?  this.selectedStatuses.map(s => s.name) : [],
      categoryPnl: Array.isArray(this.selectedCategories) ? this.selectedCategories.map(s => s.name) : [],
    }
    forkJoin([
      this._mpSurfService.loadByPost(body, 'clusters/data'),
      // this._mpSurfService.load({
      //   beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      //   endDate: this.selectedDate.endDate.format('YYYY-MM-DD')
      // }, 'indicators/cards')
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([clusterData]) => {
      //@ts-ignore
      // this.cards = cardData.splice(0,3)
      //@ts-ignore
      this.gridApi.setRowData(clusterData);
      this.gridApi.setColumnDefs(generate_clusters(clusterData[0]));
      this.collapse();
      this._cdr.markForCheck()

    })
  }

  private loadTags(): void {
    this._mpSurfService
      .load({}, 'products/tags')
      .pipe(untilDestroyed(this))
      .subscribe(
        (list: string[]) => {
          this.tagsList = list.map(item => ({name: item}));;
        },
    );
  }

  private loadStatuses(): void {
      this._mpSurfService
        .load({}, 'products-status')
        .pipe(untilDestroyed(this))
        .subscribe(
          (response: IStatus[]) => {
            this.statusesList = response;
          },
      );
    }
  
  private loadCategories(): void {
      this._mpSurfService
        .load({}, 'products/category-pnl-list')
        .pipe(untilDestroyed(this))
        .subscribe(
          (response: string[]) => {
            this.categoriesList = response.map(item => ({name: item}));
          },
    );
  }

  selectTag(): void {
    this.loadData();
  }

  selectStatus(): void {
    this.loadData();
  }

  selectCategory(): void {
    this.loadData();
  }

  onClearTags(): void {
    this.selectedTags = [];
    this.loadData();
  }
  onClearStatuses(): void {
    this.selectedStatuses = [];
    this.loadData();
  };

  onClearCategories(): void {
    this.selectedCategories = [];
    this.loadData();
  }
}
