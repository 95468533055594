import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { IArticlesPurchase } from 'app/menu-tabs/guides/finpurchase/finpurchase.interface';
import { ISelectedNgbDate } from 'app/shared/interfaces/common.interface';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';
import * as moment from 'moment';
import { ApexOptions } from 'ng-apexcharts';
import { ConfirmationService, MessageService } from 'primeng/api';
import { genarateColumnDefs, generateSummary, getChartOptions } from './adv-stat.func';
import { ColDef, GridReadyEvent, RowNode } from 'ag-grid-community';
import { LOCALE_TEXT_FOR_FILTERS } from 'app/menu-tabs/guides/products/products.mock';


export interface IAdvStatChartPoint {
  orders_count: string,
  orders_sum: string,
  adv_sum: string,
  adv_count: string,
  date: string
}

export interface IAdvStatRowData {
  advertId: string,
  name?: string,//only WB
  adv_type?: string,//only WB
  nmId: string,
  views: number,
  clicks: number,
  atbs: number,
  shks: number,
  sum: number,
  sum_price: number,
  cr: number,
  cpc: number,
  ctr: number,
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-adv-stat',
  templateUrl: './adv-stat.component.html',
  styleUrls: ['./adv-stat.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class AdvStatComponent extends AbstractMenuTabComponent implements OnInit {

  chartOptions: Partial<ApexOptions> | null = null;
  articles = [];
  dateParams = null;
  selectedNm = [];
  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(7, 'day'),
    endDate: moment().subtract(1, 'day'),
  };
  rowData = [];
  isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
  readonly defaultColDef: ColDef = {
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'right' },
  };
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  columnDefs = [];
  summaryRow = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadArticles();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  onGridFilterChanged(): void {
    const rows = [];
    this.gridApi.forEachNodeAfterFilter((node : RowNode) => {
      rows.push(node.data)
    })
    this.summaryRow = generateSummary(rows);
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.dateParams = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    };
    this.loadChart({...this.dateParams, nmId: Array.isArray(this.selectedNm) ?  this.selectedNm.map(item => +item.code) : []});
    this.loadTable({...this.dateParams, nmId: Array.isArray(this.selectedNm) ?  this.selectedNm.map(item => +item.code) : []});
  }

  onChange(event: { value: {name: string, code: string}[]}) {
    this.loadChart({...this.dateParams, nmId: Array.isArray(this.selectedNm) ?  this.selectedNm.map(item => +item.code) : []});
    this.loadTable({...this.dateParams, nmId: Array.isArray(this.selectedNm) ?  this.selectedNm.map(item => +item.code) : []});
  }

  onClear() {
    this.selectedNm = []
    this.loadChart({...this.dateParams, nmId: this.selectedNm});
    this.loadTable({...this.dateParams, nmId: this.selectedNm});
  }

  loadChart(params: object): void {
    this._mpSurfService.loadByPost(params, 'adv-stat/chart').pipe(untilDestroyed(this))
    .subscribe({
      next: (data: { data: IAdvStatChartPoint[] }) => {
        this.chartOptions = getChartOptions(data);
        this._cdr.detectChanges()
      },
      error: () => {
        this.showPopUpMessage(
          'error',
          PopUpMessages.loadFailedSummary,
          PopUpMessages.loadFailedMessages
        );
      },
    })
  }

  loadTable(params: object): void {
    this._mpSurfService.loadByPost(params, 'adv-stat/table').pipe(untilDestroyed(this))
    .subscribe({
      next: (data: { data: IAdvStatRowData[] }) => {
        this.columnDefs = genarateColumnDefs(this.isOzon);
        this.rowData = data.data;
        this.summaryRow = generateSummary(data.data);
        this._cdr.markForCheck()
      },
      error: () => {
        this.showPopUpMessage(
          'error',
          PopUpMessages.loadFailedSummary,
          PopUpMessages.loadFailedMessages
        );
      },
    })
  }

  loadArticles(): void {
    this._mpSurfService
      .load({}, 'products/important')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IArticlesPurchase[]) => {
          this.articles = data.map((a) => {
            return {
              name: `${a.nmid} - ${a.SKU}`,
              code: a.nmid,
            };
          });
          this._cdr.markForCheck()
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      });
    }
}