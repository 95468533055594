import { DecimalPipe } from '@angular/common';
import { ApexOptions } from 'ng-apexcharts';
import { IChartPoint, IMySalesRowData } from './mysales.interface';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { SALES_TABLE_COLUMN_DEFS, SALES_TABLE_COLUMN_DEFS_OZON } from './mysales.mock';
import { UnitPipe } from '../../../shared/pipes/unit.pipe';

const decimalPipe = new DecimalPipe('ru-RU');
const unitPipe = new UnitPipe();

export function getChartOptions(data: {
  chart: IChartPoint[];
}): Partial<ApexOptions> {
  return {
    series: [
      {
        name: 'Продажи (шт)',
        type: 'column',
        data: data.chart.map(elem => +elem.sales_count || 0),
        color: '#74db90',
      },
      {
        name: 'Продажи (руб)',
        type: 'line',
        data: data.chart.map(elem => +elem.sales_sum || 0),
        color: 'rgba(9, 60, 143, 1)',
      },
      {
        name: 'Возвраты (шт)',
        type: 'column',
        data: data.chart.map(elem => +elem.returns_count || 0),
        color: '#e8b0c6',
      },
      {
        name: 'Возвраты (руб)',
        type: 'line',
        data: data.chart.map(elem => +elem.returns_sum || 0),
        color: 'rgba(186, 20, 20, 1)',
      },
    ],
    chart: {
      height: 480,
      type: 'area',
      stacked: false,
      animations: {
        enabled: true,
      },
    },
    dataLabels: { enabled: false },
    //@ts-ignore
    stroke: { curve: 'smooth', width: [2, 3, 2] },
    legend: { horizontalAlign: 'left', offsetX: 40 },
    xaxis: {
      type: 'category',
      categories: data.chart.map(elem => elem.gr_date),
    },
    yaxis: [
      {
        seriesName: 'Продажи (шт)',
        opposite: true,
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0'),
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Штуки',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Продажи (руб)',
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0'),
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Рубли',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Продажи (шт)',
        opposite: true,
        show: false,
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
        },
      },
      {
        seriesName: 'Продажи (руб)',
        show: false,
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
        },
      },
    ],
  };
}


export function generateColumnDefsByDate(rowData: IMySalesRowData | null, isOzon: boolean, showInRubles = false): (ColDef | ColGroupDef)[] {
  if(!rowData) return []
  const dates: (ColDef | ColGroupDef)[] = Object.entries(rowData)
  .filter(([key, value]) => key.match(/\d\d.\d\d/))
  .map(([key, value]) => {
    return  {
      field: key,
      headerName: key,
      width: 95,
      type: 'rightAligned',
      cellStyle: {
        textAlign: 'right',
      },
      cellRenderer: (params) => {
        if(params.data[key]) {
          return showInRubles 
          ? `${unitPipe.transform(decimalPipe.transform(params.data[key].sale_sum, '1.0-1'), 'rubles')}`
          : `${unitPipe.transform(params.data[key].sale_count, 'item')}`
        }
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        const a = nodeA.data[key]
        const b = nodeB.data[key]
        if(showInRubles) {
          return Number(a.sale_sum) - Number(b.sale_sum)
        }
        return Number(a.sale_count) - Number(b.sale_count)
      },
    }
  })

  const totalColumn: ColDef = {
    field: 'total',
    headerName: 'Итого',
    width: 120,
    type: 'rightAligned',
    cellStyle: {
      textAlign: 'right',
      fontWeight: '700'
    },
    sortable: true,
    cellRenderer: (params) => {
      return showInRubles 
      ? `${unitPipe.transform(decimalPipe.transform(getDataForTotal(params.data).sum, '1.0-1'), 'rubles')}`
      : `${unitPipe.transform(getDataForTotal(params.data).count, 'item')}`
    },
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      let a = 0;
      let b = 0;
      if(showInRubles) {
        a = Object.entries(nodeA.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {sale_sum: string}]) => +value.sale_sum).reduce((acc, curr) => acc + curr, 0)
        b = Object.entries(nodeB.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {sale_sum: string}]) => +value.sale_sum).reduce((acc, curr) => acc + curr, 0)
      } else {
        a = Object.entries(nodeA.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {sale_count: string}]) => +value.sale_count).reduce((acc, curr) => acc + curr, 0)
        b = Object.entries(nodeB.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {sale_count: string}]) => +value.sale_count).reduce((acc, curr) => acc + curr, 0)
      } 
      return Number(a) - Number(b)
    },
  }

  const CONFIG = [
    ...SALES_TABLE_COLUMN_DEFS.slice(0, 5),
    {
      field: 'techSize',
      headerName: 'Размер',
      filter: 'agNumberColumnFilter',
      width: 80,
    },
    totalColumn,
    ...dates
  ]

  CONFIG[1] = isOzon ? SALES_TABLE_COLUMN_DEFS_OZON[1] : SALES_TABLE_COLUMN_DEFS[1]

  return CONFIG
}

export function generateSummary(inputRows: IMySalesRowData[] | null, type: string, columnDefs: (ColDef)[] ):Partial<IMySalesRowData>[]  {
  if(type === "1") {
    const rows = Array.isArray(inputRows) ? inputRows : []
    return [
      {
        photo: 'empty',
        nmID: 'Итого',
        subject: null,
        barcode: null,
        supplierArticle: null,
        sale_date: null,
        return_date: null,
        seller_sum: rows.map(row => +row.seller_sum).reduce((prev, curr) => prev + curr, 0),
        mp_sum: rows.map(row => +row.mp_sum).reduce((prev, curr) => prev + curr, 0),
        spp: null,
        forPay: rows.map(row => +row.forPay).reduce((prev, curr) => prev + curr, 0),
        warehouseName: null,
        regionName: null,
        delivery_type: null,
        orderType: null,
        srid: null,
        self_buyouts: null
      },
    ]
  }

  const rows = Array.isArray(inputRows) && !!inputRows.length ? inputRows : []

  const agg = {};

  if(rows?.length) {
    Object.entries(rows[0])
    .filter(([key, value]) => key.match(/\d\d.\d\d/))
    .map(([key, _value]) => {
      agg[key] = rows.map(row => row[key]).reduce((acc, item) => {
        return {
          sale_count: Number(acc.sale_count) + Number(item.sale_count),
          sale_sum: Number(acc.sale_sum) + Number(item.sale_sum),
        }
      }, {sale_count: 0, sale_sum: 0})
    })
    return [
      {
        photo: 'empty',
        nmID: 'Итого',
        subject: null,
        barcode: null,
        supplierArticle: null,
        total: null,
        ...agg
      },
    ]
  } else {
    columnDefs.map(def => def.field).filter((fieldName) => fieldName.match(/\d\d.\d\d/)).forEach(name => agg[name] = { sale_count: 0, sale_sum: 0  })
    return [
      {
        photo: 'empty',
        nmID: 'Итого',
        subject: null,
        barcode: null,
        supplierArticle: null,
        total: null,
        ...agg
      },
    ]
  }
}

export function getDataForTotal(row: IMySalesRowData): { sum: number, count: number } {
  return Object.entries(row)
    .filter(([key, _value]) => key.match(/\d\d.\d\d/))
    .reduce((acc, [_key, value]) => {
        return {
          count: Number(acc.count) + Number(value.sale_count),
          sum: Number(acc.sum) + Number(value.sale_sum),
        }
      }, {count: 0, sum: 0})
}