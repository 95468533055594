import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColDef, DragStoppedEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { LOCALE_TEXT_FOR_FILTERS } from '../../../guides/products/products.mock';
import { GridOptions } from 'ag-grid';
import { generateColumnDefs } from './reconciliation.mock';
import { AbstractMenuTabComponent } from '../../../../menu-tabs/abstract-menu-tab.component';
import { ISelectedNgbDate } from '../../../../shared/interfaces/common.interface';
import * as moment from 'moment';
import { ICardWidget } from '../../../../menu-tabs/dashboard/main-test/main-test.interface';
import { forkJoin } from 'rxjs';
import { environment } from 'environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reconciliation.component.scss'],

})
export class ReconciliationComponent extends AbstractMenuTabComponent  {
  gridApi!: GridApi<any>;
  rowData: any[] = [];
  cards: ICardWidget[] = [];
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  columnsDef = generateColumnDefs(); 
  // AG-GRID ENDS
  isLoading = false;
  selectedDate: ISelectedNgbDate = this._sharedDataService.filterDate || {
    startDate: moment().subtract(21, 'day'),
    endDate: moment(),
  };

  URL_NEW = environment.apiNew;

  constructor() {
    super()
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  loadData(): void {
    const params = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    }
    forkJoin([
      this._mpSurfService.load(params, 'reconciliation'),
      this._mpSurfService.load(params, 'reconciliation/cards')
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([rowData, cardsData]) => {
      //@ts-ignore
      this.rowData = rowData;
      //@ts-ignore
      this.cards = cardsData;
      this._cdr.markForCheck()
    })
  }

  dateChanged($event: ISelectedNgbDate) {
    this._sharedDataService.setFilterDate($event)
    this.selectedDate = $event;
    this.loadData()
  }

  onBasicUploadError(event: any, fileUpload: any) {
    this.showPopUpMessage(
      'error',
      'Ошибка загрузки',
      'Проверьте введенные данные и повторите попытку!'
    );
    fileUpload.clear(); // this will clear your file
  }

  onBasicUploadAuto(event: any, fileUpload: any) {
    if(!event.originalEvent.body.error) {
      this.showPopUpMessage(
        'success', 
        'Файл загружен. Данные обновлены', 
        ''
      );
      this.loadData();
    } else {
      this.showPopUpMessage(
        'error',
        'Ошибка загрузки',
        event.originalEvent.body.message
      );
    }
    fileUpload.clear(); // this will clear your file
  }

  onDragStopped(event: DragStoppedEvent) {
    // localStorage.setItem('reconciliation-column-defs', JSON.stringify(event.api.getColumnDefs()))
  }
}
