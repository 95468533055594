import { Component, HostListener, inject, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ILoginResponse, IUserData } from './login.interface';
import { finalize, switchMap } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../../menu-tabs/abstract-menu-tab.component';
import { ISupplierResponse } from 'app/general-components/profile/users/users.interface';
import { Router } from '@angular/router';
import {
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class LoginComponent extends AbstractMenuTabComponent implements OnInit {
  errorMessage: string;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  isShown = false;

  readonly LoginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  readonly RegisterForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [
      Validators.required,
      Validators.minLength(12),
    ]),
    agreed: new FormControl(false, [Validators.requiredTrue]),
  });

  private _authService = inject(AuthService);
  private _api = inject(ApiService);
  private readonly _document = inject(DOCUMENT);
  private readonly _renderer = inject(Renderer2);

  constructor(private router: Router) {
    super();
  }

  @HostListener('document:keydown.enter', ['$event'])
  keyDownEnter() {
    if (this.LoginForm.valid) {
      this.logIn();
    }
  }

  ngOnInit(): void {

    window.addEventListener('storage', (event: StorageEvent) => {
      if(event.key === 'token' && event.newValue.length > 10){
        this._authService.setLoggedIn(true);
        this.loadUserData();
      }
    })

    sessionStorage.clear();
    this._route.queryParams.subscribe(params => {
      if (params.lostpassword) {
        this._router.navigate(['/auth/register']);
      }
    });
    this._route.fragment.subscribe(v => {
      if(typeof v === 'string' && v.startsWith('access_token')){
        const fragmentObj = new URLSearchParams(v);
        const yandexPayload = {
          access_token: fragmentObj.get('access_token'),
          token_type: fragmentObj.get('token_type'),
          expires_in: fragmentObj.get('expires_in'),
          cid: fragmentObj.get('cid'),
        }
        this.loginRegular(yandexPayload)
      }
    })
    //@ts-ignore
    window.YaAuthSuggest.init(
      {
        client_id: "3a367f4cdfba4731beafb8107f8c55ac",
        response_type: "token",
        redirect_uri: "https://my.mpsurf.ru/auth/login"
      },
      "https://my.mpsurf.ru",
      { 
        view: "button",
        parentId: "buttonContainerId",
        buttonSize: 'm',
        buttonView: 'additional',
        buttonTheme: 'light',
        buttonBorderRadius: "12",
        buttonIcon: 'ya',
      }
    )
    .then(({handler}) => handler())
    .then(data => console.log('Сообщение с токеном', data))
    .catch(error => console.log('Обработка ошибки', error))
  }

  logIn() {
    const wbtoken = localStorage.getItem('target');

    if (wbtoken && wbtoken === 'wb-integration') {
      this.loginLkWb();//NEW
    } else {
      this.loginRegular();//OLD
    }
  }

  private loadUserData(): void {
    this.isLoading = true;
    this._api
      .userRegister('getUserData', localStorage.getItem('token'), '')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (data: IUserData) => {
          if (+data.is_error === 0) {
            this._router.navigate(['/supplier-selection']);
            localStorage.setItem('profileName', JSON.stringify(data.data.name));
            localStorage.setItem(
              'profilePhone',
              JSON.stringify(data.data.phone)
            );
            localStorage.setItem(
              'profilePhoneNumber',
              JSON.stringify(data.data.phonenumber)
            );
            localStorage.setItem(
              'profileEmail',
              JSON.stringify(data.data.email)
            );
            localStorage.setItem(
              'profileDateSubscribe',
              JSON.stringify(data.data.subscribe)
            );
            localStorage.setItem(
              'updateProfile',
              JSON.stringify(Math.trunc(Date.now() / 1000))
            );
          } else {
            this.showPopUpMessage(
              'error',
              PopUpMessages.loadFailedSummary,
              PopUpMessages.loadFailedMessages
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  openRegisterBot(): void {
    this._mpSurfService
      .createOrUpdate(this.RegisterForm.getRawValue(), 'users')
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.closeModal();
          window.open('https://t.me/mpsurfreg_bot', '_blank');
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }

  createOAuthConnection(): void {
    this._mpSurfService
      .loadByOld({}, 'urlWbIntegration', 'data')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error?: number; msg: string; data?: string }) => {
          if (data?.is_error) {
            this.showPopUpMessage('error', 'Ошибка', data.msg);
          } else {
            //delete from storage [target]: wb-integration
            localStorage.removeItem('target');

            this.router.navigate(['settings', 'shop']).then(() => location.href = data.msg);
            
            // location.href = data.msg;
          }
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            'Ошибка',
            'Ошибка при попытке создать интеграцию. Обратитесь к тех поддержке'
          );
        },
      });
  }

  loginRegular(payload = {}): void {
    this.isLoading = true;
    let body = this.LoginForm.getRawValue();
    body.email = body.email.trim();
    body.password = body.password.trim();
    body = {...body, ...payload};
    this._api
      .userRegister('login', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: ILoginResponse) => {
          if (+response.is_error === 1) {
            this.showPopUpMessage(
              'error',
              'Ошибка входа',
              'Неверно введен логин или пароль'
            );
            this.errorMessage = response.msg;
            this.isLoading = false;
          }
          if (+response.is_error === 0) {
            localStorage.setItem('token', response.token);
            this._authService.setLoggedIn(true);

            this.loadUserData();
          
            response.is_admin
              ? localStorage.setItem('is_admin', '1')
              : localStorage.setItem('is_admin', '0');
            const isTestStatus = window.location.host.includes('localhost')
              ? 1
              : response.is_test;
            localStorage.setItem('is_test', JSON.stringify(isTestStatus));

            //@ts-ignore
            window.YaSendSuggestToken(
              'https://my.mpsurf.ru',
              {
                 success_ya: true
              }
           )
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            'Ошибка входа',
            'Проверьте введенные данные и повторите попытку!'
          );
          this.isLoading = false;
        }
      );
  }

  loginLkWb(): void {

    this.isLoading = true;
    const body = this.LoginForm.getRawValue();
    body.email = body.email.trim();
    body.password = body.password.trim();

    this._api
      .userRegister('login', localStorage.getItem('token'), body)
      .pipe(
        untilDestroyed(this),
        switchMap(response => {
          localStorage.setItem('token', response.token);
          return this._api.userRegister('getDataShopWB', response.token, '')
        })
      )
      .subscribe(

        {
          next: (list: ISupplierResponse[]) => {
            localStorage.setItem('supplier', JSON.stringify(list));
            this.isLoading = false;
            this.createOAuthConnection();
          },
          error: () => this.isLoading = false,
          complete: () => this.isLoading = false,
        }

    )
  }

  togglePassword(): void {
    this.isShown = !this.isShown
  }

  openQRDialog(content: TemplateRef<any>): void {
    this._modalService.open(content, {
      centered: true,
    });

    const dialogRef = this._document.querySelector('.modal-dialog');

    if (dialogRef) {
      this._renderer.setStyle(dialogRef, 'box-shadow', 'none');
      this._renderer.setStyle(dialogRef, 'max-width', '720px');
    }
  }
}
