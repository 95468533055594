import { Component, OnInit, TemplateRef } from '@angular/core';
import { Clipboard  } from '@angular/cdk/clipboard';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColDef } from 'ag-grid-community';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { ICardWidget } from 'app/menu-tabs/dashboard/main-test/main-test.interface';
import { MessageService, ConfirmationService } from 'primeng/api';
import { forkJoin, switchMap } from 'rxjs';
import { MpCommonResponse } from 'app/shared/interfaces/common.type';
import { IMpMetaData } from 'app/shared/interfaces/mp-common.interface';

interface IConditionsTree {
  value: string,
  children?: IConditionsTree[]
}

interface IReferalRowData {
  "date_pay": string,
  "cost": number,
  "credited": number,
  "period": number,
  "today_credited": string,
  "date_last_credited": string,
  "remains_credited": string
}

interface ICardReferalData {
  "referral_promo": string,
  "referral_offer": string,
  "bonuses_earned": number,
  "bonuses_used": number,
  "user_count": number,
  "available_withdrawal": number
}

interface IReferalData {
  data: IReferalRowData[],
  cards: ICardReferalData
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class PartnersComponent extends AbstractMenuTabComponent implements OnInit {
  conditions: IConditionsTree[] = [
    {
      value: 'Мы выдаем вам партнерский промокод',
    },
    {
      value: 'Вы делитесь им со своей аудиторией',
    },
    {
      value: 'После применения промокода, каждому пришедшему от вас предоставляется дополнительная скидка 10% на любой первый заказ в сервисе',
    },
    {
      value: 'С момента первого пополнения, вы будете получать 10% со всех оплат от приведенных вами клиентов',
    },
    {
      value: 'Партнерское вознаграждение считается и начисляется на бонусный баланс посуточно для каждой транзакции. Подробная статистика по всем начислениям доступна в режиме реального времени после генерации промокода',
    },
    {
      value: 'Заработанные бонусы можно вывести на любой счёт или использовать для оплаты подписки сервиса до 100%',
    },
    {
      value: 'Вывод средств доступен раз в месяц, при условии:',
      children: [
        { value: 'Сумма к выводу более 5 000 руб.' },
        { value: 'Было не менее 5 оплат от разных рефералов партнера.' },
      ]
    },
  ]

  isConsentChecked = false;
  referalData: IReferalData | null = null;
  cards: ICardWidget[] = [];
  defaultDef = {
    flex: 1,
    minWidth: 20,
    maxWidth: 400,
    resizable: true,
    suppressSizeToFit: true,
    suppressResize: true,
    sortable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };

  columnDefs: ColDef[] = [
    {
      field: 'date_pay',
      headerName: 'Дата оплаты',
      headerClass: 'header-centered',
      filter: 'agDateColumnFilter'
    },
    {
      field: 'cost',
      headerName: 'Сумма покупки',
      headerClass: 'header-centered',
      filter: 'agNumberColumnFilter'
    },
    {
      field: 'credited',
      headerName: 'Всего к начислению',
      headerClass: 'header-centered',
      filter: 'agNumberColumnFilter'
    },
    {
      field: 'period',
      headerName: 'Период тарифного плана',
      headerClass: 'header-centered',
      filter: 'agNumberColumnFilter'
    },
    {
      field: 'today_credited',
      headerName: 'Начислено на сегодня',
      headerClass: 'header-centered',
      filter: 'agNumberColumnFilter'
    },
    {
      field: 'date_last_credited',
      headerName: 'Дата последнего начисления',
      headerClass: 'header-centered',
      filter: 'agDateColumnFilter'
    },
    {
      field: 'remains_credited',
      headerName: 'Осталось к начислению',
      headerClass: 'header-centered',
      filter: 'agNumberColumnFilter'
    },
  ]
  rowData: IReferalRowData[] = [];
  isPromokodUsed = false;

  constructor(private clipboard: Clipboard){
    super()
  }

  ngOnInit(): void {
    this.isPromokodUsed$.pipe(untilDestroyed(this)).subscribe(isUsed => {
      this.isPromokodUsed = isUsed;
      if(this.isPromokodUsed){
        this._mpSurfService.load({}, 'referral/list').pipe(untilDestroyed(this))
        .subscribe((data: IReferalData )=> this._handleReferralData(data))
      }
    })
  }

  openModal(template: TemplateRef<any>): void {
    this._modalService.open(template, {centered: true})
  }

  onConsentChange(event: {checked: boolean}): void {
    this.isConsentChecked = event.checked;
  }

  copyPromokod(): void {
    this.clipboard.copy(this.referalData.cards.referral_promo);
    this.showPopUpMessage(
      'success',
      'Промокод скопирован',
      ''
    );
  }

  requestReferalData(): void {
    this._modalService.dismissAll()
    this._mpSurfService.loadByOld({}, 'signatureOffer', 'data')
    .pipe(
      untilDestroyed(this),
      switchMap(() => forkJoin([this._mpSurfService.load({}, 'referral/list'), this._mpSurfService.loadMetaData(this.SHOP_ID, this.TOKEN)]))
    )
    .subscribe(([referalData, metadata]: [IReferalData, MpCommonResponse<IMpMetaData>]) => {
      this.isPromokodUsed = !!metadata.data.partners;
      this._handleReferralData(referalData);
      this._cdr.markForCheck();
    })
  }

  private _handleReferralData(data: IReferalData): void {
    this.referalData = data;
      this.rowData = data.data;
      this.cards = [
        {
          "title": "Привлечено пользователей",
          "subfields": [],
          "totalCount": null,
          "totalUnits": data.cards.user_count,
          "diffInPercents": null,
          "description": null
        },
        {
          "title": "Израсходовано бонусов",
          "subfields": [],
          "totalCount": null,
          "totalUnits": data.cards.bonuses_used,
          "diffInPercents": null,
          "description": null
        },
        {
          "title": "Заработано всего",
          "subfields": [],
          "totalCount": null,
          "totalSum": data.cards.bonuses_earned,
          "diffInPercents": null,
          "description": null
        },
        {
          "title": "Доступно к выводу",
          "subfields": [],
          "totalCount": null,
          "totalSum": data.cards.available_withdrawal,
          "diffInPercents": null,
          "description": null
        }
      ];
      this._cdr.markForCheck()
  }
}
