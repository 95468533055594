import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IPlanFactRowData } from '../plan-fact.interface';
import { PlanFactComponent } from '../plan-fact.component';

@Component({
  selector: 'app-plan-fact-controls-renderer',
  template: `
    <div style="display: flex; align-items: center">
      <button
        (click)="onEditClick()"
        class="icon-button"
        style="border: none; background: transparent; margin-right: 15px;">
        <i class="lnr-pencil"></i>
      </button>
      <button
        (click)="onCloneClick()"
        class="icon-button"
        style="border: none; background: transparent; margin-right: 15px;">
        <i class="fa fa-clone"></i>
      </button>
      <button
        (click)="onDeleteClick()"
        class="icon-button"
        style="border: none; background: transparent;">
        <i class="lnr-trash"></i>
      </button>
    </div>
  `,
})
export class PlanFactControlsRendererComponent
  implements ICellRendererAngularComp
{
  id: number | null = null;

  start = ''

  componentParent: PlanFactComponent;

  agInit(params: ICellRendererParams<IPlanFactRowData>): void {
    this.componentParent = params.context.componentParent;
    this.start = params.data.start_date;
    this.id = params.data.id;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onRowClick(): void {
    this.componentParent.onRowClick(this.id, this.start);
  }

  onEditClick(): void {
    this.componentParent.onEditClick(this.id);
  }

  onCloneClick(): void {
    this.componentParent.onCloneClick(this.id);
  }

  onDeleteClick(): void {
    this.componentParent.onDeleteClick(this.id);
  }
}