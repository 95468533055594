<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext"
/>
<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="upper-filters">
      <section style="display: flex; align-items: center; gap: 15px">
        <div class="page-title-icon">
          <i class="lnr-cart icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          РНП (Воронка по товарам)
          <i
            class="fa fa-question-circle"
            aria-hidden="true"
            [pTooltip]="'Воронка по основным метрикам. Строится на основе реализации и оперативных данных. Отчет по реализации доступен до ' + lastReportDate">
          </i>
        </div>

        <app-mp-video-play
          [videoLink]="getVideoLinkByPage('dashboard/rnp')" />
      </section>
      <section class="rnp__filters">
        <app-mp-ngb-range-datepicker
          [rangeType]="presets"
          [initialData]="selectedDate"
          (selectedDateEmitter)="dateChanged($event)">
        </app-mp-ngb-range-datepicker>

        <p-multiSelect
            (onChange)="loadPanels()"
            (onClear)="clearArticles()"
            [(ngModel)]="selectedNm"
            [maxSelectedLabels]="1"
            [options]="articles"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '300px' }"
            defaultLabel="Артикул"
            emptyMessage="Артикулов не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <p-multiSelect
            (onChange)="loadPanels()"
            (onClear)="clearSubjects()"
            [(ngModel)]="selectedSubject"
            [maxSelectedLabels]="1"
            [options]="subjectsList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '300px' }"
            defaultLabel="Предмет"
            emptyMessage="Предметов не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <p-multiSelect
            (onChange)="loadPanels()"
            (onClear)="clearCategories()"
            [(ngModel)]="selectedCategories"
            [maxSelectedLabels]="1"
            [options]="categoriesList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '300px' }"
            defaultLabel="Категория собственная"
            emptyMessage="Категорий не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>

          <p-selectButton
            (onChange)="onDateTypeChange()"
            [(ngModel)]="selectedDateType"
            [options]="[
              { name: 'По дням', value: 1 },
              { name: 'По неделям', value: 2 },
              { name: 'По месяцам', value: 3 },
            ]"
            optionLabel="name"
            optionValue="value"
            selectionMode="single"
            styleClass="dark-panel"
            [ngStyle]="{ fontSize: '14px' }"
            >
          </p-selectButton>
      </section>
    </div>
  </div>
</div>

<section class="rnp__panels">
  <mat-paginator (page)="onPage($event)" [length]="paginationLength" [pageIndex]="selectedPage-1" [pageSize]="1" [hidePageSize]="true" aria-label="Select page">
  </mat-paginator>
  <mat-accordion multi displayMode="flat">

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Воронка продаж </mat-panel-title>
      </mat-expansion-panel-header>
      <ag-grid-angular
        *ngIf="!loading; else loadingTpl"
        [suppressFieldDotNotation]="true"
        [columnDefs]="columnDefs2"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [rowData]="rowData2"
        [domLayout]="'autoHeight'"
        [rowHeight]="30"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        headerHeight="28"
        style="width: 100%;"
      />
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="onOpened()">
      <mat-expansion-panel-header>
        <mat-panel-title> Общая аналитика </mat-panel-title>
      </mat-expansion-panel-header>
      <ag-grid-angular
        *ngIf="!loading; else loadingTpl"
        [suppressFieldDotNotation]="true"
        [columnDefs]="columnDefs1"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [rowData]="rowData1"
        [domLayout]="'autoHeight'"
        [rowHeight]="30"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        headerHeight="28"
        style="width: 100%;"
      />
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Рекламная статистика </mat-panel-title>
      </mat-expansion-panel-header>
      <h5>В разработке</h5>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Оборачиваемость и остатки </mat-panel-title>
      </mat-expansion-panel-header>
      <ag-grid-angular
        *ngIf="!loading; else loadingTpl"
        [suppressFieldDotNotation]="true"
        [columnDefs]="columnDefs4"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [rowData]="rowData4"
        [domLayout]="'autoHeight'"
        [rowHeight]="30"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        headerHeight="28"
        style="width: 100%;"
      />
    </mat-expansion-panel>
  </mat-accordion> 
</section>

<ng-template #loadingTpl>
  <mat-spinner [diameter]="30"></mat-spinner>
</ng-template>