import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/shared/services/api.service';
import { metrikaConfig } from '../../../../shared/metrika/metrika.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISupplierResponse } from '../../../profile/users/users.interface';
import { MpCommonResponse } from '../../../../shared/interfaces/common.type';
import { IMpMetaData } from '../../../../shared/interfaces/mp-common.interface';
import { MpSurfApiService } from '../../../../shared/services/mpsurf-api.service';
import { LOCAL_STORAGE_KEYS } from '../../../../shared/contants/localStorage';
import { MessageService } from 'primeng/api';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-supplier-selection',
  templateUrl: './supplier-selection.component.html',
  styleUrls: ['./supplier-selection.component.scss'],
  providers:[ MessageService]
})
export class SupplierSelectionComponent implements OnInit {
  private readonly _router = inject(Router);

  private readonly _api = inject(ApiService);

  private readonly _mpSurfService = inject(MpSurfApiService);

  protected readonly _messageService = inject(MessageService);

  suppliersList: ISupplierResponse[] = [];

  ngOnInit(): void {
    this.loadSuppliers();
  }

  private loadSuppliers(): void {
    this._api
      .userRegister('getDataShopWB', localStorage.getItem('token'), '')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: ISupplierResponse[]) => {
          if (!data || data.length === 0) {
            this._router.navigate(['/settings/shop/']);
          }
          if (data.length === 1) {
            this.suppliersList = data;
            this.navigateBySelectedSupplier(data[0]);
          }

          this.suppliersList = data;
        },
        err => {
          console.log(err);
        }
      );
  }

  navigateBySelectedSupplier(item: ISupplierResponse) {
    if (item) {
      metrikaConfig.params.WBSellerParams = item;
      localStorage.setItem('supplier', JSON.stringify(this.suppliersList));
      localStorage.setItem(
        'mpall_shop',
        JSON.stringify({
          shop: item.id,
          name: item.name,
          marketPlace: item.marketplace,
        })
      );

      const wbtoken = localStorage.getItem('target')

      if(wbtoken && wbtoken === 'wb-integration') {
        this.createOAuthConnection();
      }

      this.loadMetaData(item.id);
    }
  }

  private loadMetaData(shop_id: number): void {
    this._mpSurfService
      .loadMetaData(shop_id, localStorage.getItem('token') || '')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: MpCommonResponse<IMpMetaData>) => {
          if (response.is_error === 0) {
            this._router.navigate(['/dashboard/rates']);

            localStorage.setItem(
              LOCAL_STORAGE_KEYS.METADATA_VIDEO_LINKS,
              JSON.stringify(response.data.tutorialVideoLink)
            );

            localStorage.setItem(
              LOCAL_STORAGE_KEYS.META_DATA_LAST_UPDATE_TIME,
              new Date().getTime().toString()
            );
          }
        },
      });
  }

  createOAuthConnection(): void {
    this._mpSurfService.loadByOld({}, 'urlWbIntegration', 'data').pipe(untilDestroyed(this)).subscribe({
      next: (data: { is_error?:  number, msg: string, data?: string }) => {
        if(data?.is_error) {
          this._messageService.add({

          })
          this._messageService.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.msg
          })
        }
        else {
          //delete from storage [target]: wb-integration
          localStorage.removeItem('target')
          location.href = data.msg
        }
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: "Ошибка при попытке создать интеграцию. Обратитесь к тех поддержке"
        })
      }
    })
  }
}
