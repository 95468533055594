<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<div class="app-page-title" style="margin-bottom: 0">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
      </div>
      <div>Операции</div>
      <app-mp-video-play [videoLink]="getVideoLinkByPage('fin/operations')" />
    </div>
    <div class="page-title-actions">
      <div class="header-wrap-btns">
        <button
          class="mb-2 btn btn-success add-operation-btn"
          style="background: #4c95d2; border: none"
          type="button">
          <a
            href="../../../../assets/images/download/finoperation_template.xlsx"
            style="color: #fff">
            Скачать шаблон
          </a>
        </button>
        <p-fileUpload
          #uploadedFiles
          (onBeforeUpload)="isLoading = true"
          (onError)="onBasicUploadError($event, uploadedFiles)"
          (onUpload)="onBasicUploadAuto($event, uploadedFiles)"
          [auto]="true"
          [style]="{ height: '35px' }"
          [maxFileSize]="10000000"
          accept=".xls, .xlsx, .csv"
          chooseLabel="Загрузить из Excel"
          class="button-add-purchase mr-4 inline-block"
          label="Import"
          mode="basic"
          name="file"
          url="{{ API_NEW }}fin-operation/load-preview?token={{
            TOKEN
          }}&shop_id={{ SHOP_ID }}" />
        <div ngbDropdown>
          <button
            class="mb-2 btn btn-success add-operation-btn"
            ngbDropdownToggle
            type="button">
            Добавить операцию
          </button>
          <div class="dropdown-menu-right-rounded" ngbDropdownMenu>
            <button
              (click)="openCreateOperationModal(receipt, 'arrival')"
              class="dropdown-item">
              <span style="color: green">Поступления</span>
            </button>
            <button
              (click)="openCreateOperationModal(expenses, 'expenses')"
              class="dropdown-item">
              <span style="color: red">Списания</span>
            </button>
            <button
              (click)="openCreateOperationModal(moving, 'moving')"
              class="dropdown-item">
              Перемещение
            </button>
            <!--                        TODO: Hide for a while-->
            <!--                        <button (click)="openCreateOperationModal(loanRepayment, 'repayment')"-->
            <!--                                class="dropdown-item"-->
            <!--                                style="color:#b90000">-->
            <!--                            Погашение кредита\займа-->
            <!--                        </button>-->
          </div>
        </div>
        <button
          [hidden]="true"
          data-target="#purchase"
          data-toggle="modal"
          id="openModalButton">
          Open Modal
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!showAgGridExcel">
  <div
    *ngIf="!showAgGridExcel && finOperationData.length === 0"
    class="text-center mbg-3">
    <span>
      Операций еще нет. Добавьте операции с помощью кнопки добавить операцию в
      правом верхнем углу
    </span>
  </div>
  <div *ngIf="finOperationsForRemoving.length > 0" class="button-wrapp">
    <p>Выбрано: {{ finOperationsForRemoving.length }}</p>
    <p-toast></p-toast>
    <button (click)="removeSelected()" class="icon-button" label="">
      <i class="lnr-trash"></i>
      Удалить
    </button>
  </div>
  <div
    *ngIf="!showAgGridExcel && finOperationData.length > 0"
    class="main-card mb-3 card">
    <ag-grid-angular
      #agGrid
      (gridReady)="onGridReady($event)"
      (rowSelected)="onRowSelected($event)"
      [columnDefs]="dataForColumnDefs"
      [defaultColDef]="defaultColDef"
      [enableRangeSelection]="true"
      [getRowStyle]="getRowStyle"
      [gridOptions]="gridOptions"
      [localeText]="LOCALE_RU_FILTERS"
      [pagination]="true"
      [paginationPageSize]="30"
      [domLayout]="'autoHeight'"
      [rowData]="finOperationData"
      [rowDragManaged]="true"
      [rowHeight]="40"
      [suppressRowTransform]="true"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      rowSelection="multiple"
      style="width: 100%" />
    <button
      (click)="exportInExcel()"
      class="mb-2 btn btn-success add-operation-btn button-export-fin"
      type="button">
      Экспорт в Excel
    </button>
  </div>
</div>
<app-finoperations-excel
  [ngStyle]="{ display: showAgGridExcel ? 'block' : 'none' }" />

<!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ ПОСТУПЛЕНИЯ -->
<ng-template #receipt>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="
      (isCreate ? 'Добавление' : 'Изменение') + ' операции поступления'
    " />
  <form
    [formGroup]="FormArrival"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Дата оплаты <br />
          (для ДДС)
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="position-relative row form-group" style="margin-left: 0">
            <input
              #d1="ngbDatepicker"
              readonly
              (click)="d1.toggle()"
              [(ngModel)]="selectedDatePayment"
              class="form-control"
              formControlName="date_payment"
              name="dp1"
              ngbDatepicker
              placeholder="dd.mm.yyyy"
              style="width: 150px" />
          </div>
        </div>
      </div>
      <span style="font-weight: 700;" *ngIf="isWarningVisible">
        Операция раньше даты создания счёта, сумма не попадет в ДДС и остаток по счету.
      </span>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="date_accrual_l">
          Дата начисления <br />
          (для ОПиУ)
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 20px">
          <div class="position-relative row form-group" style="margin-left: 0">
            <input
              #d2="ngbDatepicker"
              readonly
              (click)="d2.toggle()"
              [(ngModel)]="selectedDateAccrual"
              class="form-control"
              formControlName="date_accrual"
              name="dp2"
              ngbDatepicker
              placeholder="dd.mm.yyyy"
              style="width: 150px" />
          </div>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label">
          Счет
          <span style="color: red">*</span>
        </label>
        <div style="width: 323px">
          <ng-select
            [dropdownPosition]="'bottom'"
            [items]="finAccountsList"
            [searchable]="true"
            [virtualScroll]="true"
            bindLabel="name"
            bindValue="UID"
            [style]="{ 'font-size': '14px' }"
            formControlName="account_id"
            notFoundText="Ничего не найдено"
            placeholder="Cчета">
          </ng-select>
        </div>
        <button
          (click)="openNewAccountModal()"
          [pTooltip]="'Добавить счет'"
          class="p-button-success mr-2 button-excel button-add-supplier"
          icon="pi pi-plus"
          pButton
          style="width: 30px; height: 30px; margin-top: 2px"
          tooltipPosition="bottom"
          type="button"></button>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Сумма, руб.
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <p-inputNumber
            [maxFractionDigits]="2"
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            formControlName="amount"
            locale="ru-RU"
            [minFractionDigits]="2" />
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label">
          Статья
          <span style="color: red">*</span>
        </label>
        <div style="width: 366px">
          <ng-select
            [items]="itemsList"
            [searchable]="true"
            bindLabel="name"
            bindValue="item_id"
            [style]="{ 'font-size': '14px' }"
            formControlName="item_id"
            notFoundText="Ничего не найдено"
            placeholder="Статья">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Артикул</label>
        <div style="width: 366px">
          <ng-select
            [items]="finArticlesNmList"
            [searchable]="true"
            [style]="{ 'font-size': '14px' }"
            bindLabel="name"
            bindValue="name"
            formControlName="product_code"
            notFoundText="Ничего не найдено"
            placeholder="Артикул">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Контрагент</label>
        <div style="width: 323px">
          <ng-select
            [items]="finPartnersList"
            [searchable]="true"
            bindLabel="name"
            bindValue="UID"
            [style]="{ 'font-size': '14px' }"
            formControlName="partner_id"
            notFoundText="Ничего не найдено"
            placeholder="Категория">
          </ng-select>
        </div>
        <button
          (click)="openNewAgentModal()"
          [pTooltip]="'Добавить контрагента'"
          class="p-button-success mr-2 button-excel button-add-supplier"
          icon="pi pi-plus"
          pButton
          style="width: 30px; height: 30px; margin-top: 2px"
          tooltipPosition="bottom"
          type="button"></button>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Кабинет</label>
        <div style="width: 366px">
          <ng-select
            [items]="cabinets"
            [searchable]="true"
            bindLabel="shop_name"
            bindValue="id"
            [style]="{ 'font-size': '14px' }"
            formControlName="operation_shop_id"
            notFoundText="Ничего не найдено"
            placeholder="Кабинет">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l"
          >Комментарий</label
        >
        <div class="col-sm-9">
          <textarea
            style="width: 342px"
            class="form-control"
            formControlName="description"
            id="description_l"
            name="description">
          </textarea>
          <span
            *ngIf="
              this.FormArrival.controls['description'].invalid &&
              this.FormArrival.controls['description'].touched
            "
            class="alert-danger"
            >Не корректно заполнено поле</span
          >
        </div>
      </div>
    </div>
    <app-footer-modal
      (submitEmitter)="createUpdateOperation('arrival')"
      [isCreate]="isCreate"
      [isDisabled]="FormArrival.invalid">
    </app-footer-modal>
  </form>
</ng-template>

<!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ СПИСАНИЯ -->
<ng-template #expenses>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="
      (isCreate ? 'Добавление' : 'Изменение') + ' операции списания'
    " />
  <form
    [formGroup]="FormExpenses"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Дата оплаты <br />
          (для ДДС) <span style="color: red">*</span></label
        >
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="position-relative row form-group ms-0">
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              readonly
              class="form-control"
              formControlName="date_payment"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY"
              style="width: 150px" />
          </div>
        </div>
      </div>
      <span style="font-weight: 700;" *ngIf="isWarningVisible">
        Операция раньше даты создания счёта, сумма не попадет в ДДС и остаток по счету.
      </span>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="date_accrual">
          Дата начисления <br />
          (для ОПиУ)
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 20px">
          <div class="position-relative row form-group ms-0">
            <input
              #d2="ngbDatepicker"
              readonly
              (click)="d2.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_accrual"
              id="date_accrual"
              name="dp2"
              ngbDatepicker
              placeholder="DD.MM.YYYY"
              style="width: 150px" />
          </div>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label">
          Счет
          <span style="color: red">*</span></label
        >
        <div style="width: 323px">
          <ng-select
            [dropdownPosition]="'bottom'"
            [items]="finAccountsList"
            [searchable]="true"
            [virtualScroll]="true"
            [style]="{ 'font-size': '14px' }"
            bindLabel="name"
            bindValue="UID"
            formControlName="account_id"
            notFoundText="Ничего не найдено"
            placeholder="Cчет">
          </ng-select>
        </div>
        <button
          (click)="openNewAccountModal()"
          [pTooltip]="'Добавить счет'"
          class="p-button-success mr-2 button-excel button-add-supplier"
          icon="pi pi-plus"
          pButton
          style="width: 30px; height: 30px; margin-top: 2px"
          tooltipPosition="bottom"
          type="button"></button>
      </div>
      <div class="position-relative row form-group d-flex">
        <label class="col-sm-3 col-form-label">
          Сумма, руб.
          <span style="color: red">*</span>
        </label>
        <div style="width: 366px">
          <p-inputNumber
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            formControlName="amount"
            mode="decimal"
            locale="ru-RU"
            [minFractionDigits]="2">
          </p-inputNumber>

          <span
            *ngIf="isCreate && displaySeparation && !displaySeparateSum"
            (click)="addSeparateSumOperation()"
            class="separate__sum_title"
            >Разделить сумму</span
          >
          <span
            *ngIf="isCreate && displaySeparation && displaySeparateSum"
            (click)="removeSeparateSum()"
            class="separate__sum_title"
            >Удалить разделение</span
          >
        </div>
      </div>
      <div
        *ngIf="displaySeparation && displaySeparateSum"
        class="position-relative row form-group d-flex">
        <label class="col-sm-3 col-form-label">
          Кол-во месяцев для распределения в ОПиУ
          <span style="color: red">*</span>
        </label>
        <div style="width: 366px; margin-top: 25px">
          <p-inputNumber
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            [useGrouping]="false"
            formControlName="separate_payment">
          </p-inputNumber>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label">
          Статья
          <span style="color: red">*</span>
        </label>
        <div style="width: 366px">
          <ng-select
            [items]="itemsList"
            [searchable]="true"
            bindLabel="name"
            [style]="{ 'font-size': '14px' }"
            bindValue="item_id"
            formControlName="item_id"
            notFoundText="Ничего не найдено"
            placeholder="Категория">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Артикул</label>
        <div style="width: 366px">
          <ng-select
            [items]="finArticlesNmList"
            [placeholder]="'Артикул'"
            [searchable]="true"
            bindLabel="name"
            [style]="{ 'font-size': '14px' }"
            bindValue="name"
            formControlName="product_code"
            notFoundText="Ничего не найдено">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group d-flex">
        <label class="col-sm-3 col-form-label"> Контрагент</label>
        <div style="width: 323px">
          <ng-select
            [items]="finPartnersList"
            [searchable]="true"
            bindLabel="name"
            [style]="{ 'font-size': '14px' }"
            bindValue="UID"
            formControlName="partner_id"
            notFoundText="Ничего не найдено"
            placeholder="Категория">
          </ng-select>
        </div>
        <button
          (click)="openNewAgentModal()"
          [pTooltip]="'Добавить контрагента'"
          class="p-button-success mr-2 button-excel button-add-supplier"
          icon="pi pi-plus"
          pButton
          pRipple
          style="width: 30px; height: 30px; margin-top: 2px"
          tooltipPosition="bottom"
          type="button"></button>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Кабинет</label>
        <div style="width: 366px">
          <ng-select
            [items]="cabinets"
            [searchable]="true"
            bindLabel="shop_name"
            bindValue="id"
            [style]="{ 'font-size': '14px' }"
            formControlName="operation_shop_id"
            notFoundText="Ничего не найдено"
            placeholder="Кабинет">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l-1"
          >Комментарий</label
        >
        <div class="col-sm-9">
          <textarea
            style="width: 342px"
            class="form-control"
            formControlName="description"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <app-footer-modal
      (submitEmitter)="createUpdateOperation('expenses')"
      [isCreate]="isCreate"
      [isDisabled]="FormExpenses.invalid" />
  </form>
</ng-template>

<!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ УДЕРЖАНИЯ -->
<ng-template #retention>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="
      (isCreate ? 'Добавление' : 'Изменение') + ' операции удержание WB'
    " />
  <form
    [formGroup]="FormRetention"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="date_accrual_l">
          Дата начисления <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="position-relative row form-group" style="margin-left: 0">
            <input
              #d2="ngbDatepicker"
              readonly
              (click)="d2.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_accrual"
              id="date_accrual_l"
              name="dp2"
              ngbDatepicker
              placeholder="DD.MM.YYYY"
              style="width: 150px" />
            <!--              TODO Approved by Natasha-->
            <!--              <div class="" style="width: 200px; display: flex; gap: 11px">-->
            <!--                <input type="checkbox" name="checkbox" formControlName="insure_arrival_income"-->
            <!--                       id="insure_arrival_income">-->
            <!--                <label for="insure_arrival_income" style="width: 100%" class="col-sm-3 col-form-label">Подтвердить-->
            <!--                  начисление</label>-->
            <!--              </div>-->
          </div>
        </div>
      </div>
      <!--            <div class="position-relative row form-group" style="display: flex">-->
      <!--                <label class="col-sm-3 col-form-label" for="account_lable">Счет<span-->
      <!--                        style="color:red">*</span></label>-->
      <!--                <div style="width: 366px;">-->
      <!--                    <ng-select [dropdownPosition]="'bottom'"-->
      <!--                               [items]="finAccountsList"-->
      <!--                               [searchable]="true"-->
      <!--                               [virtualScroll]="true"-->
      <!--                               bindLabel="name"-->
      <!--                               bindValue="UID"-->
      <!--                               formControlName="account_id"-->
      <!--                               id="account_lable" notFoundText="Ничего не найдено"-->
      <!--                               placeholder="Cчета">-->
      <!--                    </ng-select>-->
      <!--                </div>-->
      <!--            </div>-->
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label"
          >Сумма, руб.<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <p-inputNumber
            [maxFractionDigits]="2"
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            formControlName="amount"
            mode="decimal"
            locale="ru-RU"
            [minFractionDigits]="2">
          </p-inputNumber>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label">
          Статья
          <span style="color: red">*</span>
        </label>
        <div style="width: 366px">
          <ng-select
            [items]="itemsList"
            [searchable]="true"
            bindLabel="name"
            [style]="{ 'font-size': '14px' }"
            bindValue="item_id"
            formControlName="item_id"
            notFoundText="Ничего не найдено"
            placeholder="Категория">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Артикул</label>
        <div style="width: 366px">
          <ng-select
            [items]="finArticlesNmList"
            [placeholder]="'Артикул'"
            [searchable]="true"
            bindLabel="name"
            [style]="{ 'font-size': '14px' }"
            bindValue="name"
            formControlName="product_code"
            notFoundText="Ничего не найдено">
          </ng-select>
        </div>
        <!--            <button type="button" pButton pRipple icon="pi pi-plus"-->
        <!--                    style="width: 30px; height: 30px; margin-top: 2px;"-->
        <!--                    class="p-button-success mr-2 button-excel button-add-supplier"-->
        <!--                    [pTooltip]="'Добавить артикул'"-->
        <!--                    tooltipPosition="bottom" (click)="showSelectDropDown = !showSelectDropDown">-->
        <!--            </button>-->
        <!--          <ng-container *ngIf="!showSelectDropDown">-->
        <!--            <div style="width: 323px;">-->
        <!--              <input name="amount" id="project" placeholder="" type="text"-->
        <!--                     formControlName="product_code" class="form-control">-->
        <!--            </div>-->
        <!--            <button type="button" pButton pRipple icon="pi pi-arrow-circle-left"-->
        <!--                    style="width: 30px; height: 30px; margin-top: 2px;"-->
        <!--                    class="p-button-success mr-2 button-excel button-add-supplier"-->
        <!--                    [pTooltip]="'Добавить артикул'"-->
        <!--                    tooltipPosition="bottom" (click)="showSelectDropDown = !showSelectDropDown">-->
        <!--            </button>-->
        <!--          </ng-container>-->
      </div>
      <!--                <div class="position-relative row form-group">-->
      <!--                    <label class="col-sm-3 col-form-label">-->
      <!--                        Контрагент</label>-->
      <!--                    <div style="width: 323px;">-->
      <!--                        <ng-select [items]="finPartnersList" [searchable]="true" bindLabel="name"-->
      <!--                                   bindValue="UID"-->
      <!--                                   formControlName="partner_id" notFoundText="Ничего не найдено"-->
      <!--                                   placeholder="Категория">-->
      <!--                        </ng-select>-->
      <!--                    </div>-->
      <!--                    <button (click)="openNewAgentModal()" [pTooltip]="'Добавить контрагента'"-->
      <!--                            class="p-button-success mr-2 button-excel button-add-supplier" icon="pi pi-plus"-->
      <!--                            pButton-->
      <!--                            pRipple-->
      <!--                            style="width: 30px; height: 30px; margin-top: 2px;"-->
      <!--                            tooltipPosition="bottom" type="button">-->
      <!--                    </button>-->
      <!--                </div>-->
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l-1"
          >Комментарий</label
        >
        <div class="col-sm-9">
          <textarea
            class="form-control"
            style="width: 342px"
            formControlName="description"
            id="description_l-1"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <app-footer-modal
      (submitEmitter)="createUpdateOperation('retention')"
      [isCreate]="isCreate"
      [isDisabled]="FormRetention.invalid" />
    <p-toast />
  </form>
</ng-template>

<!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ ПЕРЕМЕЩЕНИЯ -->
<ng-template #moving>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="
      (isCreate ? 'Добавление' : 'Изменение') + ' операции перемещения'
    " />
  <form
    [formGroup]="FormMoving"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Дата перемещения<span style="color: red">*</span></label
        >
        <div class="col-sm-5 mt-10" style="margin-top: 10px">
          <input
            #d1="ngbDatepicker"
            readonly
            (click)="d1.toggle()"
            [(ngModel)]="selectedDatePayment"
            class="form-control"
            formControlName="date_payment"
            name="dp1"
            ngbDatepicker
            placeholder="dd.mm.yyyy" />
          <!--<div class="alert alert-danger" *ngIf="this.FormArrival.controls['date_payment'].invalid && this.FormArrival.controls['date_payment'].touched">Не корректно заполнено поле</div>-->
          <span
            *ngIf="
              this.FormMoving.controls['date_payment'].invalid &&
              this.FormMoving.controls['date_payment'].touched
            "
            class="alert-danger"
            >Не верно заполненон поле</span
          >
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label"
          >Сумма<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <p-inputNumber
            [maxFractionDigits]="2"
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            formControlName="amount"
            mode="decimal"
            locale="ru-RU"
            [minFractionDigits]="2">
          </p-inputNumber>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label"
          >Счет откуда<span style="color: red">*</span></label
        >
        <div style="width: 366px">
          <ng-select
            (click)="isAccountItemBusy('account_id_2')"
            [dropdownPosition]="'bottom'"
            [items]="finAccountsList"
            [style]="{ 'font-size': '14px' }"
            [searchable]="true"
            [virtualScroll]="true"
            bindLabel="name"
            bindValue="UID"
            formControlName="account_id"
            notFoundText="Ничего не найдено"
            placeholder="Cчета">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label"
          >Счет куда<span style="color: red">*</span></label
        >
        <div style="width: 366px">
          <ng-select
            (click)="isAccountItemBusy('account_id')"
            [dropdownPosition]="'bottom'"
            [items]="finAccountsList"
            [searchable]="true"
            [style]="{ 'font-size': '14px' }"
            [virtualScroll]="true"
            bindLabel="name"
            bindValue="UID"
            formControlName="account_id_2"
            notFoundText="Ничего не найдено"
            placeholder="Cчета">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description1"
          >Комментарий</label
        >
        <div class="col-sm-9">
          <textarea
            style="width: 342px"
            class="form-control"
            formControlName="description"
            id="description1"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <p-toast />
    <app-footer-modal
      (submitEmitter)="createUpdateOperation('moving')"
      [isCreate]="isCreate"
      [isDisabled]="FormMoving.invalid">
    </app-footer-modal>
  </form>
</ng-template>

<!--  ФОРМА ДЛЯ СОЗДАНИЯ КРЕДИТ\ЗАЙМА -->
<ng-template #loanRepayment>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="'Добавление операции погашения кредита займа'">
  </app-header-modal>
  <form
    [formGroup]="FormLoanRepayment"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label"
          >Дата оплаты<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <div class="position-relative row form-group" style="margin-left: 0">
            <input
              #d1="ngbDatepicker"
              readonly
              (click)="d1.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_payment"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY"
              style="width: 150px" />
          </div>
          <span
            *ngIf="
              this.FormRetention.controls['date_payment'].invalid &&
              this.FormRetention.controls['date_payment'].touched
            "
            class="alert-danger"
            >Не верно заполненон поле</span
          >
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="date_accrual_l">
          Дата начисления
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <div class="position-relative row form-group" style="margin-left: 0">
            <input
              #d2="ngbDatepicker"
              readonly
              (click)="d2.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_accrual"
              name="dp2"
              ngbDatepicker
              placeholder="DD.MM.YYYY"
              style="width: 150px" />
          </div>
          <span
            *ngIf="
              this.FormRetention.controls['date_accrual'].invalid &&
              this.FormRetention.controls['date_accrual'].touched
            "
            class="alert-danger"
            >Не корректно заполнено поле</span
          >
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Счет
          <span style="color: red">*</span>
        </label>
        <div style="width: 366px">
          <ng-select
            [dropdownPosition]="'bottom'"
            [items]="finAccountsDebitList"
            [searchable]="true"
            [virtualScroll]="true"
            [style]="{ 'font-size': '14px' }"
            bindLabel="name"
            bindValue="UID"
            formControlName="account_id"
            notFoundText="Ничего не найдено"
            placeholder="Cчета">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group" style="display: flex">
        <label class="col-sm-3 col-form-label" for="credit_loan">
          Кредит\займ<span style="color: red">*</span>
        </label>
        <div style="width: 366px">
          <ng-select
            [dropdownPosition]="'bottom'"
            [items]="finAccountsCreditList"
            [searchable]="true"
            [virtualScroll]="true"
            [style]="{ 'font-size': '14px' }"
            bindLabel="name"
            bindValue="UID"
            formControlName="credit_loan"
            id="credit_loan"
            notFoundText="Ничего не найдено"
            placeholder="Кредиты\Займы">
          </ng-select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Тело долга, сумма руб.
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <p-inputNumber
            [maxFractionDigits]="2"
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            formControlName="debt_body"
            mode="decimal"
            locale="ru-RU"
            [minFractionDigits]="2">
          </p-inputNumber>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Проценты, сумма руб.
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <p-inputNumber
            [maxFractionDigits]="2"
            [maxlength]="15"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            formControlName="percentages"
            mode="decimal"
            locale="ru-RU"
            [minFractionDigits]="2">
          </p-inputNumber>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Сумма платежа, руб.<span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <input
            [disabled]="true"
            [style]="{ width: '343px', height: '37px', fontSize: '12px' }"
            class="p-inputtext p-component p-element p-inputnumber-input p-filled"
            formControlName="amount_of_payment"
            type="number" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l-1">
          Комментарий
        </label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <app-footer-modal
      (submitEmitter)="createUpdateOperation('repayment')"
      [isCreate]="isCreate"
      [isDisabled]="FormLoanRepayment.invalid">
    </app-footer-modal>
  </form>
</ng-template>

<!--  ФОРМА ДЛЯ ДОБАВЛЕНИ НОВОГО АГЕНТА -->
<ng-template #newAgent>
  <app-header-modal
    (closeEmitter)="closeSecondModal()"
    [modalTitle]="'Добавить контрагента'">
  </app-header-modal>
  <form
    [formGroup]="FormNewAgent"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="shop_lable">Юр. лицо</label>
        <div class="col-sm-9">
          <select
            class="form-control"
            formControlName="shop"
            id="shop_lable"
            name="shop">
            <option *ngFor="let shop of shopsList" [ngValue]="shop.id">
              {{ shop.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label"
          >Наименование<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label"
            name="name"
            placeholder="Введите наименование"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="inn_label">ИНН</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="inn"
            id="inn_label"
            name="inn"
            placeholder="ИНН"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="kpp"
            id="kpp_lable"
            name="kpp"
            placeholder="КПП"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="checking_account_lable"
          >Р/С</label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="checking_account"
            id="checking_account_lable"
            name="checking_account"
            placeholder="Расчетный счет"
            type="text" />
        </div>
      </div>
      <!-- TODO: hide for a while -->
      <!--                <div class="position-relative row form-group">-->
      <!--                    <label class="col-sm-3 col-form-label" for="checking_account_lable">Доходная статья</label>-->
      <!--                    <div class="col-sm-9" style="margin-top: 4px;">-->
      <!--                        <p-dropdown [filter]="true" [options]="itemsIncomeList"-->
      <!--                                    [showClear]="true"-->
      <!--                                    [style]="{ display: 'flex', alignItems: 'center', height: '34px'}"-->
      <!--                                    formControlName="income_item"-->
      <!--                                    optionLabel="name" optionValue="id"-->
      <!--                                    placeholder="Нераспределенный доход"></p-dropdown>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div class="position-relative row form-group">-->
      <!--                    <label class="col-sm-3 col-form-label" for="checking_account_lable">Расходная статья</label>-->
      <!--                    <div class="col-sm-9" style="margin-top: 4px;">-->
      <!--                        <p-dropdown [filter]="true" [options]="itemsExpensesList"-->
      <!--                                    [showClear]="true"-->
      <!--                                    [style]="{ display: 'flex', alignItems: 'center', height: '34px'}"-->
      <!--                                    formControlName="expenses_item"-->
      <!--                                    optionLabel="name" optionValue="id"-->
      <!--                                    placeholder="Нераспределенный расход"></p-dropdown>-->
      <!--                    </div>-->
      <!--                </div>-->
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description"
          >Описание</label
        >
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            id="description"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <app-footer-modal
      (submitEmitter)="createNewAgent()"
      [isCreate]="isCreate"
      [isDisabled]="FormNewAgent.invalid" />
  </form>
  <p-toast />
</ng-template>

<!--  ФОРМА ДЛЯ ДОБАВЛЕНИ НОВОГО СЧЕТА -->
<ng-template #newAccount>
  <app-header-modal
    (closeEmitter)="closeSecondModal()"
    [modalTitle]="'Добавить счета'" />
  <form
    [formGroup]="FormNewAccount"
    autocomplete="off"
    class="needs-validation"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Название
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label1"
            name="name"
            placeholder="Введите название"
            type="text" />
        </div>
      </div>

      <div *ngIf="creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Доступные средства
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input
            class="form-control"
            formControlName="credit_limit"
            name="name"
            placeholder="Введите сумму доступных средств"
            type="number" />
        </div>
      </div>
      <div *ngIf="creditLimitSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Кредитный лимит
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input
            class="form-control"
            formControlName="credit_limit"
            name="name"
            placeholder="Введите кредитный лимит"
            type="text" />
        </div>
      </div>

      <div *ngIf="creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="balance_label">
          Основной долг
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <input
              class="form-control"
              formControlName="balance_init"
              name="balance_init"
              placeholder="Основной долг"
              type="text" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_balance_init"
              id="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
        </div>
      </div>

      <div *ngIf="!creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Начальный остаток
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <p-inputNumber
              [maxlength]="15"
              [style]="{ width: '200', height: '37px', fontSize: '12px' }"
              formControlName="balance_init"
              mode="decimal"
              locale="ru-RU"
              [minFractionDigits]="2" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              readonly
              class="form-control"
              formControlName="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l">
          Описание
        </label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            name="description">
          </textarea>
        </div>
      </div>
    </div>

    <app-footer-modal
      (submitEmitter)="createNewAccount()"
      [isCreate]="isCreate"
      [isDisabled]="FormNewAccount.invalid" />
  </form>
  <p-toast />
</ng-template>
<p-toast />
