import { DecimalPipe } from "@angular/common";
import { ApexOptions } from "ng-apexcharts";
import { IFullTableItem, IGeneralInfoTableItem } from "./plan-fact.interface";

const decimalPipe = new DecimalPipe('ru-RU')

export function getChartOptions(data): Partial<ApexOptions>{
    return {
        series: [
          {
            name: 'Заказы (шт)',
            type: 'column',
            data: data.map((elem) => elem.ordersCount || 0),
            color: '#1A74E8',
          },
          {
            name: 'Заказы (руб)',
            type: 'line',
            data: data.map((elem) => elem.ordersTotal || 0),
            color: 'rgba(9, 60, 143, 1)',
          },
          {
            name: 'Продажи (шт)',
            type: 'column',
            data: data.map((elem) => elem.salesCount || 0),
            color: '#74db90',
          },
          {
            name: 'Продажи (руб)',
            type: 'line',
            data: data.map((elem) => elem.salesTotal || 0),
            color: 'rgba(10, 105, 26, 1)',
          },
          {
            name: 'Возвраты (шт)',
            type: 'column',
            data: data.map((elem) => elem.returnsCount || 0),
            color: '#e8b0c6',
          },
          {
            name: 'Возвраты (руб)',
            type: 'line',
            data: data.map((elem) => elem.returnsTotal || 0),
            color: 'rgba(186, 20, 20, 1)',
          },
          {
            name: 'Отмены (шт)',
            type: 'column',
            data: data.map((elem) => elem.cancelsCount || 0),
            color: '#feb019',
          },
          {
            name: 'Отмены (руб)',
            type: 'line',
            data: data.map((elem) => elem.cancelsTotal || 0),
            color: '#c99928',
          },
        ],
        chart: {
          height: 360,
          type: 'area',
          stacked: false,
          animations: {
            enabled: true,
          },
        },
        dataLabels: { enabled: false },
        //@ts-ignore
        stroke: { curve: 'smooth', width: [2, 3, 2, 3, 2, 3, 2, 3] },
        legend: { horizontalAlign: 'left', offsetX: 40 },
        xaxis: {
          type: 'category',
          categories: data.map((elem) => elem.date),
        },
        yaxis: [
          {
            seriesName: 'Заказы (шт)',
            opposite: true,
            labels: {},
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: 'Штуки',
              style: {
                color: '#000000',
              },
            },
            min: 0,
          },
          {
            seriesName: 'Заказы (руб)',
            labels: {
              formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: 'Рубли',
              style: {
                color: '#000000',
              },
            },
            min: 0,
          },
          {
            seriesName: 'Заказы (шт)',
            opposite: true,
            show: false,
          },
          {
            seriesName: 'Заказы (руб)',
            show: false,
            labels: {
              formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
            },
          },
          {
            seriesName: 'Заказы (шт)',
            opposite: true,
            show: false,
          },
          {
            seriesName: 'Заказы (руб)',
            show: false,
            labels: {
              formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
            },
          },
          {
            seriesName: 'Заказы (шт)',
            opposite: true,
            show: false,
          },
          {
            seriesName: 'Заказы (руб)',
            show: false,
            labels: {
              formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
            },
          },
        ],
    };
}

export function mapToRows(data: IGeneralInfoTableItem){
    const firstRow = {
      name: 'Сумма в рублях в день',
      plan: Object.entries(data).filter(([key, value]) => key === 'plan').map(([key, value]) => value.sum_day)[0], 
      fact: Object.entries(data).filter(([key, value]) => key === 'fact').map(([key, value]) => value.sum_day)[0], 
      delta1: Object.entries(data).filter(([key, value]) => key === 'delta_rub').map(([key, value]) => value.sum_day)[0], 
      delta2: Object.entries(data).filter(([key, value]) => key === 'delta_percent').map(([key, value]) => value.sum_day)[0], 
    }
    const secondRow = {
      name: 'Cумма в рублях на сегодня',
      plan: Object.entries(data).filter(([key, value]) => key === 'plan').map(([key, value]) => value.sum_today)[0], 
      fact: Object.entries(data).filter(([key, value]) => key === 'fact').map(([key, value]) => value.sum_today)[0], 
      delta1: Object.entries(data).filter(([key, value]) => key === 'delta_rub').map(([key, value]) => value.sum_today)[0], 
      delta2: Object.entries(data).filter(([key, value]) => key === 'delta_percent').map(([key, value]) => value.sum_today)[0], 
    }
    const thirdRow = {
      name: 'Сумма в рублях',
      plan: Object.entries(data).filter(([key, value]) => key === 'plan').map(([key, value]) => value.sum_rub)[0], 
      fact: Object.entries(data).filter(([key, value]) => key === 'fact').map(([key, value]) => value.sum_rub)[0], 
      delta1: Object.entries(data).filter(([key, value]) => key === 'delta_rub').map(([key, value]) => value.sum_rub)[0], 
      delta2: Object.entries(data).filter(([key, value]) => key === 'delta_percent').map(([key, value]) => value.sum_rub)[0], 
    }

    return [
      firstRow,
      secondRow,
      thirdRow
    ]
}

export function getDateKeys(data: IFullTableItem[]): string[] {
  
  const rowData = data[0];

  const keys = Object.entries(rowData).filter(([key, value]) => key.match(/^\d/)).map(([key, value]) => key)

  return keys
}
