import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractMenuTabComponent } from '../../../menu-tabs/abstract-menu-tab.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { THIRD_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import {
  IFullTableItem,
  IGeneralInfo,
  IGeneralInfoTableItem,
  IPlanFactArticle,
  IPlanFactRowData,
} from './plan-fact.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PLAN_FACT_COLUMN_DEFS } from './plan-fact.model';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICardWidget } from '../main-test/main-test.interface';
import { ApexOptions } from 'ng-apexcharts';
import { getChartOptions, getDateKeys, mapToRows } from './plan-fact.func';
import { groups } from './plan-fact.mock';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { of, timer } from 'rxjs';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-plan-fact',
  templateUrl: './plan-fact.component.html',
  styleUrls: ['./plan-fact.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class PlanFactComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  public dataSource = new MatTableDataSource<any>([]);

  columns: any[];
  displayedColumns: string[];
  displayedColumns2: string[];
  groupByColumns: string[] = [];
  allData: any[];
  _allGroup: any[];

  expandedCar: any[] = [];
  expandedSubCar: any[] = [];

  @ViewChild(MatSort) sort: MatSort;

  readonly presets = THIRD_RANGE_TYPE;

  plan_facts: IPlanFactRowData[] = [];

  plan_fact: IPlanFactRowData & { articles: IPlanFactArticle[] } = null;

  generalInfoMetadata?: Partial<IGeneralInfo>;

  generalInfoTable?: IGeneralInfoTableItem;

  mappedData = [];

  chartOptions: Partial<ApexOptions> | null = null;

  goal = null;

  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };

  columnDefs = PLAN_FACT_COLUMN_DEFS;

  protected gridOptionsPlanFact: GridOptions | null = null;

  createPlanFormGroup?: FormGroup;
  updatePlanFormGroup?: FormGroup;

  selectedMethodWhenCreate = 1;
  selectedMethodWhenUpdate = 1;

  methods = [
    { code: 1, name: 'По кабинету' },
    { code: 2, name: 'По артикулу' },
  ];

  types = [
    { code: 1, name: 'По сумме заказов' },
    { code: 2, name: 'По сумме продаж' },
  ];

  cards: ICardWidget[] = [];

  articlesFromBackend: IPlanFactArticle[] = [];

  articlesFromPlan: IPlanFactArticle[] = [];

  editMode = false;

  fullTableRows = [];

  dateKeys = [];

  selectedDateType = 1;

  planId = 0;

  start = '';

  dateColumns = [];

  filteredColumns = [];

  @ViewChild('createFormTpl') createFormTpl: TemplateRef<unknown>;
  @ViewChild('updateFormTpl') updateFormTpl: TemplateRef<unknown>;

  constructor(private readonly _fb: FormBuilder) {
    super();
    this.gridOptionsPlanFact = {
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
    this.columns = [
      {
        field: 'type',
      },
      {
        field: 'total',
      },
      {
        field: '01/09/2024',
      },
      {
        field: '02/09/2024',
      },
      {
        field: '03/09/2024',
      },
      {
        field: '04/09/2024',
      },
      {
        field: '05/09/2024',
      },
      {
        field: '06/09/2024',
      },
      {
        field: '07/09/2024',
      },
      {
        field: '08/09/2024',
      },
      {
        field: '09/09/2024',
      },
      {
        field: '10/09/2024',
      },
      {
        field: '11/09/2024',
      },
      {
        field: '12/09/2024',
      },
      {
        field: '13/09/2024',
      },
      {
        field: '14/09/2024',
      },
      {
        field: '15/09/2024',
      },
      {
        field: '16/09/2024',
      },
      {
        field: '17/09/2024',
      },
      {
        field: '18/09/2024',
      },
    ];
    this.displayedColumns2 = this.columns.map(column => column.field === 'type' ? 'typeHeader' : column.field);
    this.displayedColumns = this.columns.map(column => column.field);
  }

  ngOnInit(): void {
    this.loadPlanFacts();
    this.createPlanFormGroup = this._fb.group({
      plan_name: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      start_date: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      days: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      goal_rub: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: new FormControl(1, { nonNullable: true }),
      method: new FormControl(1, { nonNullable: true }),
      articles: new FormArray([]),
    });

    this.updatePlanFormGroup = this._fb.group({
      plan_name: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      start_date: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      days: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      goal_rub: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: new FormControl(1, { nonNullable: true }),
      method: new FormControl(1, { nonNullable: true }),
      articles: new FormArray([]),
    });

    this.subscribeOnMethodWhenCreate();
    this.subscribeOnArticlesWhenCreate();

    this.subscribeOnMethodWhenUpdate();
    this.subscribeOnArticlesWhenUpdate();

    of(groups).subscribe(
      (data: any) => {
        this.dataSource.data = data;
        this.dateColumns = [
          'groupHeader',
          'total',
          ...getDateKeys(data as unknown as IFullTableItem[]),
        ];
        this.filteredColumns = getDateKeys(data);
      },
      (err: any) => console.log(err)
    );
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  onRowClick(id: number, start: string): void {
    this.planId = id;
    this.start = start;
    this.loadCards(id);
    this.loadGeneralInfo(id);
    this.loadChart(id);
    this.loadFullTable(id, start);
  }

  onGoalInputWhenCreate(event: {
    originalEvent: KeyboardEvent;
    value: number;
  }): void {
    this.updatePlanFormGroup.controls['goal_rub'].setValue(event.value);
  }

  onGoalInputWhenUpdate(event: {
    originalEvent: KeyboardEvent;
    value: number;
  }): void {
    this.updatePlanFormGroup.controls['goal_rub'].setValue(event.value);
  }

  onEditClick(id: number): void {
    this.editMode = true;
    this.loadPlanFactById(id);
  }

  onCloneClick(id: number): void {
    this.loadPlanFactById(id, true);
  }

  onDeleteClick(id: number): void {
    this.deletePlanRequest(id);
  }

  onDateTypeChange(): void {
    this.loadFullTable(this.planId, this.start);
  }

  openCreatePlanModal(formTpl: TemplateRef<unknown>): void {
    this.articlesFromBackend = [];
    this.createPlanFormGroup.reset();
    this._modalService.open(formTpl, { centered: true });
  }

  submitCreate(): void {
    const body = this.createPlanFormGroup.getRawValue();

    if (this.selectedMethodWhenCreate === 2) {
      body['articles'] = this.articlesFromBackend.map((a, i) => ({
        name: a.name,
        plan_sum: (
          this.createPlanFormGroup.controls['articles'] as FormArray
        ).at(i).value.plan_sum,
        remove_from_plan: (
          this.createPlanFormGroup.controls['articles'] as FormArray
        ).at(i).value.remove_from_plan
          ? 1
          : 0,
      }));
    } else {
      delete body.articles;
    }
    if (typeof body.start_date === 'object') {
      const { day, month, year } = body.start_date as {
        day: number;
        month: number;
        year: number;
      };
      body.start_date = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
    this.createPlanRequest(body);
  }

  submitUpdate(): void {
    const body = this.updatePlanFormGroup.getRawValue();

    if (this.selectedMethodWhenUpdate === 2) {
      body['articles'] = this.articlesFromPlan.map((a, i) => ({
        name: a.name,
        plan_sum: (
          this.updatePlanFormGroup.controls['articles'] as FormArray
        ).at(i).value.plan_sum,
        remove_from_plan: (
          this.updatePlanFormGroup.controls['articles'] as FormArray
        ).at(i).value.remove_from_plan
          ? 1
          : 0,
      }));
    } else {
      delete body.articles;
    }
    if (typeof body.start_date === 'object') {
      const { day, month, year } = body.start_date as {
        day: number;
        month: number;
        year: number;
      };
      body.start_date = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
    this.updatePlanRequest(body);
  }

  private loadPlanFacts(): void {
    this._mpSurfService
      .load({}, 'plan-fact')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (
          data: IPlanFactRowData[] | { is_error: number; msg: string }
        ) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.plan_facts = data as IPlanFactRowData[];
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadPlanFactById(id: number, cloning = false): void {
    this._mpSurfService
      .load({}, `plan-fact/${id}`)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IPlanFactRowData | { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.planId = data['id'];
            this.plan_fact = data as IPlanFactRowData & {
              articles: IPlanFactArticle[];
            };
            if (!cloning) {
              this.populateForm(this.plan_fact);
              this._modalService.open(this.updateFormTpl, { centered: true });
            } else {
              this.createPlanRequest(this.plan_fact);
            }
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private subscribeOnMethodWhenCreate(): void {
    this.createPlanFormGroup.controls['method'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedMethodWhenCreate = value;
        if (this.selectedMethodWhenCreate === 2) {
          this.loadArticlesForForm();
        }
      });
  }

  private subscribeOnMethodWhenUpdate(): void {
    this.updatePlanFormGroup.controls['method'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedMethodWhenUpdate = value;
        if (this.selectedMethodWhenUpdate === 2) {
          this.articlesFromPlan = this.plan_fact.articles;
        }
      });
  }

  private subscribeOnArticlesWhenCreate(): void {
    this.createPlanFormGroup.controls['articles'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(
        (values: { remove_from_plan: boolean; plan_sum: number }[]) => {
          const agg = values
            .filter(value => !value.remove_from_plan)
            .reduce((acc, curr) => acc + curr.plan_sum, 0);
          this.createPlanFormGroup.controls['goal_rub'].setValue(agg);
        }
      );
  }

  private subscribeOnArticlesWhenUpdate(): void {
    this.updatePlanFormGroup.controls['articles'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(
        (values: { remove_from_plan: boolean; plan_sum: number }[]) => {
          const agg = values
            .filter(value => !value.remove_from_plan)
            .reduce((acc, curr) => acc + curr.plan_sum, 0);
          this.updatePlanFormGroup.controls['goal_rub'].setValue(agg);
        }
      );
  }

  private loadArticlesForForm(): void {
    this._mpSurfService
      .load({}, 'plan-fact/articles')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (
          data: IPlanFactArticle[] | { is_error: number; msg: string }
        ) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.articlesFromBackend = data as IPlanFactArticle[];

            if (this.articlesFromBackend.length) {
              this.articlesFromBackend.map((o, i) => {
                (this.createPlanFormGroup.controls.articles as FormArray).push(
                  new FormGroup({
                    remove_from_plan: new FormControl(false, {
                      nonNullable: true,
                    }),
                    plan_sum: new FormControl(0, { nonNullable: true }),
                  })
                );
              });
            }
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadCards(id: number): void {
    this._mpSurfService
      .load({ id }, 'plan-fact/cards')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: ICardWidget[] | { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.cards = data as ICardWidget[];
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadGeneralInfo(id: number): void {
    this._mpSurfService
      .load({ id }, 'plan-fact/general-info')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IGeneralInfo | { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.generalInfoMetadata = data as IGeneralInfo;
            this.generalInfoTable = (data as IGeneralInfo).table;
            this.mappedData = mapToRows(this.generalInfoTable);
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadChart(id: number): void {
    this._mpSurfService
      .load({ id }, 'plan-fact/chart')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (
          data: Partial<ApexOptions> | { is_error: number; msg: string }
        ) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.chartOptions = getChartOptions(data as Partial<ApexOptions>);
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private createPlanRequest(
    body: IPlanFactRowData & { articles: IPlanFactArticle[] }
  ): void {
    this._mpSurfService
      .loadByPost(body, 'plan-fact')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this._modalService.dismissAll();
            this.showPopUpMessage('success', '', data['msg']);
            this.loadPlanFacts();
          }
        },
        error: err => {},
      });
  }

  private updatePlanRequest(
    body: IPlanFactRowData & { articles: IPlanFactArticle[] }
  ): void {
    body['id'] = this.planId;
    this._mpSurfService
      .loadByPut(body, 'plan-fact')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this._modalService.dismissAll();
            this.showPopUpMessage('success', '', data['msg']);
            this.loadPlanFacts();
          }
        },
        error: err => {},
      });
  }

  private deletePlanRequest(id: number): void {
    this._mpSurfService
      .delete({}, 'plan-fact/' + id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
            this.loadPlanFacts();
          } else {
            this._modalService.dismissAll();
            this.showPopUpMessage('success', '', data['msg']);
            this.loadPlanFacts();
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadFullTable(id: number, start: string): void {
    const params = { id, date_type: this.selectedDateType };

    if (this.selectedDateType === 1) {
      params['month'] = +start;
    }

    this.fullTableRows = [];
    this._mpSurfService
      .load(params, 'plan-fact/full-table')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IFullTableItem[]) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.fullTableRows = data;
            this.dateKeys = getDateKeys(data);
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private populateForm(
    data: IPlanFactRowData & { articles: IPlanFactArticle[] }
  ): void {
    const [year, month, day] = data.start_date.split(' ')[0].split('-');

    this.updatePlanFormGroup.controls['plan_name'].setValue(data.plan_name);
    this.updatePlanFormGroup.controls['start_date'].setValue({
      day: +day,
      month: +month,
      year: +year,
    });
    this.updatePlanFormGroup.controls['days'].setValue(data.days);
    this.updatePlanFormGroup.controls['goal_rub'].setValue(data.goal_rub);
    this.updatePlanFormGroup.controls['type'].setValue(data.type);
    this.updatePlanFormGroup.controls['method'].setValue(data.method);
    if (this.plan_fact?.articles?.length) {
      this.plan_fact.articles.map((o, i) => {
        (this.updatePlanFormGroup.controls.articles as FormArray).push(
          new FormGroup({
            remove_from_plan: new FormControl(false, { nonNullable: true }),
            plan_sum: new FormControl(0, { nonNullable: true }),
          })
        );
      });
      this.plan_fact.articles.forEach((_el, i) => {
        (this.updatePlanFormGroup.controls.articles as FormArray)
          .at(i)
          .setValue({
            remove_from_plan: !!_el.remove_from_plan,
            plan_sum: _el.plan_sum,
          });
      });
    }
  }

  groupHeaderClick(row) {
    if (row.expanded) {
      row.expanded = false;
      const agg = [];
      groups.forEach(g =>
        g.expanded ? agg.push(g, ...g.articles) : agg.push(g)
      );
      this.dataSource.data = agg;
    } else {
      row.expanded = true;
      const agg = [];
      groups.forEach(g =>
        g.expanded ? agg.push(g, ...g.articles) : agg.push(g)
      );
      this.dataSource.data = agg;
    }
    timer(0).subscribe(() => {
      this._cdr.detectChanges()
    })
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  isObject(entry: string | number | object): boolean {
    return typeof entry === 'object'
  }
}
