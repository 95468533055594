import {
  Component,
  LOCALE_ID,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import {
  faAngleDown,
  faAngleUp,
  faPlus,
  faWindowClose,
  faBolt,
} from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TAXES_TYPES } from './shop.mock';
import {
  IDataShopWBList,
  IDataShopWbReloaded,
  IResponseCommon,
  IShopStatic,
  ITitleValue,
  ShopReports,
} from './shop.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { ActivatedRoute, GuardsCheckStart, Router } from '@angular/router';
import { filter, Observable, of, switchMap, tap } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'ru-RU' },
  ],
})
export class ShopComponent extends AbstractMenuTabComponent implements OnInit {
  readonly faAngleDown = faAngleDown;
  readonly faAngleUp = faAngleUp;
  readonly faPlus = faPlus;
  readonly faBolt = faBolt;
  readonly faWindowClose = faWindowClose;
  readonly formGroup = new FormGroup({
    shop_name: new FormControl('', Validators.required),
    shop_key: new FormControl('', Validators.required),
  }); //CHECK
  form = new FormGroup({
    checkArray: new FormArray([], [Validators.required]),
    phone_number: new FormControl('', [Validators.required]),
  });
  readonly typesOfTaxes: ITitleValue[] = TAXES_TYPES;
  shop: IDataShopWBList[] = [];
  shop_list: IDataShopWbReloaded[] = [];
  wild: any;
  supplier: any;
  WBTokenAdv: any;
  WBTokenPortal: any;
  KeyApiStat: any;
  KeyApiNew: any;
  KeyApiNewer: any;
  KeyApiAdv: any;
  tgid: any;
  val6: string;
  hide_full_access_wb = false;
  error_phone: string | boolean;
  exists_phone: string;
  modal_title_get_phone: string;
  modal_title_update_token: string;
  modal_shop_id: any;
  exists_phone_color: string;
  error_phone_rr: any;
  tax_type: any;
  tax_percent: number;
  view_google_table_info = true;
  video_link = null;
  hasBrokenToken = !!localStorage.getItem('hasBrokenToken');

  //OZON
  ClientID: string = null;
  KeyID: string = null;
  ClientSecret: string = null;

  // ADD USER
  selectedSellService = null;
  name_connect = '';

  activeShopId: number; //CHECK

  products!: any[];
  vvv = [
    {
      id: '1000',
      code: 'f230fh0g3',
      name: 'Bamboo Watch',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 65,
      category: 'Accessories',
      quantity: 24,
      inventoryStatus: 'INSTOCK',
      rating: 5,
    },
  ];

  text = '';
  subtext = '';

  modalData: ShopReports[] = [];

  isOzon = false;

  isOauthLoading = true;

  @ViewChild('updateTokenTmpl') modalContentUpdateToken: TemplateRef<any>;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.view_google_table_info = localStorage.getItem('googleMsgInfo') == null;
  }

  ngOnInit() {
    if (localStorage.getItem('mpall_shop')) {
      this.isOzon =
        JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    }

    this.getData();
    this.getDataShop();
    this.video_link = this.getVideoLinkByPage('settings/shop');
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(
          item => item.pageTitle === 'settings/shop'
        ) || { die_text: null, die_title: null };
        this.text = die_title;
        this.subtext = die_text;
      });

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      if (params['code'] && params['state']) {
        this._mpSurfService
          .loadByOld(
            { code: params['code'], state: params['state'] },
            'getWbIntegrationCode',
            'data'
          )
          .subscribe({
            next: (data: {
              is_error?: number;
              msg: string;
              data?: string;
              user: { shop: number; name: string; marketPlace: number };
            }) => {
              if (data?.is_error) {
                this.showPopUpMessage('error', 'Ошибка', data.msg);
              } else {
                localStorage.setItem(
                  'mpall_shop',
                  JSON.stringify({
                    shop: data.user.shop,
                    name: data.user.name,
                    marketPlace: data.user.marketPlace,
                  })
                );
                localStorage.removeItem('hasBrokenToken')
                window.location.reload();
                this.router
                  .navigate(['settings', 'shop'])
                  .then(() => {
                    if(localStorage.getItem('redirectWB')){
                      localStorage.removeItem('redirectWB')
                    } else {
                      location.href = data.msg
                    }
                  });
              }
            },
            error: () => {
              this.showPopUpMessage(
                'error',
                'Ошибка',
                'Ошибка при попытке создать интеграцию. Обратитесь к тех поддержке'
              );
            },
          });
      }
      if (params['target'] && params['target'] === 'wb-integration') {
        //save to storage [target]: wb-integration - From WB site

        localStorage.setItem('target', 'wb-integration');

        //case 1 authorized: true => call method createOAuthConnection

        this.createOAuthConnection();
      } else {
        this.isOauthLoading = false
      }
    });


    this.router.events.pipe(filter(e => e instanceof GuardsCheckStart)).subscribe(
      (e) => {
        if(!!localStorage.getItem('hasBrokenToken')){
          this.showPopUpMessage('error', 'Внимание!', 'В некоторых кабинетах обнаружены проблемы с подключением. Для восстановления интеграции нажмите кнопку "Переподключить". Если возникнут вопросы, обратитесь в службу поддержки.')
        }
      }
    )
  }

  closeGoogleTableMsg(param) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.view_google_table_info = false;
    }
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (+item.value === +e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    console.log(this.form.value);
  }

  view_full_access_wb() {
    this.hide_full_access_wb = true;
  }

  openLarge(content, id) {
    this.activeShopId = id;
    this.modal_title_get_phone = 'Добавление кабинета';
    this._modalService.open(content);
  }

  disabledSeller(id) {
    if (confirm('Вы уверены, что хотите отключить кабинет от системы?')) {
      const body = { shop_id: this.SHOP_ID, id };
      this.api
        .userRegister('disabledSeller', localStorage.getItem('token'), body)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (data: IResponseCommon) => {
            if (data.is_error) {
              this.showPopUpMessage(
                'error',
                PopUpMessages.updateFailedSummary,
                PopUpMessages.updateFailedMessage
              );
            } else {
              this.getData();
              this.showPopUpMessage(
                'success',
                PopUpMessages.updateSuccessSummary,
                data.msg
              );
              localStorage.removeItem('mpall_shop');
              this.reloadWBlist();
            }
          },
          error: () => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedMessage
            );
          }
        });
    }
  }

  createConnection(id: number) {
    if (this.name_connect?.length) {
      const body = {
        shop_id: this.SHOP_ID,
        id,
        name: this.name_connect,
        marketplace: this.selectedSellService,
      };
      this.api
        .userRegister('addSeller', localStorage.getItem('token'), body)
        .pipe(untilDestroyed(this))
        .subscribe({
          next:(data: IResponseCommon) =>{
            this._modalService.dismissAll();
            if(data.is_error){
              this.showPopUpMessage(
                'error',
                PopUpMessages.createFailedSummary,
                data.msg
              );
            } else {
              this.getData();
              this.reloadWBlist();
              this.showPopUpMessage(
                'success',
                PopUpMessages.createSuccessSummary,
                data.msg
              );
            }
          },
          error:()=>{
            this.showPopUpMessage(
              'error',
              PopUpMessages.createFailedSummary,
              PopUpMessages.createFailedMessage
            );
          }
        });
    } else {
      this.error_phone = true;
      this.exists_phone = 'Ошибка заполнения';
      this.exists_phone_color = 'warning';
    }
  }

  updateToken(editType, id) {
    this.modal_title_update_token = '';
    this.modal_shop_id = id;
    this.error_phone = '';
    switch (editType) {
      case 'name':
        this.modal_title_update_token = 'Название подключения';
        break;
      case 'taxes':
        const currentShopAccount = this.shop.filter(shop => shop.id === id)[0];
        if (currentShopAccount) {
          this.tax_percent = +currentShopAccount.tax_percent;
          this.tax_type = +currentShopAccount.tax_type;
        }
        this.modal_title_update_token = 'Налог';
        break;
      case 'wild':
        this.modal_title_update_token = 'Wild token';
        break;
      case 'supplier':
        this.modal_title_update_token = 'Supplier-id';
        break;
      case 'WBTokenAdv':
        this.modal_title_update_token = 'WBToken для рекламных кампаний';
        break;
      case 'WBTokenPortal':
        this.modal_title_update_token = 'WBToken для внутренней аналитики';
        break;
      case 'KeyApiStat':
        this.modal_title_update_token = 'Ключ API Статистика';
        break;
      case 'KeyApiNew':
        this.modal_title_update_token = 'Ключ API Стандартный';
        break;
      case 'KeyApiNewer':
        this.modal_title_update_token = 'Ключ API Новый';
        this.KeyApiNewer = null;
        break;
      case 'KeyApiAdv':
        this.modal_title_update_token = 'Ключ API Реклама';
        break;
      case 'tgid':
        this.modal_title_update_token = 'Telegram ID';
        break;

      case 'KeyApiOzon':
        this.modal_title_update_token = 'Ключ API Ozon';
        this.ClientID = null;
        this.KeyID = null;
        break;

      case 'KeyAdvApiOzon':
        this.modal_title_update_token = 'Ключ Рекламный API Ozon';
        this.ClientID = null;
        this.ClientSecret = null;
        break;
    }
    this._modalService.open(this.modalContentUpdateToken);
  }

  updateTokenNew(editType: string, id: number) {
    this.error_phone = null;
    let value;
    let phone;
    switch (editType) {
      case 'Название подключения':
        value = this.name_connect;
        break;
      case 'Wild token':
        value = this.wild;
        phone = this.val6;
        break;
      case 'Налог':
        value = { tax_percent: this.tax_percent, tax_type: this.tax_type };
        break;
      case 'Supplier-id':
        value = this.supplier;
        break;
      case 'WBToken для рекламных кампаний':
        value = this.WBTokenAdv;
        break;
      case 'WBToken для внутренней аналитики':
        value = this.WBTokenPortal;
        break;
      case 'Ключ API Статистика':
        value = this.KeyApiStat;
        break;
      case 'Ключ API Стандартный':
        value = this.KeyApiNew;
        break;
      case 'Ключ API Новый':
        value = this.KeyApiNewer;
        break;
      case 'Ключ API Реклама':
        value = this.KeyApiAdv;
        break;
      case 'Telegram ID':
        value = this.tgid;
        break;
      case 'OzonKeys':
        value = { oz_api_key: this.KeyID, oz_client_id: this.ClientID };
        break;
      case 'OzonAdvKeys':
        value = {
          oz_adv_api_key: this.ClientID,
          oz_adv_client_secret: this.ClientSecret,
        };
        break;
    }
    const body = { id, type_edit: editType, value, phone };
    this.api
      .userRegister('updateDataShop', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IResponseCommon) => {
          if (!data.is_error) {
            this._modalService.dismissAll();
            this.name_connect = '';
            this.wild = '';
            this.supplier = '';
            this.WBTokenAdv = '';
            this.tax_type = '';
            this.tax_percent = 0;
            this.WBTokenPortal = '';
            this.KeyApiStat = '';
            this.KeyApiNew = '';
            this.tgid = '';
            this.getData();
          } else {
            this.error_phone = data.msg;
            this.error_phone_rr = data.msg_phone;
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.updateFailedSummary,
            PopUpMessages.updateFailedMessage
          );
        }
      );
  }

  updateTokenMpall(id) {
    if (
      confirm(
        'Вы уверены, что хотите изменить Ключ для гугл таблиц? ' +
          'После нажаният на кнопку "ДА" не зубудьте его поменять в настройках гугл таблицы'
      )
    ) {
      const body = { id };
      this.api
        .userRegister(
          'updateDataShopKeyMpall',
          localStorage.getItem('token'),
          body
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          (data: IShopStatic<IResponseCommon>) => {
            if (data.data.is_error === 0) {
              this.showPopUpMessage(
                'success',
                PopUpMessages.updateSuccessSummary,
                ''
              );
              this.getData();
            } else {
              this.showPopUpMessage(
                'error',
                PopUpMessages.updateFailedSummary,
                PopUpMessages.updateFailedMessage
              );
            }
          },
          (error: HttpErrorResponse) => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedMessage
            );
          }
        );
    }
  }

  getTypeOfTax(type: number): string {
    const taxType = this.typesOfTaxes.find(tax => tax.value === +type);
    if (!type || !taxType) {
      return 'Не установлено';
    }
    return taxType ? taxType.title : '';
  }

  getDataShop() {
    this.api
      .userRegister('getDataShopWB', localStorage.getItem('token'), '')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IDataShopWbReloaded[]) => {
          this.shop_list = data;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  getData() {
    this.api
      .userRegister('getDataShopWBList', localStorage.getItem('token'), '')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IDataShopWBList[]) => {
          if (!data) {
            this.showPopUpMessage(
              'error',
              PopUpMessages.loadFailedSummary,
              PopUpMessages.loadFailedMessages
            );
          }
          this.shop = data;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private reloadWBlist() {
    this.api
      .userRegister('getDataShopWB', localStorage.getItem('token'), '')
      .pipe(untilDestroyed(this))
      .subscribe((response: IDataShopWbReloaded[]) => {
        localStorage.setItem('supplier', JSON.stringify(response));
        if (!localStorage.getItem('mpall_shop') && response.length) {
          const shopData = response[0];
          localStorage.setItem(
            'mpall_shop',
            JSON.stringify({
              shop: shopData.id,
              name: shopData.name,
              marketPlace: shopData.marketplace,
            })
          );
        }
        window.location.reload();
      });
  }

  openLoadDataModal(content: TemplateRef<any>, apiReports: ShopReports[]) {
    if (apiReports?.length) {
      this.modalData = apiReports;
      this._modalService.open(content);
    } else {
      this.showPopUpMessage(
        'error',
        'Нет данных',
        'По данному кабинету данные не загружены'
      );
    }
  }

  getTime(date: string): string {
    return date.split(' ')[1] ?? '';
  }

  createOAuthConnectionWithButton(): void{

    localStorage.setItem('redirectWB', 'false')

    this.createOAuthConnection()
  }

  createOAuthConnection(): void {
    this._mpSurfService
      .loadByOld({}, 'urlWbIntegration', 'data')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error?: number; msg: string; data?: string }) => {
          if (data?.is_error) {
            this.showPopUpMessage('error', 'Ошибка', data.msg);
          } else {
            //delete from storage [target]: wb-integration

            const wbtoken = localStorage.getItem('target');

            if (wbtoken && wbtoken === 'wb-integration') {
              localStorage.removeItem('target');
            }

            location.href = data.msg;

            this.isOauthLoading = false;
          }
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            'Ошибка',
            'Ошибка при попытке создать интеграцию. Обратитесь к тех поддержке'
          );
        },
      });
  }

  handleReconnect(url: string): void {
    localStorage.setItem('redirectWB', 'false');
    window.location.href = url;
  }
}
