import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { ROUTES } from 'app/shared/contants/routes';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute)

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 403 &&
          ![
            ROUTES.PROFILE_TARIFF,
            ROUTES.SETTINGS_SHOP,
            ROUTES.PROFILE_BILL,
            ROUTES.PROFILE_PARTNERS
          ].includes(this._router.url)
        ) {
          this._router.navigate([ROUTES.PROFILE_TARIFF], {
            queryParams: { accessDenited: 1 },
          });
        }

        if (error.status === 401) {
          this._router.navigate(['/auth/login'], {
            queryParams: { accessDenited: 1 },
          });
        }

        return throwError(() => new Error(error.statusText));
      })
    );
  }
}
