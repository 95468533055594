<ng-container *ngIf="!isOauthLoading">
  <app-page-header-notification-bar
    *ngIf="text || subtext"
    [headerTitle]="text"
    [barText]="subtext" />

  <div *ngIf="view_google_table_info" class="col-sm-6 col-md-6">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">
        Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP
        Surf
      </h5>
      <div class="col-sm-4 col-md-4">
        <button
          (click)="closeGoogleTableMsg(1)"
          class="btn btn-primary mt-2 mr-2">
          Все понятно
        </button>
      </div>
    </div>
    <br />
  </div>

  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="icon pe-7s-users icon-gradient bg-happy-itmeo"></i>
        </div>
        <div>Кабинеты</div>

        <app-mp-video-play [videoLink]="video_link" />
      </div>
      <div style="display: flex; gap: 8px">
        <button
          (click)="openLarge(getPhone, 0)"
          class="btn-shadow d-inline-flex align-items-center btn btn-success"
          type="button">
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          Добавить кабинет
        </button>
        <button
          (click)="createOAuthConnectionWithButton()"
          class="btn-shadow d-inline-flex align-items-center btn btn-glow"
          type="button">
          <fa-icon [icon]="faBolt" class="me-2"></fa-icon>
          Быстрое подключение через ЛК WB
        </button>
      </div>
      <div *ngIf="hasBrokenToken" class="wb-warning">!На одном из кабинетов потеряно подключение</div>
    </div>
  </div>

  <div *ngIf="shop?.length != 0" class="row row-special">
    <div *ngFor="let item of shop" class="col-md-12 col-lg-6 col-xl-4">
      <div
        style="height: 100%"
        class="card-shadow-primary profile-responsive card-border mb-3 card">
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-dark">
            <div class="menu-header-content btn-pane-right">
              <div>
                <div class="d-flex items-center gap-3">
                  <img
                    [src]="
                      item.sign_ozon
                        ? 'assets/images/ozon-logo.webp'
                        : 'assets/images/wb-logo.jpg'
                    "
                    style="width: 35px; margin-bottom: 3px; border-radius: 10px"
                    alt="logo" />
                  <h5
                    *ngIf="item.name; else itemSpace"
                    class="menu-header-title">
                    {{ item.name }}
                    <fa-icon
                      style="color: yellow"
                      *ngIf="item?.['wb-integration']"
                      [icon]="faBolt"
                      class="me-2"></fa-icon>
                  </h5>
                </div>
                <h6 *ngIf="item.name_company" class="menu-header-subtitle">
                  {{ item.name_company }}
                </h6>
                <h6
                  *ngIf="item.name_company; else itemSpace"
                  class="menu-header-subtitle">
                  <br />
                </h6>
              </div>
              <div class="menu-header-btn-pane">
                <button
                  (click)="disabledSeller(item.id)"
                  [disabled]="item.readOnly == 1"
                  class="me-2 btn btn-warning btn-sm"
                  matTooltip="Кабинет будет удален из системы и все его ключи досутпа"
                  matTooltipShowDelay="0">
                  Отключить кабинет
                </button>
              </div>
            </div>
          </div>
        </div>
        <ul class="list-group list-group-flush" style="height: 100%">
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Название подключения</div>
                  <div
                    *ngIf="item.name; else enterName"
                    class="widget-subheading">
                    {{ item.name }}
                  </div>
                  <ng-template #enterName>
                    <div class="widget-subheading">
                      <div class="badge rounded-pill bg-danger">
                        введите название
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="widget-content-right me-3">
                  <button
                    (click)="updateToken('name', item.id)"
                    [disabled]="item.readOnly == 1"
                    class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm"
                    matTooltip="Для ручного добавления"
                    matTooltipShowDelay="0">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
                <div class="widget-content-right">
                  <div *ngIf="item.name; else itemNameData" class="fsize-2">
                    <span class="text-success pe-2">
                      <fa-icon [icon]="faAngleDown"></fa-icon>
                    </span>
                  </div>
                  <ng-template #itemNameData>
                    <div
                      class="fsize-2"
                      matTooltip="Не удалось получить данные. Необходимо обновить подключение"
                      matTooltipShowDelay="0">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">
                    Налог (Ставка:
                    {{
                      item.tax_percent
                        ? item.tax_percent + '%'
                        : 'Не установлено'
                    }}, {{ getTypeOfTax(+item.tax_type) }})
                  </div>
                </div>
                <div class="widget-content-right" style="margin-right: 16px">
                  <button
                    (click)="updateToken('taxes', item.id)"
                    [disabled]="item.readOnly == 1"
                    class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm"
                    matTooltip="Для ручного добавления"
                    matTooltipShowDelay="0">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
                <div class="widget-content-right">
                  <div
                    *ngIf="item?.tax_percent; else itemNameData"
                    class="fsize-2">
                    <span class="text-success pe-2">
                      <fa-icon [icon]="faAngleDown"></fa-icon>
                    </span>
                  </div>
                  <ng-template #itemNameData>
                    <div
                      class="fsize-2"
                      matTooltip="Не удалось получить данные. Необходимо обновить подключение"
                      matTooltipShowDelay="0">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </li>
          <!--КЛЮЧ API - WB-->
          <li
            *ngIf="!item.sign_ozon && !item?.['wb-integration']"
            class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Ключ API - WB</div>
                </div>
                <div class="widget-content-right">
                  <button
                    (click)="updateToken('KeyApiNewer', item.id)"
                    [disabled]="item.readOnly == 1"
                    class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_key_apinew_status" class="fsize-2">
                    <span class="text-success pe-2">
                      <fa-icon [icon]="faAngleDown"></fa-icon>
                    </span>
                  </div>
                  <div *ngIf="!item.wb_key_apinew_status" class="fsize-2">
                    <span class="text-danger pe-2">
                      <fa-icon [icon]="faWindowClose"></fa-icon>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!--КЛЮЧ API - OZON-->
          <li *ngIf="item.sign_ozon" class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Ключ API - OZON</div>
                  <div
                    *ngIf="item.wb_key_apinew_status == null"
                    class="widget-subheading"></div>
                  <div
                    *ngIf="item.wb_key_apinew_status == 0"
                    class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">
                      выполняется проверка
                    </div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button
                    (click)="updateToken('KeyApiOzon', item.id)"
                    [disabled]="item.readOnly == 1"
                    class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.oz_token_status" class="fsize-2">
                    <span class="text-success pe-2">
                      <fa-icon [icon]="faAngleDown"></fa-icon>
                    </span>
                  </div>
                  <div *ngIf="!item.oz_token_status" class="fsize-2">
                    <span class="text-danger pe-2">
                      <fa-icon [icon]="faWindowClose"></fa-icon>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="item.wb_key_apinew_status == 0"
                  class="fsize-2"
                  matTooltip="Данные загружаются. Ожидайте обновления"
                  matTooltipShowDelay="0">
                  <div class="loader">
                    <div class="ball-clip-rotate">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li *ngIf="item.sign_ozon" class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Ключ рекламный API - OZON</div>
                </div>
                <div class="widget-content-right">
                  <button
                    (click)="updateToken('KeyAdvApiOzon', item.id)"
                    [disabled]="item.readOnly == 1"
                    class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.oz_adv_token_status" class="fsize-2">
                    <span class="text-success pe-2">
                      <fa-icon [icon]="faAngleDown"></fa-icon>
                    </span>
                  </div>
                  <div *ngIf="!item.oz_adv_token_status" class="fsize-2">
                    <span class="text-danger pe-2">
                      <fa-icon [icon]="faWindowClose"></fa-icon>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="item.wb_key_apinew_status == 0"
                  class="fsize-2"
                  matTooltip="Данные загружаются. Ожидайте обновления"
                  matTooltipShowDelay="0">
                  <div class="loader">
                    <div class="ball-clip-rotate">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <ng-container *ngIf="hide_full_access_wb">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wild token
                    </div>
                    <div
                      *ngIf="item.wb_auth_token_client_site != null"
                      class="widget-subheading">
                      <br />
                    </div>
                    <div
                      *ngIf="item.wb_auth_token_client_site == null"
                      class="widget-subheading">
                      <div class="badge rounded-pill bg-danger">
                        токен не активен
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button
                      (click)="updateToken('wild', item.id)"
                      [disabled]="item.readOnly == 1"
                      class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm"
                      matTooltip="Для ручного добавления"
                      matTooltipShowDelay="0">
                      <i class="pe-7s-tools btn-icon-wrapper"> </i>
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="widget-content-right">
                    <div
                      *ngIf="item.wb_auth_token_client_site != null"
                      class="fsize-2">
                      <span class="text-success pe-2">
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_token_client_site == null"
                      class="fsize-2"
                      matTooltip="Не удалось получить данные. Необходимо обновить подключение"
                      matTooltipShowDelay="0">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Supplier-id
                    </div>
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == null"
                      class="widget-subheading">
                      <br />
                    </div>
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == 0"
                      class="widget-subheading">
                      <div class="badge rounded-pill bg-warning">
                        выполняется проверка
                      </div>
                    </div>
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == 99"
                      class="widget-subheading">
                      <div class="badge rounded-pill bg-danger">
                        Supplier-id не корректный
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button
                      (click)="updateToken('supplier', item.id)"
                      [disabled]="item.readOnly == 1"
                      class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                      <i class="pe-7s-tools btn-icon-wrapper"> </i>
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="widget-content-right">
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == 1"
                      class="fsize-2">
                      <span class="text-success pe-2">
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == null"
                      class="fsize-2">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == 99"
                      class="fsize-2">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_supplier_id_status == 0"
                      class="fsize-2"
                      matTooltip="Данные загружаются. Ожидайте обновления"
                      matTooltipShowDelay="0">
                      <div class="loader">
                        <div class="ball-clip-rotate">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WBToken
                      для рекламных кампаний
                    </div>
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == null"
                      class="widget-subheading">
                      <br />
                    </div>
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == 0"
                      class="widget-subheading">
                      <div class="badge rounded-pill bg-warning">
                        выполняется проверка
                      </div>
                    </div>
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == 99"
                      class="widget-subheading">
                      <div class="badge rounded-pill bg-danger">
                        токен не действителен
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button
                      (click)="updateToken('WBTokenAdv', item.id)"
                      [disabled]="item.readOnly == 1"
                      class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                      <i class="pe-7s-tools btn-icon-wrapper"> </i>
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="widget-content-right">
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == 1"
                      class="fsize-2">
                      <span class="text-success pe-2">
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == null"
                      class="fsize-2">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == 99"
                      class="fsize-2">
                      <span class="text-danger pe-2">
                        <fa-icon [icon]="faWindowClose"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="item.wb_auth_wbtoken_adv_status == 0"
                      class="fsize-2"
                      matTooltip="Данные загружаются. Ожидайте обновления"
                      matTooltipShowDelay="0">
                      <div class="loader">
                        <div class="ball-clip-rotate">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ng-container>


          <!--Ключ для google таблиц-->
          <li *ngIf="!item.sign_ozon" class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Ключ для google таблиц</div>
                  <div class="widget-subheading">{{ item.api_key_mpall }}</div>
                </div>
                <button
                  (click)="updateTokenMpall(item.id)"
                  [disabled]="item.readOnly == 1"
                  class="me-1 btn-icon btn-icon-only btn btn-warning btn-sm">
                  <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                </button>
              </div>
            </div>
          </li>

          <!--ЗАГРУЗКА ДАННЫХ-->
          <li class="list-group-item footer-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Загрузка данных</div>
                </div>
                <div class="widget-content-right">
                  <div class="fsize-2 flex items-center gap-2">
                    <button
                      (click)="
                        openLoadDataModal(loadDataTableModal, item.api_reports)
                      "
                      class="me-1 btn-icon btn-icon-only btn btn-warning btn-sm">
                      Посмотреть
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="item.refresh_token_url" class="list-group-item footer-item" style="background: #F6702B;">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Подключение потеряно</div>
                </div>
                <div class="widget-content-right">
                  <div class="fsize-2 flex items-center gap-2">
                      <button (click)="handleReconnect(item.refresh_token_url)" class="btn-shadow d-inline-flex align-items-center btn btn-success">
                        Переподключить
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!--Таблица загрузки данных-->
  <ng-template #loadDataTableModal>
    <p-table [value]="modalData" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>Название апи</th>
          <th>Дата обновления</th>
          <th>Последние данные</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product?.name }}</td>
          <td>
            {{ product?.update_date | date: 'dd.MM.yyyy' }}
            {{ getTime(product.update_date) }}
          </td>
          <td>
            {{ product?.last_data_date | date: 'dd.MM.yyyy' }}
            {{ getTime(product.last_data_date) }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>

  <!--Добавление Пользователя-->
  <ng-template #addUser let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Добавление пользователя</h4>
      <button
        (click)="d('Cross click')"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title"></h5>
        <form (ngSubmit)="submitForm()" [formGroup]="form" novalidate>
          <p-inputMask
            formControlName="phone_number"
            id="user_phone"
            mask="+9(999) 999-99-99"
            name="phone_number" />
          <div *ngFor="let data of shop_list; let i = index">
            <label>
              <input
                (change)="onCheckboxChange($event)"
                [value]="data.id"
                type="checkbox" />
              {{ data.name }}
            </label>
          </div>
          <input
            [disabled]="form.invalid || form.disabled"
            class="mt-1 btn btn-primary"
            type="submit"
            value="Добавить пользователя" />
        </form>
      </div>
    </div>
  </ng-template>

  <!--Добавление ЮР ЛИЦА | Поставщика-->
  <ng-template #getPhone>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="modal_title_get_phone" />
    <div class="modal-body">
      <div class="mb-3">
        <h5>1. Выберите маркетплейс:</h5>
        <p-selectButton
          [options]="[
            { label: 'Wildberries', value: 1 },
            { label: 'Ozon', value: 2 },
          ]"
          [style]="{ fontSize: '14px' }"
          [(ngModel)]="selectedSellService"
          optionLabel="label"
          optionValue="value" />
      </div>

      <div class="col-md-12">
        <h5>
          2. Введите название кабинета (как он будет отображаться в системе,
          например: "Мой кабинет"):
        </h5>
        <div class="main-card mb-12 d-flex mb-3">
          <input
            [(ngModel)]="name_connect"
            [style]="{ width: '274px' }"
            class="me-2"
            pInputText
            width="100%" />
        </div>
        <div class="connect-btn-group">
          <!-- <button
            class="oauth-btn btn"
            *ngIf="!isOzon && selectedSellService !== 2"
            (click)="createOAuthConnectionWithButton()"
            type="button">
            Быстрое подключение через ЛК WB
          </button> -->
          <button
            class="connect-btn btn"
            (click)="createConnection(activeShopId)"
            [disabled]="!name_connect || !selectedSellService"
            type="button">
            Добавить
          </button>
        </div>
        <div
          *ngIf="exists_phone_color == 'warning'"
          class="alert alert-warning">
          {{ exists_phone }}
        </div>
        <div
          *ngIf="exists_phone_color == 'success'"
          class="alert alert-success">
          {{ exists_phone }}
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #updateTokenTmpl>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="modal_title_update_token">
    </app-header-modal>

    <div class="modal-body">
      <p>
        Для изменения "{{ modal_title_update_token }}" необходимо внести новое
        значение в поле ниже
      </p>

      <div *ngIf="modal_title_update_token === 'Ключ API Новый'" class="mb-3">
        <p-panel
          header="Как узнать свой API ключ"
          class="pointer mb-3"
          [collapsed]="true"
          [toggler]="'header'"
          [toggleable]="true">
          <div style="margin-bottom: 5px; font-weight: 400; font-size: 13px">
            АPI ключ позволяет интегрировать данные между системами продавца и
            MP Surf.
            <br />
            <br />
            Для генерации API ключа, нужно сделать следущее:
            <br />
            1. Перейти по ссылке в ЛК WB
            <a
              href="https://seller.wildberries.ru/supplier...ess-to-api"
              class="inline"
              target="_blank"
              >https://seller.wildberries.ru/supplier...ess-to-api</a
            >
            <br />
            2. Нажать на кнопку "Создать новый токен" <br />
            3. Придумать и внести название ключа,
            <span style="text-decoration: underline; font-weight: 600"
              >не ставить</span
            >
            галочки <br />
            4. Выбрать
            <span style="text-decoration: underline; font-weight: 600"
              >все</span
            >
            опции (все методы API) и нажать кнопку "Создать токен" <br />
            5. Ответом за запрос будет API ключ (сразу скопируйте его) <br />
          </div>
        </p-panel>
      </div>

      <div
        *ngIf="modal_title_update_token === 'Ключ Рекламный API Ozon'"
        class="mb-3">
        <p-panel
          header="Как узнать свой рекламный API ключ"
          class="pointer mb-3"
          [collapsed]="true"
          [toggler]="'header'"
          [toggleable]="true">
          <div style="margin-bottom: 5px; font-weight: 400; font-size: 13px">
            Performance АPI ключ позволяет интегрировать данные по рекламным
            кабинетам между системами продавца и MP Surf.
            <br />
            <br />
            Для генерации Performance API ключа, нужно сделать следующее:
            <br />
            1. Перейти в раздел API ключей по ссылке
            <a
              href="https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi"
              class="inline"
              target="_blank"
              >https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi</a
            >
            <br />
            2. Выбрать вкладку Performance API <br />
            3. Создайте аккаунт или выберите нужный из списка и нажмите
            "Добавить ключ" <br />
            4. Введите название ключа в появившемся окне и нажмите кнопку
            "Добавить" <br />
            5. Скопируйте значения столбцов Client ID и Client Secret <br />
          </div>
        </p-panel>
      </div>

      <div *ngIf="modal_title_update_token === 'Ключ API Ozon'" class="mb-3">
        <p-panel
          header="Как узнать свой API ключ"
          class="pointer mb-3"
          [collapsed]="true"
          [toggler]="'header'"
          [toggleable]="true">
          <div style="margin-bottom: 5px; font-weight: 400; font-size: 13px">
            АPI ключ позволяет интегрировать данные между системами продавца и
            MP Surf.
            <br />
            <br />
            Для генерации API ключа, нужно сделать следущее:
            <br />
            1. Перейти в раздел API ключей по ссылке
            <a
              href="https://seller.ozon.ru/app/settings/api-keys"
              class="inline"
              target="_blank"
              >https://seller.ozon.ru/app/settings/api-keys</a
            >
            <br />
            2. Выбрать Seller API, скопировать ClientID <br />
            3. Нажать "Сгенерировать ключ"<br />
            4. В открывшемся окне: указать название для API-ключей и выбрать
            роль "Администратор"<br />
            5. Нажать "Сгенерировать". Ответом за запрос будет API ключ (сразу
            скопируйте его)<br />
          </div>
        </p-panel>
      </div>

      <div *ngIf="modal_title_update_token == 'Название подключения'">
        <input
          [(ngModel)]="name_connect"
          pInputText
          width="100%" />&nbsp;&nbsp;&nbsp;<button
          (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
          class="p-button-raised p-button-rounded"
          pButton
          type="button">
          Сохранить
        </button>
        <div *ngIf="error_phone" class="alert alert-danger">
          {{ error_phone }}
        </div>
      </div>

      <div class="main-card mb-12">
        <div
          *ngIf="modal_title_update_token == 'Wild token'"
          class="d-flex flex-column">
          <div>
            <p>
              Номер телефона необходимо указать от аккаунта со статусом
              "Владелец"
            </p>
            <p-inputMask
              [(ngModel)]="val6"
              [style]="{ width: '100%', marginBottom: '10px' }"
              class="mb-2"
              mask="+7(999) 999-99-99"
              placeholder="+7(999) 999-99-99"></p-inputMask>
            <div *ngIf="error_phone_rr" class="alert alert-danger">
              Не корректно заполнено поле
            </div>
            <textarea
              [(ngModel)]="wild"
              [cols]="30"
              [rows]="5"
              [style]="{ width: '100%' }"
              autoResize="autoResize"
              pInputTextarea></textarea>
            <div *ngIf="error_phone" class="alert alert-danger">
              {{ error_phone }}
            </div>
          </div>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="mt-2 p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Ключ API Новый'"
          class="d-flex flex-column">
          <textarea
            [(ngModel)]="KeyApiNewer"
            [cols]="30"
            [rows]="5"
            autoResize="autoResize"
            pInputTextarea></textarea>
          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="mt-2 p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Ключ API Ozon'"
          class="d-flex flex-column">
          <input
            type="text"
            pInputText
            class="mb-2"
            [(ngModel)]="ClientID"
            [style]="{ width: '100%' }"
            placeholder="Введите ваш ClientID" />
          <input
            type="text"
            pInputText
            [(ngModel)]="KeyID"
            class="mb-2"
            [style]="{ width: '100%' }"
            placeholder="Введите ваш KeyID" />

          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>

          <button
            (click)="updateTokenNew('OzonKeys', modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Ключ Рекламный API Ozon'"
          class="d-flex flex-column">
          <input
            type="text"
            pInputText
            class="mb-2"
            [(ngModel)]="ClientID"
            [style]="{ width: '100%' }"
            placeholder="Введите ваш ClientID" />
          <input
            type="text"
            pInputText
            [(ngModel)]="ClientSecret"
            class="mb-2"
            [style]="{ width: '100%' }"
            placeholder="Введите ваш Client Secret" />

          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>

          <button
            (click)="updateTokenNew('OzonAdvKeys', modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
        </div>

        <div
          *ngIf="modal_title_update_token == 'Налог'"
          class="d-flex flex-column">
          <div class="position-relative row form-group mb-3">
            <label class="col-sm-3 col-form-label" for="account_id"
              >Тип налога<span style="color: red">*</span></label
            >
            <div class="col-sm-9">
              <ng-select
                [(ngModel)]="tax_type"
                [dropdownPosition]="'bottom'"
                [items]="typesOfTaxes"
                [searchable]="true"
                [virtualScroll]="true"
                bindLabel="title"
                bindValue="value"
                id="account_id"
                notFoundText="Ничего не найдено"
                placeholder="Тип налога">
              </ng-select>
            </div>
          </div>
          <div class="position-relative row form-group mb-3">
            <label class="col-sm-3 col-form-label" for="account_id"
              >Процент, %<span style="color: red">*</span></label
            >
            <div class="col-sm-9">
              <p-inputNumber
                [(ngModel)]="tax_percent"
                [max]="100"
                [min]="0"></p-inputNumber>
            </div>
          </div>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Supplier-id'"
          class="d-flex gap-2 flex-column">
          <input [(ngModel)]="supplier" pInputText width="100%" />
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'WBToken для рекламных кампаний'"
          class="d-flex gap-2 flex-column">
          <textarea
            [(ngModel)]="WBTokenAdv"
            [cols]="30"
            [rows]="5"
            autoResize="autoResize"
            pInputTextarea></textarea>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>

          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'WBToken для внутренней аналитики'"
          class="">
          <textarea
            [(ngModel)]="WBTokenPortal"
            [cols]="30"
            [rows]="5"
            autoResize="autoResize"
            pInputTextarea></textarea>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>

          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Ключ API Статистика'"
          class="d-flex gap-2 flex-column">
          <textarea
            [(ngModel)]="KeyApiStat"
            [cols]="30"
            [rows]="5"
            autoResize="autoResize"
            pInputTextarea></textarea>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Ключ API Стандартный'"
          class="d-flex gap-2 flex-column">
          <textarea
            [(ngModel)]="KeyApiNew"
            [cols]="30"
            [rows]="5"
            autoResize="autoResize"
            pInputTextarea></textarea>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>

          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Ключ API Реклама'"
          class="d-flex gap-2 flex-column">
          <textarea
            [(ngModel)]="KeyApiAdv"
            [cols]="30"
            [rows]="5"
            autoResize="autoResize"
            pInputTextarea></textarea>
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>
        </div>
        <div
          *ngIf="modal_title_update_token == 'Telegram ID'"
          class="d-flex gap-2 flex-column">
          <p-panel
            header="Как узнать свой Telegram ID"
            class="pointer"
            [collapsed]="true"
            [toggler]="'header'"
            [toggleable]="true">
            <div style="margin-bottom: 5px; font-weight: 400; font-size: 13px">
              Telegram ID — это цифровой код, который присваивается каждому
              аккаунту при регистрации.
              <br />
              <br />
              Для определения Telegram ID, нужно сделать следующее:
              <br />
              1. Перейти в телеграмм-бот по ссылке
              <a href="https://t.me/myidbot" class="inline" target="_blank"
                >https://t.me/myidbot</a
              >
              <br />
              2. Нажать кнопку "Запустить" <br />
              3. Нажать на кнопку "Меню" и выбрать запрос "/getid Returns your
              Telegram ID" <br />
              4. Ответом на этот запрос будет Telegram ID <br />
            </div>
          </p-panel>
          <!--                    <input [(ngModel)]="tgid" width="100%" placeholder="Введите ваш Telegram ID">-->
          <p-inputNumber
            [(ngModel)]="tgid"
            [style]="{ width: '100%' }"
            mode="decimal"
            inputId="withoutgrouping"
            [useGrouping]="false"
            placeholder="Введите ваш Telegram ID" />

          <div *ngIf="error_phone" class="alert alert-danger">
            {{ error_phone }}
          </div>

          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <p-toast />
</ng-container>
