import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../../../shared/services/api.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IDataShopWB,
  IFinItemList2,
  IFinPartnersList,
} from './partners.interface';
import { FINPARTNERS_COLUMN_DEFS } from './partners.mock';
import { ColDef } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { SharedDataService } from '../../../../shared/services/shared-data.service';
import { LOCALE_TEXT_FOR_FILTERS } from '../../../guides/products/products.mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PopUpStatus } from '../../../../shared/interfaces/common.type';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { IResponseCommon } from '../../../settings/shop/shop.interface';
import { AbstractMenuTabComponent } from '../../../abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.sass'],
  providers: [MessageService, ConfirmationService],
})
export class PartnersComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  //  AG_GRID
  rowData: IFinPartnersList[] = [];
  rowsForRemoving: any[] = [];

  readonly finPartnersColumnDF = FINPARTNERS_COLUMN_DEFS;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    floatingFilter: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly gridOptions: GridOptions;
  readonly options: GlobalConfig;
  readonly partnerForm: FormGroup;
  itemsIncomeList: IFinItemList2[] = [];
  itemsExpensesList: IFinItemList2[] = [];
  isLoading = false;
  shop_list: IDataShopWB[] = [];
  modal_partner_id: any;
  items: any;

  isEdit = false;
  activeUID = null;
  @ViewChild('createPartnerModal', { read: TemplateRef })
  createPartnerModal: TemplateRef<any>;
  @ViewChild('updatePartnerModal', { read: TemplateRef })
  updatePartnerModal: TemplateRef<any>;

  text = ''
  subtext = ''

  constructor(
    private api: ApiService,
    public toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    super();

    this.options = this.toastr.toastrConfig;
    this.partnerForm = this.fb.group({
      expenses_item: new FormControl(''),
      income_item: new FormControl(''),
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
      inn: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      kpp: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      checking_account: new FormControl(''),
      description: new FormControl(''),
    });
    this.gridOptions = {
      context: { componentParent: this },
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    this.loadPartners();
    this.getShopList();
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'fin/settings/partners') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  onRowSelected(event: any) {
    const selectedUID = event.data.UID;
    const selectedStatus = event.node.selected;
    if (selectedStatus) {
      this.rowsForRemoving.push(selectedUID);
    } else {
      this.rowsForRemoving = this.rowsForRemoving.filter(
        row => row !== selectedUID
      );
    }
  }

  removeSelected() {
    if (confirm('Вы уверены, что хотите удалить запись ?')) {
      const requests = this.rowsForRemoving.map(row => {
        const body = {
          uid: row,
          shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
        };
        return this.api.userRegisterFin(
          'getFinPartnersDelete',
          localStorage.getItem('token'),
          body
        );
      });
      this.isLoading = true;
      forkJoin(requests)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.loadPartners();
            this.rowsForRemoving.length = 0;
            this.showPopUpMessage(
              'success',
              PopUpMessages.deleteSuccessSummary,
              PopUpMessages.deleteSuccessMessage
            );
          },
          () => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.deleteFailedSummary,
              PopUpMessages.deleteFailedMessage
            );
          }
        );
    }
  }

  openModalCreatePartner(content) {
    this.partnerForm.reset();
    this.isEdit = false;
    this.modalService.open(content);
  }

  openModalUpdatePartner(rowData: IFinPartnersList) {
    this.activeUID = rowData.UID;
    this._mpSurfService.load({uid: rowData.UID,}, 'fin-partners')
      .subscribe(
        (data: any) => {
          this.modal_partner_id = data.UID;
          this.partnerForm.patchValue({
            name: data.name,
            inn: data.INN,
            kpp: data.KPP,
            checking_account: data.checking_account,
            description: data.description,
            expenses_item: data.expenses_item,
            income_item: data.income_item,
          });
          this.isEdit = true;
          this.modalService.open(this.createPartnerModal, {
            backdrop: 'static',
          });
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  createUpdateFinPartner() {
    this.isLoading = true;
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      uid: this.isEdit ? this.activeUID : undefined,
    };
    this._mpSurfService.loadByPost({...body, ...this.partnerForm.getRawValue()}, 'fin-partners')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IResponseCommon) => {
          if (+response.is_error === 0) {
            this.isEdit
              ? this.showPopUpMessage(
                  'success',
                  PopUpMessages.updateSuccessSummary,
                  'Контрагент изменен'
                )
              : this.showPopUpMessage(
                  'success',
                  PopUpMessages.createSuccessSummary,
                  'Контрагент создан'
                );
            this.partnerForm.reset();
            this.modalService.dismissAll();
            this.loadPartners();
          } else {
            this.showPopUpMessage(
              'error',
              this.isEdit
                ? PopUpMessages.updateFailedSummary
                : PopUpMessages.createFailedSummary,
              response.msg
            );
          }
        },
        () => {
          this.isEdit
            ? this.showPopUpMessage(
                'error',
                PopUpMessages.createFailedSummary,
                PopUpMessages.createFailedMessage
              )
            : this.showPopUpMessage(
                'error',
                PopUpMessages.updateFailedSummary,
                PopUpMessages.updateFailedMessage
              );
        }
      );
  }

  deletePartner(rowData: IFinPartnersList) {
    if (confirm('Вы уверены, что хотите удлаить запись ?')) {
      this._mpSurfService.delete({uid: rowData.UID}, 'fin-partners')
        .subscribe(
          (response: any) => {
            if (+response.is_error === 0) {
              this.showPopUpMessage('success', response.msg, '');
              this.loadPartners();
            } else {
              this.showPopUpMessage('error', response.msg, '');
            }
          },
          () => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.deleteSuccessSummary,
              PopUpMessages.deleteFailedMessage
            );
          }
        );
    }
  }

  private loadPartners() {
    this.isLoading = true;
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
    };
    this._mpSurfService.load({}, 'fin-partners')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IFinPartnersList[]) => {
          this.rowData = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private getShopList() {
    this.isLoading = true;
    this.api
      .userRegister('getDataShopWB', localStorage.getItem('token'), '')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IDataShopWB[]) => {
          this.shop_list = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }


  private getFullNameOfItems(itemId: number, status: string): string {
    if (itemId) {
      if (status === 'income') {
        return (
          this.itemsIncomeList.filter(item => +item.id === itemId)[0]?.name ??
          '--'
        );
      }
      if (status === 'expenses') {
        return (
          this.itemsExpensesList.filter(item => +item.id === itemId)[0]?.name ??
          '--'
        );
      }
    }
  }
}
