<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />

<div *ngIf="view_google_table_info" class="col-sm-6 col-md-6">
  <div class="mb-3 card text-white card-body bg-warning">
    <h5 class="card-title">
      Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf
    </h5>
    <div class="col-sm-4 col-md-4">
      <button
        (click)="closeGoogleTableMsg(1)"
        class="btn btn-primary mt-2 mr-2">
        Все понятно
      </button>
    </div>
  </div>
  <br />
</div>
<div class="app-page-title">
  <div class="page-title-heading">
    <div class="page-title-left">
      <div class="page-title-icon">
        <i class="pe-7s-diamond icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        Мои товары
        <i
          class="fa fa-question-circle"
          aria-hidden="true"
          [pTooltip]="
            'Серые столбцы можно редактировать - дважды нажмите на серую ячейку и внесите необходимые изменения!'
          "></i>
      </div>
      <app-mp-video-play [videoLink]="getVideoLinkByPage('guides/products')" />
    </div>
    <div class="page-title-right">
      <div class="page-title-right-group">
        <button (click)="openModal()" class="btn btn-success">
          Настройка статусов
        </button>
        <button
          (click)="exportExcel()"
          class="btn btn-success"
          type="button">
          Скачать шаблон
        </button>
        <button
          (click)="switchProductsAvailibility(0)"
          *ngIf="OBOROT === 1"
          class="btn btn-success">
          Скрыть выведенные из оборота
        </button>
        <p-fileUpload
          #uploadedFiles
          (onError)="onBasicUploadError($event, uploadedFiles)"
          (onSend)="onSendData()"
          (onUpload)="onBasicUploadAuto($event, uploadedFiles)"
          [auto]="true"
          [maxFileSize]="10000000"
          [style]="{ height: '33px', fontSize: '12px' }"
          accept=".xls, .xlsx, .csv"
          chooseLabel="Загрузить из Excel"
          label="Import"
          mode="basic"
          name="file"
          url="{{ URL_NEW }}products/uploaded?token={{ TOKEN }}&shop_id={{
            SHOP_ID
          }}" />
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div *ngIf="loading" style="width: 100%;">
    <ag-grid-angular
      (cellValueChanged)="onCellValueChanged($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (gridReady)="onGridReady($event)"
      [columnDefs]="columnDefsSales"
      [defaultColDef]="defaultColDef"
      [enableRangeSelection]="true"
      [getRowStyle]="getRowStyle"
      [gridOptions]="gridOptions"
      [localeText]="localeTextForFilters"
      [pagination]="true"
      [paginationPageSize]="30"
      [domLayout]="'autoHeight'"
      [rowData]="rowData"
      [rowDragManaged]="true"
      [rowHeight]="40"
      [tooltipHideDelay]="tooltipHideDelay"
      [tooltipShowDelay]="tooltipShowDelay"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      rowSelection="multiple"
      style="width: 100%;" />
    <button
      (click)="exportExcel()"
      class="mb-2 btn btn-success add-operation-btn button-export-fin"
      type="button">
      Скачать в Excel
    </button>
  </div>
</div>

<ng-template #productStatuesModal>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="'Добавление статусов товаров'">
  </app-header-modal>
  <form [formGroup]="statusesForm">
    <div class="modal-body">
      <div class="separate__block" formArrayName="statuses">
        <div
          *ngFor="let statuses of FormArrayStatuses.controls; let i = index"
          class="wrapp-operation">
          <div [formGroupName]="i" class="wrap__expenses__block mb-2">
            <input
              [style]="{ width: '100%' }"
              formControlName="name"
              pInputText
              type="text" />
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-footer-modal
    (close)="closeModal()"
    (submitEmitter)="createStatuses()"
    [isCancel]="true"
    [isCreate]="true"
    [isDisabled]="isDisabledModal">
  </app-footer-modal>
</ng-template>
<p-toast />

<ng-template #tpl>
  <section class="sebes-modal">
    <div class="header">
      Себестоимость
    </div>
    <div class="subheader">
      <section class="subheader-info">
        <div>
          Предмет: {{modalData.subject}}
        </div>
        <div>
          Артикул поставщика: {{modalData.SKU}}
        </div>
        <div>
          Артикул: {{modalData.nmid}}
        </div>
        <div>
          Цвет: {{modalData.colors}}
        </div>
        <div>
          Размер: {{modalData.techSize}}
        </div>
        <div>
          Бренд: {{modalData.brand}}
        </div>
      </section>
      <section class="subheader-photo">
        <img [src]="modalData.photo || 'https://storage.mds.yandex.net/get-tfb/15151514/org100086145_attach_448_1738751002668?ts=00062e163ba567de&content_type=image%2fjpeg&filename=photo_2025-02-05_13-21-56.jpg&disposition=inline&sign=ab9bcdbd98f2e467973baa63bca0b46dbf22a0b20932ffcae62b49450490ba51'" alt="">
      </section>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 10px; margin-bottom: 10px;">
      <div style="display: flex; flex-direction: column; flex: 1; padding: 0 2px">
        <label>Дата корректировки</label>
        <input
          #d1="ngbDatepicker"
          (click)="d1.toggle()"
          autocomplete="off"
          class="form-control"
          [(ngModel)]="date" 
          id="date_balance_init"
          name="dp1"
          ngbDatepicker
          placeholder="DD.MM.YYYY"
          [style]="{height: '38px'}"
          readonly
        />
      </div>
      <div style="display: flex; flex-direction: column; flex: 1; padding: 0 2px;">
        <label for="item1">Закупка / производство, руб</label>
        <p-inputNumber 
          [maxFractionDigits]="2"
          mode="decimal"
          locale="ru-RU"
          [(ngModel)]="cost_price_input" 
          required
          #cost="ngModel"
        />
      </div>
      <div style="display: flex; flex-direction: column; flex: 1; padding: 0 2px">
        <label for="item2">Доп. расходы, руб</label>
        <p-inputNumber
          [maxFractionDigits]="2"
          mode="decimal"
          locale="ru-RU"
          [(ngModel)]="other_deductions_input" 
          required
        />
      </div>
    </div>
    <div>
      <button style="
        font-size: .7rem;
        height: 25px;
        margin-top: -4px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        padding-bottom: 2px;" 
        class="btn btn-primary"
        (click)="apply()"
      >Применить
      </button>
      <span style="margin-left: 8px;">Применить к остальным размерам артикула</span>
    </div>
    <div style="margin-top: 10px; margin-bottom: 10px;">
      <ag-grid-angular
          (gridReady)="onGridReady1($event)"
          [columnDefs]="coldef"
          [defaultColDef]="defaultColDef"
          [enableRangeSelection]="true"
          [suppressRowClickSelection]="true"
          [localeText]="localeTextForFilters"
          [pagination]="true"
          [paginationPageSize]="30"
          [domLayout]="'autoHeight'"
          [rowData]="all_articles"
          [rowDragManaged]="true"
          [rowHeight]="30"
          overlayNoRowsTemplate="Нет данных для отображения"
          class="ag-theme-alpine"
          rowSelection="multiple"
          style="width: 100%;" 
      />
    </div>
    <h5>История изменений себестоимости</h5>
    <div style="margin-top: 10px;">
      <ag-grid-angular
          (gridReady)="onGridReady2($event)"  
          [gridOptions]="gridOptions2"
          [columnDefs]="coldef2"
          [defaultColDef]="defaultColDef2"
          [enableRangeSelection]="true"
          [suppressRowClickSelection]="true"
          [localeText]="localeTextForFilters"
          [pagination]="true"
          [paginationPageSize]="30"
          [domLayout]="'autoHeight'"
          [rowData]="history_changes"
          [rowDragManaged]="true"
          [rowHeight]="30"
          overlayNoRowsTemplate="Нет данных для отображения"
          class="ag-theme-alpine"
          rowSelection="multiple"
          style="width: 100%;" 
      />
    </div>
    <footer>
      <button class="btn btn-primary" (click)="onSave()">Сохранить</button>
      <button class="btn btn-light" (click)="onClose()">Отменить</button>
    </footer>
  </section>
</ng-template>
