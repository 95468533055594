export const RNP_MOCK = [
  {
    id: 'voronka',
    groups: [
      {
        type: 'general',
        columns: [
          {
            name: 'Показатели',
            width: 375,
            align: 'left',
            headerCenter: false
          },
          {
            name: 'Итого',
            width: 132,
            align: 'center',
            headerCenter: true
          }
        ]
      },
      {
        type: 'range',
        name: '01/01/2024 - 07/01/2024',
        columns: [
          '01/01/2024',
          '02/01/2024',
          '03/01/2024',
          '04/01/2024',
          '05/01/2024',
          '06/01/2024',
          '07/01/2024',
        ]
      },
      {
        type: 'range',
        name: '08/01/2024 - 14/01/2024',
        columns: [
          '08/01/2024',
          '09/01/2024',
          '10/01/2024',
          '11/01/2024',
          '12/01/2024',
          '13/01/2024',
          '14/01/2024',
        ]
      },
    ],
    table: [
      {
        'Показатели': 'Заказы (API оперативные заказы), руб',
        'Итого': 123123,
        '01/01/2024': 200,
        '02/01/2024': 250,
        '03/01/2024': 450,
        '04/01/2024': 520,
        '05/01/2024': 600,
        '06/01/2024': 125,
        '07/01/2024': 840,
        '08/01/2024': 210,
        '09/01/2024': 360,
        '10/01/2024': 560,
        '11/01/2024': 700,
        '12/01/2024': 1200,
        '13/01/2024': 630,
        '14/01/2024': 100,
      },
      {
        'Показатели': 'Заказы (API оперативные заказы), шт',
        'Итого': 123123,
        '01/01/2024': 123123,
        '02/01/2024': 123123,
        '03/01/2024': 123123,
        '04/01/2024': 123123,
        '05/01/2024': 123123,
        '06/01/2024': 123123,
        '07/01/2024': 123123,
        '08/01/2024': 123123,
        '09/01/2024': 123123,
        '10/01/2024': 123123,
        '11/01/2024': 123123,
        '12/01/2024': 123123,
        '13/01/2024': 123123,
        '14/01/2024': 123123,
      },
      {
        'Показатели': 'Ср. чек заказа (в ценах поставщика), руб',
        'Итого': 123123,
        '01/01/2024': 123123,
        '02/01/2024': 123123,
        '03/01/2024': 123123,
        '04/01/2024': 123123,
        '05/01/2024': 123123,
        '06/01/2024': 123123,
        '07/01/2024': 123123,
        '08/01/2024': 123123,
        '09/01/2024': 123123,
        '10/01/2024': 123123,
        '11/01/2024': 123123,
        '12/01/2024': 123123,
        '13/01/2024': 123123,
        '14/01/2024': 123123,
      },
      {
        'Показатели': 'Ср. чек заказа (в ценах МП), руб',
        'Итого': 123123,
        '01/01/2024': 123123,
        '02/01/2024': 123123,
        '03/01/2024': 123123,
        '04/01/2024': 123123,
        '05/01/2024': 123123,
        '06/01/2024': 123123,
        '07/01/2024': 123123,
        '08/01/2024': 123123,
        '09/01/2024': 123123,
        '10/01/2024': 123123,
        '11/01/2024': 123123,
        '12/01/2024': 123123,
        '13/01/2024': 123123,
        '14/01/2024': 123123,
      },
    ]
  }
]