import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';
import { FORTH_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { forkJoin } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { TreeTable } from 'primeng/treetable';
import {
  IFinOperationFinAccountsList,
  IFinOperationFinPartnersList,
} from '../finoperations/finoperations.interface';
import { FIN_DDS_LABELS } from './findds.model';
import { finalize } from 'rxjs/operators';
import { ApexOptions } from 'ng-apexcharts';
import { generateChartOptions } from './findds.func';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fin-dds',
  templateUrl: './findds.component.html',
  styleUrls: ['./findds.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class FinddsComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  private readonly cdr = inject(ChangeDetectorRef)
  readonly ranges = FORTH_RANGE_TYPE;
  readonly selectOptions = [
    { name: 'По неделям', value: 1 },
    { name: 'По месяцам', value: 2 },
    /*, {name: 'По кварталам', value: 3}*/
  ];
  readonly specialHeaders = [
    'Доступные средства на начало периода',
    'Доступные средства на конец периода',
    'Оборот денег за период',
    'Операционная деятельность',
    'Финансовая деятельность',
    'Инвестиционная деятельность',
    'Деятельность вне МП',
    'Перемещения',
    'Поступления',
    'Списания',
  ];

  ddsDataTable: TreeTable & { data: any } = null;
  chartOptions: Partial<ApexOptions> | null = null

  selectedDate: ISelectedNgbDate = this._sharedDataService.filterDate || {
    startDate: moment().subtract(21, 'day'),
    endDate: moment(),
  };
  selectedAcc: IFinOperationFinAccountsList[] = [];
  selectedPartners: IFinOperationFinPartnersList[] = [];
  selectedDDSType: any = 2;

  accountsList: IFinOperationFinAccountsList[] = [];
  partnersList: IFinOperationFinPartnersList[] = [];
  isDisplayGoogleTableInfo = true;
  text = ''
  subtext = ''

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadPartnersAndAccounts();
    this.isDisplayGoogleTableInfo =
      localStorage.getItem('googleMsgInfo') == null;
  }

  closeGoogleTableMsg(param: number) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.isDisplayGoogleTableInfo = false;
    }
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'fin/dds') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  dateChanged($event: ISelectedNgbDate) {
    this._sharedDataService.setFilterDate($event)
    this.selectedDate = $event;
    this.changeType({ value: this.selectedDDSType });
  }

  public changeType(obj: any) {
    let partUid;
    let accUid;
    this.selectedPartners && this.selectedPartners.length > 0
      ? (partUid = this.selectedPartners)
      : (partUid = null);
    this.selectedAcc && this.selectedAcc.length > 0
      ? (accUid = this.selectedAcc)
      : (accUid = null);
    const body = {
      type_group: obj.value,
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      param_acc: accUid ?? undefined,
      param_part: partUid ?? undefined,
      shop_id: this.SHOP_ID,
    };
    if ([1, 2].includes(+obj.value)) {
      this.loadFinDDS(body);
    } else if (+obj.value === 3) {
      body.endDate = moment(
        this.selectedDate.endDate.format('YYYY-MM-DD'),
        'YYYY-MM-DD'
      )
        .endOf('month')
        .format('YYYY-MM-DD');
      this.loadFinDDS(body);
    }
  }

  checkIsLableWithChildren(rowData: {
    name: string;
    [key: string]: string;
  }): boolean {
    return FIN_DDS_LABELS.includes(rowData.name);
  }

  private loadPartnersAndAccounts() {
    forkJoin([
      this._mpSurfService.load({}, 'fin-account'),
      this._mpSurfService.load({}, 'fin-partners'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([responsePartners, responseAccounts]) => {
          this.partnersList =
            responsePartners as IFinOperationFinPartnersList[];
          this.accountsList =
            responseAccounts as IFinOperationFinAccountsList[];
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadFinDDS(params: any): void {
    this._mpSurfService
      .load(params, 'fin-dds')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: TreeTable & { data: any }) => {
          this.ddsDataTable = response;
          this.chartOptions = generateChartOptions(response)
          this.cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  // clearFilters(paramID: number) {
  //   if (paramID === 1 && this.selectedAcc) {
  //     this.selectedAcc.length = 0;
  //   }
  //   if (paramID === 2 && this.selectedPartners) {
  //     this.selectedPartners.length = 0;
  //   }
  //   this.changeType({ value: this.selectedDDSType });
  // }
  //     selectParameter() {
  //     const body = {
  //       type_group: this.selectedDDSType,
  //       startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
  //       endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
  //       param_acc: (this.selectedAcc || this.selectedAcc?.length > 0) ?? undefined,
  //       param_part: (this.selectedPartners || this.selectedPartners?.length > 0) ?? undefined,
  //       shop_id: this.SHOP_ID,
  //     };
  //     this.loadFinDDS(body);
  //   }
}
