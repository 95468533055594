<p-progressSpinner
  *ngIf="isLoading"
  [style]="{ position: 'absolute', top: '40%', left: '50%' }" />
<p-toast />
<div [ngStyle]="{ display: isLoading ? 'none' : 'block' }">
  <div class="h-100">
    <div class="row h-100 g-0">
      <div
        class="manrope-font h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-6" style="padding: 32px 0;">
        <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9" style="height: 100%;">
          <div class="w-100 mx-auto" style="display: flex; justify-content: center; height: 100%;">
            <div class="modal-content" style="height: 100%;">
              <div class="modal-body" style="display: flex; flex-direction: column;">

                <div style="margin-bottom: 16px;">
                  <a href="https://mpsurf.ru/">&#60; Вернуться назад</a>
                </div>

                <section style="display: flex; align-items: center; height: 100%;">
                  <section>
                    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                      <section>
                        <img src="assets/images/MPSURFHQ.png" alt="" height="40">
                      </section>
                      <h6 style="color: #ea612a; font-weight: 600;">сервис внутренней аналитики WB и Ozon</h6>
                      <h6>5 дней бесплатно (без привязки карты)</h6>
    
                      <button
                        style="width: 100%; margin-top: 32px; margin-bottom: 8px;" 
                        type="button" 
                        class="btn btn-primary"
                      >
                        <a href="https://t.me/mpsurfreg_bot" target="_blank">Зарегистрироваться через Telegram</a>
                      </button>
    
                      <div (click)="openQRDialog(modalContent)" style="margin-bottom: 32px;">
                        <a style="color: #3f6ad8; cursor: pointer;">Инструкция и QR-код для мобильной версии</a>
                      </div>
                    </div>
                    <div>
                      <form [formGroup]="LoginForm" novalidate>
                        <div class="row">
                          <div class="col-12">
                            <fieldset class="mb-3">
                              <div role="group" tabindex="-1">
                                <input
                                  class="form-control"
                                  formControlName="email"
                                  name="email"
                                  placeholder="Введите номер телефона"
                                  required
                                  type="email" />
                              </div>
                            </fieldset>
                          </div>
                          <div class="col-12">
                            <fieldset class="">
                              <div class="password-group" role="group" tabindex="-1">
                                <input
                                  class="form-control"
                                  formControlName="password"
                                  name="password"
                                  placeholder="Ваш пароль"
                                  [type]="isShown ? 'text' : 'password'" 
                                />
                                <fa-icon class="password-icon" (click)="togglePassword()" [icon]="isShown ? faEyeSlash : faEye"></fa-icon>
                              </div>
                            </fieldset>
                          </div>
                          <div
                            *ngIf="
                              LoginForm.controls['password'].hasError(
                                'invalidlogin'
                              )
                            "
                            [innerHTML]="errorMessage"
                            class="alert alert-danger"></div>
                        </div>
                        <div class="d-flex align-items-center">
                          <p-toast />
                          <div class="ms-auto">
                            <a
                              class="btn-lg btn btn-link"
                              href="https://t.me/mpsurfreg_bot?restore_password"
                              target="_blank"
                            >
                              Забыли пароль?
                            </a>
                          </div>
                        </div>
                        <button
                          (click)="logIn()"
                          [disabled]="LoginForm.invalid"  
                          style="width: 100%; margin-top: 8px" 
                          type="button" 
                          class="btn btn-primary"
                        >Войти
                        </button>
    
                        <div class="alternative-enter-hint">
                          <span class="hint">Или</span>
                        </div>
                        <div id="buttonContainerId" style="margin: 8px 0;">
                        </div>
                        <div style="font-size: 10px;"> 
                          Нажимая кнопку «Авторизоваться» или используя Яндекс, вы принимаете условия 
                          <a href="https://mpsurf.ru/oferta" target="_blank">Пользовательского соглашения</a> и 
                          <a href="https://mpsurf.ru/privacy" target="_blank">Политики конфиденциальности</a></div>
                      </form>
                    </div>
                  </section>
                </section>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="manrope-font d-none d-lg-block col-lg-6">
        <app-login-slider />
      </div>
    </div>
  </div>
</div>


<ng-template #modalContent>
  <div style="display: flex; margin-top: 8px; align-items: center;">
    <section style="width: 40%;">
      <img style="width: 100%;" src="assets/images/qrcodetg.png" alt="">
    </section>
    <section style="width: 60%; font-size: 14px;">
      <ul style="list-style-type: disc;">
        <li>Для регистрации отсканируйте QR-код или нажмите на кнопку "Зарегистрироваться через Telegram"</li>
        <li>После завершения регистрации введите в форме выше номер телефона и пароль полученный в Telegram - боте</li>
      </ul>
    </section>
  </div>
</ng-template>
