import { ApexOptions } from "ng-apexcharts";
import { IAdvStatChartPoint, IAdvStatRowData } from "./adv-stat.component";
import { UnitPipe } from "app/shared/pipes/unit.pipe";
import { DecimalPipe } from "@angular/common";
import { ColDef } from "ag-grid-community";
import { NumberSortFunc } from "app/shared/utils/functions/number-sort-func";

const decimalPipe = new DecimalPipe('ru-RU');
const unitPipe = new UnitPipe();

export function getChartOptions(data: {
  data: IAdvStatChartPoint[];
}): Partial<ApexOptions> {
  return {
    series: [
      {
        name: 'Заказы (шт)',
        type: 'column',
        data: data.data.map(elem => +elem.orders_count || 0),
        color: '#74db90',
      },
      {
        name: 'Заказы (тыс.руб.)',
        type: 'line',
        data: data.data.map(elem => +elem.orders_sum || 0),
        color: 'rgba(9, 60, 143, 1)',
      },
      {
        name: 'Реклама (шт)',
        type: 'column',
        data: data.data.map(elem => +elem.adv_count || 0),
        color: '#e8b0c6',
      },
      {
        name: 'Реклама (руб)',
        type: 'line',
        data: data.data.map(elem => +elem.adv_sum || 0),
        color: 'rgba(186, 20, 20, 1)',
      },
    ],
    chart: {
      type: 'area',
      height: 360,
      stacked: false,
      animations: {
        enabled: true,
      },
    },
    dataLabels: { enabled: false },
    //@ts-ignore
    stroke: { curve: 'smooth', width: [2, 3, 2, 3] },
    legend: { horizontalAlign: 'left', offsetX: 40 },
    xaxis: {
      type: 'category',
      categories: data.data.map(elem => elem.date),
    },
    yaxis: [
      {
        seriesName: 'Заказы (шт)',
        opposite: true,
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0'),
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Штуки',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Заказы (тыс.руб.)',
        labels: {
          formatter: (v: number) => decimalPipe.transform(v/1000, '1.0-0'),
        },
        axisTicks: {
          show: true,
          color: 'rgba(9, 60, 143, 1)',
        },
        axisBorder: {
          show: true,
          color: 'rgba(9, 60, 143, 1)',
        },
        title: {
          text: 'Заказы (тыс.руб.)',
          style: {
            color: 'rgba(9, 60, 143, 1)',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Заказы (шт)',
        opposite: true,
        show: false,
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0')
        },
      },
      {
        seriesName: 'Реклама (руб)',
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0'),
        },
        axisTicks: {
          show: true,
          color: 'rgba(186, 20, 20, 1)',
        },
        axisBorder: {
          show: true,
          color: 'rgba(186, 20, 20, 1)',
        },
        title: {
          text: 'Реклама (руб)',
          style: {
            color: 'rgba(186, 20, 20, 1)',
          },
        },
        min: 0,
      },
    ],
  };
}

export function genarateColumnDefs(isOzon: boolean): ColDef[] {
  const COMMON_DEFS: ColDef[] = [
    {
      field: 'date',
      headerName: 'Дата РК',
      headerClass: 'header-centered',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 180,
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'advertId',
      headerName: 'ID Кампании',
      headerClass: 'header-centered',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 120,
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'nmId',
      headerName: 'Артикул МП',
      headerClass: 'header-centered',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 120,
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'views',
      headerName: 'Просмотры, шт ',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 120
    },
    {
      field: 'clicks',
      headerName: 'Клики, шт ',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 120
    },
    {
      field: 'atbs',
      headerName: 'Добавлено в корзину, шт',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 120
    },
    {
      field: 'shks',
      headerName: 'Заказы из РК Статистики, шт',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 120,
      headerTooltip: 'Заказы только по данной РК'
    },
    {
      field: 'sum_price',
      headerName: 'Заказы из РК Статистики, руб',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 120,
      headerTooltip: 'Заказы только по данной РК'
    },
    {
      field: 'sum',
      headerName: 'Затраты, руб',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 120,
      headerTooltip: 'Затраты из API Статистика не бьются с финансовыми списаниями'
    },
    {
      field: 'cr',
      headerName: 'CR',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
      width: 86,
      headerTooltip: 'Это отношение количества заказов к общему количеству посещений (просмотров) кампании'
    },
    {
      field: 'cpc',
      headerName: 'CPC',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
      width: 86,
      headerTooltip: 'Средняя стоимость клика'
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      filter: 'agNumberColumnFilter',
      sortable: true,
      comparator: NumberSortFunc,
      valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
      width: 86,
      headerTooltip: 'Показатель кликабельности. Отношение числа кликов к количеству показов'
    },
  ];
  const ONLY_WB_DEFS: ColDef[] = [
    {
      field: 'name',
      headerName: 'Название рекламы',
      headerClass: 'header-centered',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 177,
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'adv_type',
      headerName: 'Тип',
      headerClass: 'header-centered',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 120,
      cellStyle: { textAlign: 'left' },
    },
  ];

  return isOzon ? COMMON_DEFS : [COMMON_DEFS[0]].concat(ONLY_WB_DEFS).concat(COMMON_DEFS.slice(1))
}

export function generateSummary(data: IAdvStatRowData[]) {
  const rows = Array.isArray(data) ? data : [];
  const watchCountAgg = rows.map(row => +row.views).reduce((prev, curr) => prev + curr, 0);
  const clickCountAgg = rows.map(row => +row.clicks).reduce((prev, curr) => prev + curr, 0);
  const addedToCartCountAgg =  rows.map(row => +row.atbs).reduce((prev, curr) => prev + curr, 0);
  const ordersCountAgg =  rows.map(row => +row.shks).reduce((prev, curr) => prev + curr, 0);
  const ordersSumAgg = rows.map(row => +row.sum_price).reduce((prev, curr) => prev + curr, 0);
  const expensesSumAgg =  rows.map(row => +row.sum).reduce((prev, curr) => prev + curr, 0);
  return [
    {
      date: 'Итого',
      views: watchCountAgg,
      clicks: clickCountAgg,
      atbs: addedToCartCountAgg,
      shks: ordersCountAgg,
      sum_price: ordersSumAgg,
      sum: expensesSumAgg,
      cr: (ordersCountAgg * 10000)/watchCountAgg,
      cpc: expensesSumAgg / clickCountAgg,
      ctr: (clickCountAgg * 100) / watchCountAgg,
    },
  ]
}
