import { Component, OnInit } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { FORTH_RANGE_TYPE, THIRD_RANGE_TYPE } from 'app/shared/common-variables/time-ranges-date-picker';
import { ISelectedNgbDate } from 'app/shared/interfaces/common.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';
import { IArticlesPurchase } from 'app/menu-tabs/guides/finpurchase/finpurchase.interface';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { generateColumnDefs } from './rnp.func';
import { RNP_MOCK } from './rnp.mock';
import { forkJoin } from 'rxjs';

interface RnpResponse {
  "totalPagesCount": number,  
  "pagination": number,    
  "data": {
    id: number,
    groups: any[],
    table: any[]
  }[]
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-rnp',
  templateUrl: './rnp.component.html',
  styleUrls: ['./rnp.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class RnpComponent extends AbstractMenuTabComponent implements OnInit {

  columnDefs1 = [];
  rowData1 = RNP_MOCK;

  columnDefs2 = [];
  rowData2 = RNP_MOCK;

  columnDefs4 = [];
  rowData4 = RNP_MOCK;

  defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };

  readonly presets = THIRD_RANGE_TYPE;
  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(14, 'day'),
    endDate: moment(),
  };

  selectedNm = [];
  selectedSubject = [];
  selectedCategories = [];
  selectedDateType = 1;
  selectedPage = 1;

  subjectsList: any[] = [];
  categoriesList = [];
  articles = [];

  loading = false;
  paginationLength = 1;
  lastReportDate = '';

  text = '';
  subtext = '';

  constructor() {
    super()
    this.gridOptions = {
      context: { componentParent: this },
    };
  }

  ngOnInit(): void {
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data
    });

    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore 
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/rnp') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })

    this.loadArticles();
    this.loadSubjects();
    this.loadCategories();
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.loadPanels();
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  onPage(page: object): void {
    //@ts-ignore
    this.selectedPage = page.pageIndex + 1;
    this.loadPanels();
  }

  onDateTypeChange(): void {
    this.selectedPage = 1;
    this.loadPanels();
  }

  loadPanels() {
    this.loading = true;
    const params = {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      categoryPnl: Array.isArray(this.selectedCategories) ? this.selectedCategories.map(s => s.name) : [],
      articles: Array.isArray(this.selectedNm) ? this.selectedNm.map(item => +item.code) : [],
      subjects: Array.isArray(this.selectedSubject) ? this.selectedSubject.map(item => item.code) : [],
      pagination: this.selectedPage,
      dateType: this.selectedDateType
    };

    forkJoin([
      this._mpSurfService.loadByPost({...params, id: 1}, 'rnp/table'),
      this._mpSurfService.loadByPost({...params, id: 2}, 'rnp/table'),
      this._mpSurfService.loadByPost({...params, id: 4}, 'rnp/table')
    ])
    .pipe(untilDestroyed(this))
    .subscribe({
      next: ([data1, data2, data4]: [RnpResponse, RnpResponse, RnpResponse]) => {
        this.columnDefs1 = generateColumnDefs(data1.data[0].groups);
        this.rowData1 = data1.data[0].table;
        this.columnDefs2 = generateColumnDefs(data2.data[0].groups);
        this.rowData2 = data2.data[0].table;
        this.columnDefs4 = generateColumnDefs(data4.data[0].groups);
        this.rowData4 = data4.data[0].table;
        this.loading = false;
        this.paginationLength = data1.totalPagesCount;
        this._cdr.markForCheck()
      },
      error: () => {
        this.loading = false;
      }
    })
  }

  private loadArticles() {
    this._mpSurfService
      .load({}, 'products/important')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IArticlesPurchase[]) => {
          this.articles = response.map((a) => {
            return {
              name: `${a.nmid} - ${a.SKU}`,
              code: a.nmid,
            };
          });
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadSubjects() {
    this._mpSurfService
      .load({}, 'products/subjects')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          //@ts-ignore
          this.subjectsList = response.map(item => ({name: item, code: item}));
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadCategories(): void {
    this._mpSurfService
      .load({}, 'products/category-pnl-list')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: string[]) => {
          this.categoriesList = response.map(r => ({name: r}));
        },
    );
  }

  clearArticles(): void {
    this.selectedNm = [];
    this.loadPanels();
  }

  clearSubjects(): void {
    this.selectedSubject = [];
    this.loadPanels();
  }

  clearCategories(): void {
    this.selectedCategories = [];
    this.loadPanels();
  }

  onOpened(): void {
    // this.loadPanels()
  }
  
}