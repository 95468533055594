<article *ngIf="data" class="tariff_item">
    <section class="tariff_item__header">
        Тариф {{data.name}}
    </section>
    <div *ngIf="data.month_summary" class="month-summary">
        {{data.month_summary}}
    </div>
    <div *ngIf="!data.month_summary" class="month-summary-empty">
    </div>
    <section class="tariff_item__body">
        <div class="tariff_item__table">
            <ul>
                <li *ngFor="let row of data.rows" class="tariff_item__table item">

                    <span class="item-title" [pTooltip]="row.tooltip || null">{{row.title}} <i *ngIf="row.tooltip" class="fa fa-question-circle" aria-hidden="true"></i></span>
                    <span class="item-description" [class.fs-18]="row?.description === '∞'">
                        <ng-container *ngIf="row.logos; else desc">
                            <img *ngFor="let logo of row.logos" [src]="logo | logoPipe" [alt]="logo">
                        </ng-container>
                        <ng-template #desc>
                            {{row.description}}
                        </ng-template>
                    </span>
                    
                </li>
            </ul>
        </div>
    </section>
    <footer class="tariff_item__footer">
        <div *ngIf="data.calculation_period" class="calculation-period">{{data.calculation_period}}</div>
        <div *ngIf="data.calculation_result" class="calculation-result">
            <span class="updated-value">{{data.calculation_result}}</span>
            <span class="old-value">{{data.calculation_result_old}}</span>
        </div>
        <div class="empty" *ngIf="recalculationMode && data.name !== 'S'"></div>
        <button 
            *ngIf="data.button_text" 
            class="btn btn-success" 
            (click)="onClick(data)"
            [disabled]="!isStandalone ? (data | buttonDisabledPipe : currentPlan) : disabled"
        >
        {{recalculationMode ? 'Пересчитать' : (data | buttonTextPipe : currentPlan)}}
        </button>
    </footer>
</article>