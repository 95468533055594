
<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]='subtext'
/>
<div [ngStyle]="{ display: isLoading ? 'none' : 'block' }">
  <div class="app-page-title abc">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-users icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          <div>
            ABC анализ
            <i class="fa fa-question-circle" aria-hidden="true"
                 [pTooltip]="'Строится на основе реализации. Отчет по реализации доступен до ' + lastReportDate"></i>
          </div>
          <span routerLink="../../guides/products" style="color: #5046e5; font-size: 0.9rem; cursor: pointer; font-style: italic;">Добавить себестоимость</span>
        </div>
        <app-mp-video-play [videoLink]="getVideoLinkByPage('dashboard/abc')" />
        <app-mp-ngb-range-datepicker
          [initialData]="selectedDate"
          (selectedDateEmitter)="dateChanged($event)" 
        />
        <p-checkbox
          *ngIf="!isOzon"
          ngbTooltip='Компенсации по браку начисляются только на артикул, и по размерам не отображаются'
          placement="bottom"
          [(ngModel)]="apiRealizationChecked"
          [binary]="true"
          inputId="binary"
          label="Показать по размерам"
          (onChange)="onWbCheckboxChange($event)" 
        />
        <p-multiSelect
            (onChange)="selectStatus()"
            (onClear)="onClearStatuses()"
            [(ngModel)]="selectedStatuses"
            [maxSelectedLabels]="1"
            [options]="statusesList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
            defaultLabel="Статусы"
            emptyMessage="Статусов не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-multiSelect
            (onChange)="selectCategory()"
            (onClear)="onClearCategories()"
            [(ngModel)]="selectedCategories"
            [maxSelectedLabels]="1"
            [options]="categoriesList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
            defaultLabel="Категория собственная"
            emptyMessage="Категорий не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-checkbox
          *ngIf="!isOzon"
          ngbTooltip='Исключает строки "Реклама по удержаниям" и "Прочие удержания/выплаты"'
          placement="bottom"
          [(ngModel)]="checked"
          [binary]="true"
          inputId="binary"
          label="Показать рекламу по API"
          (onChange)="onCheckboxChange($event)" />
          <p-checkbox
          [(ngModel)]="fboFbsChecked"
          [binary]="true"
          inputId="binary"
          label="Показать остатки FBO+FBS"
          (onChange)="onFboFbsCheckboxChange($event)" 
        />
      </div>
    </div>
  </div>
  <div class="d-flex abc-cards">
    <app-mp-card-info
      *ngFor="let card of cards"
      [card]="card"
    >
    </app-mp-card-info>
    <app-mp-card-info
      *ngIf="otherDeductionsData"
      [card]="otherDeductionsData"
    >
    </app-mp-card-info>
  </div>
  <div class="main-card card mt-3">
    <div class="abc-table">
      <ag-grid-angular
        #agGrid
        (gridReady)="onGridReady($event)"
        (filterChanged)="onGridFilterChanged()"
        (dragStopped)="onDragStopped($event)"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [paginationPageSize]="20"
        [rowData]="rowDataGeneral"
        [tooltipShowDelay]="0"
        [domLayout]="'autoHeight'"
        [rowHeight]="30"
        [pinnedBottomRowData]="summaryData"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        headerHeight="28"
        style="width: 100%;">
      </ag-grid-angular>
      <button
        [pTooltip]='!isAllowed ? "Недоступно на бесплатном тарифе" : null'
        [class.btn-disabled]="!isAllowed"
        (click)="exportExcel()"
        class="mb-2 btn btn-success button-export-adv"
        type="button"
      >
        Скачать в Excel
      </button>
    </div>
  </div>
</div>
<p-toast/>
