<div class="app-page-title pnl-consolidation">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="btn-actions-pane-left" style="width: 100%;">
        <div class="block-order-ftype">
          <div class="page-title-icon">
            <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Консолидационный отчет <br />
            о прибылях и убытках
            <i
              class="fa fa-question-circle"
              aria-hidden="true"
              [pTooltip]="
                'Строится на основе реализации. Отчет по реализации доступен до ' +
                lastReportDate
              ">
            </i>
          </div>

          <app-mp-video-play [videoLink]="getVideoLinkByPage('fin/pnl')" />

          <app-mp-ngb-range-datepicker
            [initialData]="selectedDate"
            [rangeType]="ranges"
            (selectedDateEmitter)="dateChanged($event)" />
        </div>
      </div>
      <div class="pnl-consolidation__filters">
        <p-checkbox
          *ngIf="!isOzon"
          ngbTooltip='Исключает строку "Реклама по удержаниям"'
          placement="top"
          [(ngModel)]="checked"
          [binary]="true"
          inputId="binary"
          label="Показать рекламу по API"
          (onChange)="onCheckboxChange($event)" />
        <p-multiSelect
          (onChange)="loadPNLList()"
          [options]="suppliersList"
          [(ngModel)]="selectedSuppliers"
          [style]="{
            display: 'flex',
            alignItems: 'center',
            height: '32px',
          }"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите потавщика" 
        />
      </div>
    </div>
  </div>
</div>
<div class="card p-treetable" [style.width.px]="(pnlData?.columns.length) * 180 + 400" [style.maxWidth]="'100%'">
  <p-treeTable
    [columns]="pnlData?.columns"
    [frozenColumns]="[{ field: 'name', header: 'Статья' }]"
    [metaKeySelection]="true"
    [scrollable]="true"
    [value]="pnlData?.data"
    frozenWidth="400px"
    selectionMode="single"
    styleClass="p-treetable-sm">
    <ng-template let-columns pTemplate="colgroup">
      <colgroup>
        <col *ngFor="let col of columns" style="width: 180px"/>
      </colgroup>
    </ng-template>
    <ng-template let-columns pTemplate="header">
      <tr>
        <th
          *ngFor="let col of columns"
          style="
          font-size: 18px; 
          text-align: center; 
          font-weight: 500; 
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;"
          [class.bc-EBEBEB]="col.header === 'Итого'"
        >
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData="rowData" pTemplate="body">
      <tr>
        <td
          *ngFor="let col of columns; let i = index"
          class="columns-wrap rowGray"
          [class.fs-18]="rowData.name === 'Выручка'"
          [class.fs-16]="fs_16_headers.includes(rowData.name)"
          [class.bc-c3e9ecab]="bc_c3e9ecab_headers.includes(rowData.name)"
          [class.bc-fceed1b5]="bc_fceed1b5_headers.includes(rowData.name)"
          [class.bc-bce9dedb]="rowData.name === 'Выручка'"
          [class.bc-ebc3fab8]="rowData.name === 'Чистая прибыль ИТОГ'"
          [class.bc-EBEBEB]="col.header === 'Итого' && !colored_headers.includes(rowData.name)"
          [class.centered]="col.header === 'Итого'"
          [class.align-right]="col.header !== 'Итого'"
        >
          <span
            [class.fw-500]="fw_500_headers_.includes(rowData.name)"
            [class.fw-600]="rowData.name === 'Чистая прибыль ИТОГ'"
            [class.fs-18]="fs_18_headers.includes(rowData.name)"
            [class.fs-1rem]="!fs_18_headers.includes(rowData.name)"
            [class.mr-12]="col.header !== 'Итого'"
            [class.darkGreen]="rowData.name === 'Чистая прибыль ИТОГ' && rowData[col.field] > 0"
            [class.darkRed]="rowData.name === 'Чистая прибыль ИТОГ' && rowData[col.field] < 0"
            [class.color-grey]="color_grey_headers.includes(rowData.name)"
          >
            {{
              (rowData &&
                (rowData[col.field]?.toString()?.includes('%')
                  ? rowData[col.field]
                  : (rowData[col.field] | currency: '' : ''))) ||
                ''
            }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template let-rowData="rowData" let-rowNode pTemplate="frozenbody">
      <tr [ngClass]="{ height: '50px' }">
        <td class="flex items-center"
          [class.fs-18]="rowData.name === 'Выручка'"
          [class.bc-bce9dedb]="rowData.name === 'Выручка'"
          [class.bc-c3e9ecab]="bc_c3e9ecab_headers.includes(rowData.name)"
          [class.bc-fceed1b5]="bc_fceed1b5_headers.includes(rowData.name)"
          [class.bc-ebc3fab8]="rowData.name === 'Чистая прибыль ИТОГ'"
        >
          <p-treeTableToggler [rowNode]="rowNode" />
          <span
            [pTooltip]="rowData.name"
            [tooltipDisabled]="false"
            [class.color-grey]="color_grey_headers.includes(rowData.name)"
            class="special-headers"
            [class.fw-500]="fw_500_headers_.includes(rowData.name)"
            [class.fw-600]="rowData.name === 'Чистая прибыль ИТОГ'"
            [class.fs-18]="fs_18_headers.includes(rowData.name)"
            [class.fw-15]="!fs_18_headers.includes(rowData.name)"
          >
            {{ rowData.name }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
<button
  [pTooltip]='!isAllowed ? "Недоступно на бесплатном тарифе" : null'
  [class.btn-disabled]="!isAllowed"
  (click)="exportExcel()"
  class="btn btn-success button-export-fin-pnl"
  type="button"
>
  Скачать в Excel
</button>
<p-toast />
