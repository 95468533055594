import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cssBackgroundPipe'
})
export class CssBackgroundPipe implements PipeTransform {

  transform(value: number | string | null | { value: number, delta: number }): string | null {
    if(typeof value !== 'object' ) return null;

    console.log(value.delta, typeof value.delta)

    switch (true) {
      case typeof value.delta === 'object' && !value.delta:
        return '#e6babe';
      case +value.delta >= 0:
        return '#bce9de';
      case +value.delta < 0:
        return '#fceed1';
      default:
        return null
    }
  }
}
