import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ISupplierResponse,
  ISuppliers,
  IUserResponse,
} from './users.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IResponseCommon } from '../../../menu-tabs/settings/shop/shop.interface';
import { AbstractMenuTabComponent } from '../../../menu-tabs/abstract-menu-tab.component';
import { of, switchMap, tap } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass'],
  providers: [MessageService, ConfirmationService],
})
export class UsersComponent extends AbstractMenuTabComponent implements OnInit {
  readonly FormUser: FormGroup;

  usersList: IUserResponse[] = [];

  text = ''
  subtext = ''
  techAccessAlreadyAdded = false;
  userId = null

  get suppliersControls(): FormArray {
    return this.FormUser.controls.suppliers as FormArray;
  }

  get buttonIsInValid(): boolean {
    let suppliersData = this.FormUser.controls.suppliers.getRawValue();
    let phone = this.FormUser.controls.user_phone.getRawValue();
    let name = this.FormUser.controls.tech_user_name.getRawValue();
    let jobTitle = this.FormUser.controls.tech_user_job_title.getRawValue();

    if (phone && name && jobTitle && suppliersData.some(elem => elem.checked)) {
      return false
    }
    return true;
  }

  constructor() {
    super();
    this.FormUser = new FormGroup({
      user_phone: new FormControl(null, [
        Validators.required,
        Validators.minLength(11),
      ]),
      tech_user_name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(150),
      ]),
      tech_user_job_title: new FormControl(null, [Validators.maxLength(150)]),
      suppliers: new FormArray([]),
    });
  }

  ngOnInit(): void {
    this.loadUsers();
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(
        untilDestroyed(this),
        tap(res => {
           //@ts-ignore
          const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'profile/users') || { die_text: null, die_title: null }
          this.text = die_title
          this.subtext = die_text
          this.userId = res.data.user_id
          this.FormUser.reset();
        }),
        switchMap(() => this._mpSurfService.loadByOld({}, 'getDataShopWB', 'data'))
      )
      .subscribe(
        (shopList: ISupplierResponse[]) => {
          shopList.filter(item => item.user_id === this.userId).forEach(item => {
            this.suppliersControls.push(this.getRenderedFormGroup(item));
          });
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadSuppliers(): void {
    this.FormUser.reset();
    this._mpSurfService
      .loadByOld({}, 'getDataShopWB', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (shopList: ISupplierResponse[]) => {
          shopList.filter(item => item.user_id === this.userId).forEach(item => {
            this.suppliersControls.push(this.getRenderedFormGroup(item));
          });
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadUsers(): void {
    this._mpSurfService
      .loadByOld({}, 'accountUserList', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IUserResponse[]) => {
          this.techAccessAlreadyAdded = response.some(user => user.username === '+79032632693')
          this.usersList = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private getRenderedFormGroup(groupData: any): FormGroup {
    return new FormGroup({
      id: new FormControl(groupData.id),
      name_supplier: new FormControl(groupData.name),
      checked: new FormControl(false),
    });
  }

  createUpdateUser(config: object | null) {
    const data = this.FormUser.getRawValue();
    const activeSupplier = [];
    data.suppliers.forEach(user => {
      if (user.checked) {
        activeSupplier.push(user.id);
      }
    });
    const body = config || {
      phone: data.user_phone,
      tech_user_name: data.tech_user_name,
      tech_user_job_title: data.tech_user_job_title,
      shop_id_list: activeSupplier,
    };
    this._mpSurfService
      .loadByOld(body, 'accountAddUser', 'data')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: { is_error: number; msg: string }) => {
          this._modalService.dismissAll();
          if (response.is_error === 0) {
            this.loadUsers();
            this.showPopUpMessage(
              'success',
              this.isEditModal
                ? PopUpMessages.updateSuccessSummary
                : PopUpMessages.createSuccessSummary,
              ''
            );
          } else {
            this.showPopUpMessage(
              'error',
              'Ошибка добавления пользователя',
              response.msg
            );
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка добавления пользователя', ''),
      });
  }

  openUsersModal(parameter: string, modal: TemplateRef<any>, user?: any) {
    this.isEditModal = parameter === 'edit';
    if (parameter === 'create') {
      this.loadSuppliers();
      this.suppliersControls.clear();
    } 
    else {
      this.FormUser.controls.user_phone.setValue(user.username);
      this.FormUser.controls.tech_user_name.setValue(user.tech_user_name);
      this.FormUser.controls.tech_user_job_title.setValue(user.tech_user_job_title);
      const activeSuppliersFromServerID = user.suppliers.map(
        (user: ISuppliers) => user.id
      );
      this.suppliersControls.controls.forEach((control: FormGroup) => {
        if (activeSuppliersFromServerID.includes(control.value.id)) {
          control.controls.checked.setValue(true);
        }
      });
    }

    this._modalService.open(modal);
  }

  deleteUser(phone: string) {
    this._confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить пользователя ?',
      header: 'Подтверждение удаления',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      accept: () => {
        const body = { phone };
        this._mpSurfService
          .loadByOld(body, 'accountUserDelete', 'data')
          .pipe(untilDestroyed(this))
          .subscribe(
            (response: any) => {
              if (response.is_error === 0) {
                this.loadUsers();
                this.showPopUpMessage(
                  'success',
                  PopUpMessages.deleteSuccessSummary,
                  'Пользователь удален'
                );
              }
            },
            () => {
              this.showPopUpMessage(
                'error',
                PopUpMessages.deleteFailedSummary,
                PopUpMessages.deleteFailedMessage
              );
            }
          );
      },
      reject: () => {},
    });
  }

  addTechnicalAccess(): void {
    const body = {
      phone: '+79032632693',
      tech_user_name: 'MP Surf',
      tech_user_job_title: 'Тех. поддержка',
      shop_id_list: this.suppliersControls.controls.map(ctrl => ctrl.value.id)
    };
    this.createUpdateUser(body)
  }
}
