import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { ApiService } from './shared/services/api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDataShopWBList } from './menu-tabs/settings/shop/shop.interface';


@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
    title = 'MpSurf.ru сервис аналитики и статистики';

    online = true;

    constructor(public router: Router, private api: ApiService) {
    }

    ngOnInit(): void {
        this.isOnlineUser();
        this.api.userRegister('getDataShopWBList', localStorage.getItem('token'), '')
        .pipe(untilDestroyed(this))
        .subscribe((list: IDataShopWBList[]) => {
            const hasBrokenToken = list.some(item => !!item.refresh_token_url);
            list.forEach(item => {
                if(!!item.refresh_token_url){
                    localStorage.setItem('hasBrokenToken' + item.id, 'true')
                } else {
                    localStorage.removeItem('hasBrokenToken' + item.id)
                }
            })
            if(hasBrokenToken){
                localStorage.setItem('hasBrokenToken', 'true')
                this.router.navigate(['settings/shop']);
            } else {
                localStorage.removeItem('hasBrokenToken')
            }
        })
    }

    private isOnlineUser(): void {
        this.online = navigator.onLine;
        window.addEventListener('online', () => {
            this.online = true;
        });
        window.addEventListener('offline', () => {
            confirm('Пожалуйста, проверьте свое интернет соединение!');
            this.online = false;
        });
    }
}

