export const ADV_COLUMN_DEFAULT_DEF = {
    minWidth: 30,
    maxWidth: 400,
    sortable: true,
    resizable: true,
    suppressSizeToFit: true,
    suppressResize: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    headerClass: 'header-centered',
};
