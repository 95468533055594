export const DateSortFunc = (date1: string, date2: string): number => {

  return dateToMs(date1) - dateToMs(date2)

};

export function dateToMs(date: string): number {

  const [day, month, year] = date.split('.');

  const d = new Date(+year, +month-1, +day)

  return d.getTime()

}
  