import { Component, Inject, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { FORTH_RANGE_TYPE, THIRD_RANGE_TYPE } from 'app/shared/common-variables/time-ranges-date-picker';
import { ISelectedNgbDate } from 'app/shared/interfaces/common.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';
import { IArticlesPurchase } from 'app/menu-tabs/guides/finpurchase/finpurchase.interface';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { configureRows, configureRowsToShow, generateColumnDefs } from './rnp.func';
import { RNP_MOCK } from './rnp.mock';
import { forkJoin } from 'rxjs';
import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';


const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

interface RnpResponse {
  "totalPagesCount": number,  
  "pagination": number,    
  "data": {
    id: number,
    groups: any[],
    table: any[]
  }[]
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-rnp',
  templateUrl: './rnp.component.html',
  styleUrls: ['./rnp.component.scss'],
  providers: [MessageService, ConfirmationService,  { provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class RnpComponent extends AbstractMenuTabComponent implements OnInit {

  columnDefs1 = [];
  rowData1 = RNP_MOCK;
  rowData1ToShow = [];

  columnDefs2 = [];
  rowData2 = RNP_MOCK;
  rowData2ToShow = [];

  columnDefs4 = [];
  rowData4 = RNP_MOCK;
  rowData4ToShow = [];

  defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };

  readonly presets = THIRD_RANGE_TYPE;
  selectedDate: ISelectedNgbDate = this._sharedDataService.filterDate || {
    startDate: moment().subtract(14, 'day'),
    endDate: moment(),
  };

  selectedNm = [];
  selectedSubject = [];
  selectedCategories = [];
  selectedTags = [];

  selectedDateType = 1;
  selectedPage = 1;

  subjectsList: any[] = [];
  categoriesList = [];
  articles = [];
  tagsList = [];

  loading = false;
  paginationLength = 1;
  lastReportDate = '';

  text = '';
  subtext = '';

  isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;


  voronka_categories = []
  general_categories = []
  turnover_categories = []

  categories: any[] = [];

  gridApi1: GridApi;
  gridApi2: GridApi;
  gridApi4: GridApi;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document, 
    private readonly _renderer: Renderer2
  ) {
    super()
    this.gridOptions = {
      context: { componentParent: this },
    };
  }

  ngOnInit(): void {

    if(this.isOzon) {
      this._router.navigate(['dashboard/rates'])
    }
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data
    });

    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore 
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/rnp') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })

    if(!this.isOzon)  {
      this.loadTags()
    }
    this.loadArticles();
    this.loadSubjects();
    this.loadCategories();
  }

  dropGeneral(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.general_categories, event.previousIndex, event.currentIndex);
  }

  dropVoronka(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.voronka_categories, event.previousIndex, event.currentIndex);
  }

  dropTurnover(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.turnover_categories, event.previousIndex, event.currentIndex);
  }

  dateChanged($event: ISelectedNgbDate) {
    this._sharedDataService.setFilterDate($event)
    this.selectedDate = $event;
    this.loadPanels();
  }

  onGridReady1(params: GridReadyEvent<any>) {
    this.gridApi1 = params.api;
  }

  onGridReady2(params: GridReadyEvent<any>) {
    this.gridApi2 = params.api;
  }

  onGridReady4(params: GridReadyEvent<any>) {
    this.gridApi4 = params.api;

    this.gridApi
  }

  onPage(page: object): void {
    //@ts-ignore
    this.selectedPage = page.pageIndex + 1;
    this.loadPanels();
  }

  onDateTypeChange(): void {
    this.selectedPage = 1;
    this.loadPanels();
  }

  selectTag(): void {
    this.loadPanels();
  }

  loadTags(): void {
    this._mpSurfService
      .load({}, 'products/tags')
      .pipe(untilDestroyed(this))
      .subscribe(
        (list: string[]) => {
          this.tagsList = list.map(item => ({name: item}));;
        },
    );
  }

  loadPanels() {
    this.loading = true;
    const params = {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      categoryPnl: Array.isArray(this.selectedCategories) ? this.selectedCategories.map(s => s.name) : [],
      articles: Array.isArray(this.selectedNm) ? this.selectedNm.map(item => +item.code) : [],
      subjects: Array.isArray(this.selectedSubject) ? this.selectedSubject.map(item => item.code) : [],
      pagination: this.selectedPage,
      dateType: this.selectedDateType,
      tags: Array.isArray(this.selectedTags) ? this.selectedTags.map(s => s.name) : [],
    };

    forkJoin([
      this._mpSurfService.loadByPost({...params, id: 1}, 'rnp/table'),
      this._mpSurfService.loadByPost({...params, id: 2}, 'rnp/table'),
      this._mpSurfService.loadByPost({...params, id: 4}, 'rnp/table')
    ])
    .pipe(untilDestroyed(this))
    .subscribe({
      next: ([data1, data2, data4]: [RnpResponse, RnpResponse, RnpResponse]) => {
        const savedSettings = JSON.parse(localStorage.getItem('rnp_settings'))
        this.columnDefs1 = generateColumnDefs(data1.data[0].groups);
        this.rowData1 = !!savedSettings ? configureRows(savedSettings.slice(0, 41),  data1.data[0].table) : data1.data[0].table;
        this.general_categories = this.rowData1.map(data => {
          const checkStatus = savedSettings?.find(s => s['key'] === data['Показатели'])?.checked 
          const isChecked =  typeof checkStatus === 'boolean' ? checkStatus : true;
          return {name: data["Показатели"], key: data["Показатели"], checked: isChecked, disabled: false, dragDisabled: false}
        })
        this.columnDefs2 = generateColumnDefs(data2.data[0].groups);
        this.rowData2 = !!savedSettings ? configureRows(savedSettings.slice(41, 51),  data2.data[0].table) : data2.data[0].table;
        this.voronka_categories = this.rowData2.map(data => {
          const checkStatus = savedSettings?.find(s => s['key'] === data['Показатели'])?.checked 
          const isChecked =  typeof checkStatus === 'boolean' ? checkStatus : true;
          return {name: data["Показатели"], key: data["Показатели"], checked: isChecked, disabled: false, dragDisabled: false}
        })
        this.columnDefs4 = generateColumnDefs(data4.data[0].groups);
        this.rowData4 = !!savedSettings ? configureRows(savedSettings.slice(51),  data4.data[0].table) : data4.data[0].table;
        this.turnover_categories = this.rowData4.map(data => {
          const checkStatus = savedSettings?.find(s => s['key'] === data['Показатели'])?.checked 
          const isChecked =  typeof checkStatus === 'boolean' ? checkStatus : true;
          return {name: data["Показатели"], key: data["Показатели"], checked: isChecked, disabled: false, dragDisabled: false}
        })
        this.loading = false;
        this.paginationLength = data1.totalPagesCount;
        setTimeout(() => {
          this.gridApi1.setRowData(!!savedSettings ? configureRowsToShow(savedSettings.slice(0, 41), data1.data[0].table) : data1.data[0].table);
          this.gridApi2.setRowData(!!savedSettings ? configureRowsToShow(savedSettings.slice(41, 51), data2.data[0].table) : data2.data[0].table);
          this.gridApi4.setRowData(!!savedSettings ? configureRowsToShow(savedSettings.slice(51), data4.data[0].table) : data4.data[0].table);
        }, 50)
        this._cdr.markForCheck()
      },
      error: () => {
        this.loading = false;
      }
    })
  }

  private loadArticles() {
    this._mpSurfService
      .load({}, 'products/important')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IArticlesPurchase[]) => {
          this.articles = response.map((a) => {
            return {
              name: `${a.nmid} - ${a.SKU}`,
              code: a.nmid,
            };
          });
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadSubjects() {
    this._mpSurfService
      .load({}, 'products/subjects')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          //@ts-ignore
          this.subjectsList = response.map(item => ({name: item, code: item}));
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadCategories(): void {
    this._mpSurfService
      .load({}, 'products/category-pnl-list')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: string[]) => {
          this.categoriesList = response.map(r => ({name: r}));
        },
    );
  }

  clearArticles(): void {
    this.selectedNm = [];
    this.loadPanels();
  }

  clearSubjects(): void {
    this.selectedSubject = [];
    this.loadPanels();
  }

  clearCategories(): void {
    this.selectedCategories = [];
    this.loadPanels();
  }

  onClearTags(): void {
    this.selectedTags = [];
    this.loadPanels();
  }

  onOpened(): void {
    // this.loadPanels()
  }

  onCheckboxesChangeGeneral(event: any, key: string): void {
    this.general_categories = this.general_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesChangeVoronka(event: any, key: string): void {
    this.voronka_categories = this.voronka_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesChangeTurnover(event: any, key: string): void {
    this.turnover_categories = this.turnover_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesReset(): void {
    this.general_categories = this.general_categories.map((ctg, index )=> ({...ctg, checked: false}));
    this.voronka_categories = this.voronka_categories.map((ctg, index )=> ({...ctg, checked: false}));
    this.turnover_categories = this.turnover_categories.map((ctg, index )=> ({...ctg, checked: false}));
  }

  onCheckboxesAll(): void {
    this.general_categories = this.general_categories.map((ctg, index )=> ({...ctg, checked: true}));
    this.voronka_categories = this.voronka_categories.map((ctg, index )=> ({...ctg, checked: true}));
    this.turnover_categories = this.turnover_categories.map((ctg, index )=> ({...ctg, checked: true}));
  }

  onCheckboxesSave(): void {
    this.categories = [
      ...this.general_categories,
      ...this.voronka_categories,
      ...this.turnover_categories,
    ];
    localStorage.setItem('rnp_settings', JSON.stringify(this.categories));
    window.location.reload()
  }

  onCheckboxesRestore(): void {
    localStorage.removeItem('rnp_settings');
    window.location.reload()
  }

  openCustomizator(content: TemplateRef<any>) {
    this._modalService.open(content, {
      centered: true,
      windowClass: 'customizator-modal'
    });

    const dialogRef = this._document.querySelector('.modal-dialog');

    if (dialogRef) {
      this._renderer.setStyle(dialogRef, 'box-shadow', 'none');
      this._renderer.setStyle(dialogRef, 'max-width', '720px');
    }
  }
  
}