<section class="plan-fact-wrapper">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-users icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          <div>
            План - факт
            <i
              class="fa fa-question-circle"
              aria-hidden="true"
              pTooltip="Информация на листах основана на оперативных данных и может отличаться от еженедельных отчетов"></i>
          </div>
        </div>
        <app-mp-video-play
          [videoLink]="getVideoLinkByPage('dashboard/plan-fact')" />
        <button
          (click)="openCreatePlanModal(createFormTpl)"
          class="btn btn-success">
          + Добавить план
        </button>
      </div>
    </div>
  </div>
  <ag-grid-angular
    #agGrid
    (gridReady)="onGridReady($event)"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [enableRangeSelection]="true"
    [gridOptions]="gridOptionsPlanFact"
    [localeText]="localeTextForFilters"
    [pagination]="true"
    [paginationPageSize]="20"
    [rowData]="plan_facts"
    [tooltipShowDelay]="0"
    [domLayout]="'autoHeight'"
    [rowHeight]="40"
    overlayNoRowsTemplate="Нет данных для отображения"
    class="ag-theme-alpine"
    headerHeight="28"
    style="width: 100%" />
</section>

<div style="margin: 20px 0 15px 0" *ngIf="generalInfoMetadata">
  <span style="font-size: 1.2rem; font-weight: 700"
    >{{ generalInfoMetadata.plan_name }}
    {{ generalInfoMetadata.method === 1 ? 'По кабинету' : 'По артикулу' }}
    {{
      generalInfoMetadata.type === 1 ? 'По сумме заказов' : 'По сумме продаж'
    }}</span
  >

  <div *ngIf="generalInfoMetadata">
    Завершено <span>{{ generalInfoMetadata.been_days }}</span> дней из
    <span>{{ generalInfoMetadata.days }}</span> (осталось дней -
    <span>{{ generalInfoMetadata.left_days }}</span> )
  </div>
  <div>Факт без учета самовыкупов</div>
</div>

<section
  style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 12px">
  <table *ngIf="mappedData.length" class="table">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">План</th>
        <th scope="col">Факт</th>
        <th scope="col">Разница, руб</th>
        <th scope="col">Разница, %</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of mappedData">
        <th scope="col">{{ row.name }}</th>
        <th scope="col">
          {{ row.plan | number: '1.0-0' | mpUnit: 'rubles' }}
        </th>
        <th scope="col">
          {{ row.fact | number: '1.0-0' | mpUnit: 'rubles' }}
        </th>
        <th
          [class.red]="row.delta1 < 0"
          [class.green]="row.delta1 >= 0"
          scope="col">
          <span *ngIf="row.delta1 >= 0">+</span>
          {{ row.delta1 | number: '1.0-0' | mpUnit: 'rubles' }}
        </th>
        <th
          [class.red]="row.delta1 < 0"
          [class.green]="row.delta1 >= 0"
          scope="col">
          <span *ngIf="row.delta1 >= 0">+</span>
          {{ row.delta2 | number: '1.0-0' | mpUnit: 'percent' }}
        </th>
      </tr>
    </tbody>
  </table>

  <section class="plan-fact__cards">
    <app-mp-card-info *ngFor="let card of cards" [card]="card">
    </app-mp-card-info>
  </section>
</section>

<section class="plan-fact__chart">
  <apx-chart
    *ngIf="chartOptions"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
    [dataLabels]="chartOptions.dataLabels" />
</section>

<section *ngIf="!!columns?.length">
  <section style="margin: 8px; display: flex; align-items: center; gap: 8px">
    <p-selectButton
      [(ngModel)]="selectedUnitType"
      [options]="[
        { name: 'Разница, руб', value: 1 },
        { name: 'Разница, %', value: 2 },
      ]"
      optionLabel="name"
      optionValue="value"
      selectionMode="single"
      styleClass="dark-panel"
      [ngStyle]="{ width: '330px', display: 'block' }">
    </p-selectButton>
  </section>

  <section class="example-container">
    <mat-table [dataSource]="dataSource" class="mat-table">
      <ng-container
        *ngFor="let column of columns; let i = index"
        matColumnDef="{{ column.field }}"
        [sticky]="column.field === 'type'">
        <mat-header-cell
          [class.w250]="column.field === 'type'"
          [class.w100]="column.field !== 'type'"
          *matHeaderCellDef>
          {{
            column.field === 'type'
              ? ''
              : column.field === 'total'
                ? 'Итого'
                : column.field === 'plan_day'
                  ? 'План на день'
                  : column.field
          }}
        </mat-header-cell>

        <mat-cell
          *matCellDef="let row"
          [ngStyle]="{
            background: row[column.field] | cssBackgroundPipe: selectedUnitType,
          }"
          [class.border-right-black]="
            column.field === 'total' || column.field === 'plan_day'
          ">
          <span
            *ngIf="!isObject(row[column.field]) && !row.type.includes('шт')">
            {{ row[column.field] | number: '1.0-0' | mpUnit: 'rubles' }}
          </span>

          <span *ngIf="!isObject(row[column.field]) && row.type.includes('шт')">
            {{ row[column.field] | number: '1.0-0' | mpUnit: 'item' }}
          </span>

          <div *ngIf="isObject(row[column.field])">
            <span>{{
              row[column.field].value | number: '1.0-0' | mpUnit: 'rubles'
            }}</span
            ><br />
            <span style="font-size: 9px" *ngIf="selectedUnitType === 1">{{
              row[column.field].deltaInRubles
                | number: '1.0-0'
                | mpUnit: 'rubles'
            }}</span>
            <span style="font-size: 9px" *ngIf="selectedUnitType === 2">{{
              row[column.field].deltaInPercent
                | number: '1.0-0'
                | mpUnit: 'percent'
            }}</span>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

      <ng-container matColumnDef="typeHeader" sticky>
        <mat-cell
          style="border-right: 1px solid black"
          class="w250"
          *matCellDef="let group">
          {{ group.type }}
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

      <!-- Group header -->

      <ng-container matColumnDef="groupHeader" sticky>
        <mat-cell
          style="border-right: 1px solid black; width: 250px; min-width: 250px"
          *matCellDef="let group">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              font-weight: 600;
            ">
            <span>{{ group.type }}</span>
            <span
              *ngIf="!!group?.articles?.length"
              style="font-size: 1.7em; cursor: pointer"
              >{{ group.expanded ? '-' : '+' }}</span
            >
          </div>
        </mat-cell>
      </ng-container>

      <mat-row
        class="subrow"
        *matRowDef="let row; columns: dateColumns; when: isGroup"
        (click)="groupHeaderClick(row)">
      </mat-row>
    </mat-table>
  </section>
</section>
<p-toast />

<ng-template #createFormTpl>
  <form class="plan-fact-form" [formGroup]="createPlanFormGroup">
    <div class="plan-fact-form__item">
      <label for="plan_name">Название плана</label>
      <input
        pInputText
        id="plan_name"
        aria-describedby="username-help"
        formControlName="plan_name" />
    </div>
    <div class="plan-fact-form__item range__filters">
      <label for="start_date">Месяц планирования</label>
      <div class="range__filters_api-ra" ngbDropdown>
        <input
          ngbTooltip="Выбрать месяц"
          placement="top"
          readonly
          formControlName="dateFrom"
          id="dropdownBasic1"
          ngbDropdownToggle
          style="width: 250px"
          type="text"
          pInputText />
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <section
            style="
              display: flex;
              padding: 0 4px;
              justify-content: space-evenly;
            ">
            <div>
              <div
                class="radio-group"
                style="justify-content: flex-end"
                [class.selected]="
                  createPlanFormGroup.controls['monthFrom'].value === month.key
                "
                *ngFor="let month of months">
                <label>
                  <input
                    style="width: 0"
                    type="radio"
                    formControlName="monthFrom"
                    [value]="month.key" />
                  <span>
                    {{ month.name }}
                  </span>
                </label>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              ">
              <section>
                <div
                  class="radio-group"
                  style="justify-content: flex-start; width: 60px"
                  [class.selected]="
                    createPlanFormGroup.controls['yearFrom'].value === year.key
                  "
                  *ngFor="let year of years">
                  <label>
                    <input
                      style="width: 0"
                      type="radio"
                      formControlName="yearFrom"
                      [value]="year.key" />
                    <span>
                      {{ year.name }}
                    </span>
                  </label>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="plan-fact-form__item" style="width: 250px">
      <label for="method">Способ планирования</label>
      <p-dropdown
        id="method"
        [options]="methods"
        formControlName="method"
        optionLabel="name"
        optionValue="code" />
    </div>
    <div class="plan-fact-form__item">
      <div class="plan-fact-form__item-radio-group" *ngFor="let type of types">
        <p-radioButton
          [inputId]="type.code"
          [value]="type.code"
          formControlName="type" />
        <label [for]="type.name">
          {{ type.name }}
        </label>
      </div>
    </div>

    <span *ngIf="selectedMethodWhenCreate === 2">
      Выберите артикулы, проставив галочки в чекбоксах, укажите сумму плана в
      столбце "Планируемая сумма"
    </span>

    <div
      *ngIf="selectedMethodWhenCreate === 2 && !!modalRowData?.length"
      class="row plan-fact-form__table">
      <ag-grid-angular
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (selectionChanged)="onSelectionChanged($event)"
        [columnDefs]="modalColumnDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [suppressRowClickSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [paginationPageSize]="30"
        [domLayout]="'autoHeight'"
        [rowData]="modalRowData"
        [rowDragManaged]="true"
        [rowHeight]="30"
        [tooltipHideDelay]="tooltipHideDelay"
        [tooltipShowDelay]="tooltipShowDelay"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        rowSelection="multiple"
        style="width: 100%" />
    </div>

    <div class="plan-fact-form__item">
      <label for="goal"
        >Цель в рублях <strong>(итоговая сумма из плана)</strong></label
      >
      <p-inputNumber
        (onInput)="onGoalInputWhenCreate($event)"
        [readonly]="selectedMethodWhenCreate === 2"
        [class.plain-input]="selectedMethodWhenCreate === 2"
        id="goal"
        formControlName="goal_rub" />
    </div>

    <div class="plan-fact-form__footer">
      <button
        class="btn btn-primary"
        [disabled]="createPlanFormGroup.invalid"
        (click)="submitCreate()">
        Сохранить
      </button>
    </div>
  </form>
</ng-template>

<ng-template #updateFormTpl>
  <form class="plan-fact-form" [formGroup]="updatePlanFormGroup">
    <div class="plan-fact-form__item">
      <label for="plan_name">Название плана</label>
      <input
        pInputText
        id="plan_name"
        aria-describedby="username-help"
        formControlName="plan_name" />
    </div>
    <div class="plan-fact-form__item range__filters">
      <label for="start_date"><strong>Месяц планирования</strong></label>
      <div class="range__filters_api-ra" ngbDropdown>
        <input
          ngbTooltip="Выбрать месяц"
          placement="top"
          readonly
          formControlName="dateFrom"
          id="dropdownBasic1"
          ngbDropdownToggle
          style="width: 150px"
          type="text"
          pInputText />
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <section
            style="
              display: flex;
              padding: 0 4px;
              justify-content: space-evenly;
            ">
            <div>
              <div
                class="radio-group"
                style="justify-content: flex-end"
                [class.selected]="
                  updatePlanFormGroup.controls['monthFrom'].value === month.key
                "
                *ngFor="let month of months">
                <label>
                  <input
                    style="width: 0"
                    type="radio"
                    formControlName="monthFrom"
                    [value]="month.key" />
                  <span>
                    {{ month.name }}
                  </span>
                </label>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              ">
              <section>
                <div
                  class="radio-group"
                  style="justify-content: flex-start; width: 60px"
                  [class.selected]="
                    updatePlanFormGroup.controls['yearFrom'].value === year.key
                  "
                  *ngFor="let year of years">
                  <label>
                    <input
                      style="width: 0"
                      type="radio"
                      formControlName="yearFrom"
                      [value]="year.key" />
                    <span>
                      {{ year.name }}
                    </span>
                  </label>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="plan-fact-form__item" style="width: 280px;">
      <label for="method">Способ планирования</label>
      <section style="display: flex; align-items: center; gap: 8px;">
        <p-dropdown
          id="method"
          [options]="methods"
          [style]="{width: '250px'}"
          [readonly]="true"
          pTooltip="Способ планирования при редактировании плана изменить невозможно"
          [dropdownIcon]="faCheck"
          formControlName="method"
          optionLabel="name"
          optionValue="code"
        >
        </p-dropdown>
        <fa-icon style="font-size: 14px; color: green;" [icon]="faCheck"></fa-icon>
      </section>
    </div>
    <div class="plan-fact-form__item">
      <div class="plan-fact-form__item-radio-group" *ngFor="let type of types">
        <p-radioButton
          [inputId]="type.code"
          [value]="type.code"
          formControlName="type" />
        <label [for]="type.name">
          {{ type.name }}
        </label>
      </div>
    </div>

    <span *ngIf="selectedMethodWhenUpdate === 2">
      Выберите артикулы, проставив галочки в чекбоксах, укажите сумму плана в
      столбце "Планируемая сумма"
    </span>

    <div
      *ngIf="selectedMethodWhenUpdate === 2 && !!plan_fact"
      class="row plan-fact-form__table">
      <ag-grid-angular
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChangedWhenUpdate($event)"
        (selectionChanged)="onSelectionChangedWhenUpdate($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        [columnDefs]="modalColumnDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [suppressRowClickSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [paginationPageSize]="30"
        [domLayout]="'autoHeight'"
        [rowData]="modalRowData"
        [rowDragManaged]="true"
        [rowHeight]="40"
        [tooltipHideDelay]="tooltipHideDelay"
        [tooltipShowDelay]="tooltipShowDelay"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        rowSelection="multiple"
        style="width: 100%" />
    </div>

    <div class="plan-fact-form__item">
      <label for="goal"
        >Цель в рублях <strong>(итоговая сумма из плана)</strong></label
      >
      <p-inputNumber
        (onInput)="onGoalInputWhenUpdate($event)"
        [readonly]="selectedMethodWhenUpdate === 2"
        [class.plain-input]="selectedMethodWhenUpdate === 2"
        id="goal"
        formControlName="goal_rub" />
    </div>

    <div class="plan-fact-form__footer">
      <button
        class="btn btn-primary"
        [disabled]="updatePlanFormGroup.invalid"
        (click)="submitUpdate()">
        Сохранить
      </button>
    </div>
  </form>
</ng-template>

<ng-template #icon>
  +
</ng-template>
