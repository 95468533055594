<div class="app-page-title">
  <div class="page-title-wrapper d-flex justify-content-between">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-users icon-gradient bg-mean-fruit"></i>
      </div>
      <div class="icons-wrapper">Партнерская программа</div>
    </div>
  </div>
</div>

<div class="partners_page">
  <div class="partners_page__conditions">
    <h5>
      <strong
        >Присоединяйтесь к нашей программе! Предлагайте новые возможности
        клиентам и получайте постоянное вознаграждение с их оплат. Основные
        принципы сотрудничества:
      </strong>
    </h5>
    <ul class="partners_page__conditions_list">
      <li *ngFor="let condition of conditions">
        {{ condition.value }}
        <ul
          *ngIf="condition.children"
          class="partners_page__subconditions_list">
          <li *ngFor="let subCondition of condition.children">
            {{ subCondition.value }}
          </li>
        </ul>
      </li>
    </ul>
    <h5><strong>Дополнительно</strong></h5>
    <ul style="padding-left: 0;">
      <li>Нашим партнерам доступна расширенная поддержка и консультации по сервису.</li>
      <li>
        Делясь партнерским промокодом, вы принимаете условия публичной
        <a
          target="_blank"
          href="../../../../assets/images/download/partner_offer.pdf"
          >оферты на участие в партнерской программе.</a
        >
      </li>
      <li>
        Мы всегда открыты к сотрудничеству, если у вас есть предложения или
        остались вопросы, напишите нам в Telegram:
        <a href="https://t.me/mpsurf">https://t.me/mpsurf</a>
      </li>
    </ul>
    <button
      *ngIf="!isPromokodUsed"
      (click)="openModal(consent_modal)"
      class="btn btn-success btn-sm btn-action"
      type="button">
      Сгенерировать код
    </button>
  </div>
  <ng-container *ngIf="referalData">
    <div class="partners_page__promokod">
      <div class="partners_page__promokod_wrapper">
        <section class="left">
          <h4 class="partners_page__promokod_title"><strong>Ваш промокод</strong></h4>
          <section (click)="copyPromokod()" class="partners_page__promokod_value">
            <span>{{ referalData.cards.referral_promo }}</span><i class="fa fa-clone"></i>
          </section>
          <section class="partners_page__promokod_item">
            <span>Условия использования</span> <span>Для новых клиентов</span>
          </section>
          <section class="partners_page__promokod_item">
            <span>Дата выдачи</span>
            <span>{{ referalData.cards.referral_offer }}</span>
          </section>
          <section class="partners_page__promokod_item">
            <span>Условия для продавца</span> <span>10% со всех платежей</span>
          </section>
          <section class="partners_page__promokod_item">
            <span>Условия для клиента</span>
            <span>10% скидку на первую покупку</span>
          </section>
          <section class="partners_page__promokod_item">
            <span>Лимит</span> <span>Без лимита</span>
          </section>
        </section>
        <section class="right">
          <app-mp-card-info
            *ngFor="let card of cards"
            [card]="card"></app-mp-card-info>
        </section>
      </div>
    </div>
    <div class="partners_page__table">
      <ag-grid-angular
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultDef"
        [rowData]="rowData"
        [pagination]="true"
        [paginationPageSize]="30"
        [domLayout]="'autoHeight'"
        [enableCellTextSelection]="true"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        style="width: 100%" />
    </div>
  </ng-container>
</div>
<p-toast/>

<ng-template #consent_modal>
  <div class="consent_modal">
    <p-checkbox
      (onChange)="onConsentChange($event)"
      [(ngModel)]="isConsentChecked"
      [binary]="true"
      inputId="binary"
      label="Ознакомлен и согласен с условиями Публичной оферты на участие в реферальной программе" />
    <div class="consent_modal__links">
      <span
        ><a href="https://docs.google.com/document/d/1bjM7-VVBYrcoYQTDA3WUwduN0teBNIme/edit"
          >Партнёрская оферта</a
        ></span
      >
    </div>
    <button
      (click)="requestReferalData()"
      [disabled]="!isConsentChecked"
      class="btn btn-success btn-sm btn-action"
      type="button">
      Сгенерировать код
    </button>
  </div>
</ng-template>