<section class="plan-fact-wrapper">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-users icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          <div>
            План - факт
            <i
              class="fa fa-question-circle"
              aria-hidden="true"
              pTooltip="Информация на листах основана на оперативных данных и может отличаться от еженедельных отчетов"></i>
          </div>
        </div>
        <app-mp-video-play
          [videoLink]="getVideoLinkByPage('dashboard/plan-fact')" />
        <button (click)="openCreatePlanModal(createFormTpl)" class="btn btn-success">
          + Добавить план
        </button>
      </div>
    </div>
  </div>
  <ag-grid-angular
    #agGrid
    (gridReady)="onGridReady($event)"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [enableRangeSelection]="true"
    [gridOptions]="gridOptionsPlanFact"
    [localeText]="localeTextForFilters"
    [pagination]="true"
    [paginationPageSize]="20"
    [rowData]="plan_facts"
    [tooltipShowDelay]="0"
    [domLayout]="'autoHeight'"
    [rowHeight]="40"
    overlayNoRowsTemplate="Нет данных для отображения"
    class="ag-theme-alpine"
    headerHeight="28"
    style="width: 100%" />
</section>

<div style="margin: 20px 0 15px 0;" *ngIf="generalInfoMetadata">
  <span style="font-size: 1.2rem; font-weight: 700"
    >{{ generalInfoMetadata.plan_name }}
    {{ generalInfoMetadata.method === 1 ? 'По кабинету' : 'По артикулу' }}
    {{
      generalInfoMetadata.type === 1 ? 'По сумме заказов' : 'По сумме продаж'
    }}</span
  >

  <div *ngIf="generalInfoMetadata">
    Завершено <span>{{ generalInfoMetadata.been_days }}</span> дней из
    <span>{{ generalInfoMetadata.days }}</span> (осталось дней -
    <span>{{ generalInfoMetadata.left_days }}</span> )
  </div>

</div>

  <section
    style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 12px">
    <table *ngIf="mappedData.length" class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">План</th>
          <th scope="col">Факт</th>
          <th scope="col">Разница, руб</th>
          <th scope="col">Разница, %</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of mappedData">
          <th scope="col">{{ row.name }}</th>
          <th scope="col">
            {{ row.plan | number: '1.0-0' | mpUnit: 'rubles' }}
          </th>
          <th scope="col">
            {{ row.fact | number: '1.0-0' | mpUnit: 'rubles' }}
          </th>
          <th [class.red]="row.delta1 < 0" [class.green]="row.delta1 >= 0" scope="col">
            {{ row.delta1 | number: '1.0-0' | mpUnit: 'rubles' }}
          </th>
          <th [class.red]="row.delta1 < 0" [class.green]="row.delta1 >= 0" scope="col">
            {{ row.delta2 | number: '1.0-0' | mpUnit: 'percent' }}
          </th>
        </tr>
      </tbody>
    </table>

    <section class="plan-fact__cards">
      <app-mp-card-info *ngFor="let card of cards" [card]="card">
      </app-mp-card-info>
    </section>
  </section>

  <section class="plan-fact__chart">
    <apx-chart
      *ngIf="chartOptions"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels" 
    />
  </section>

  <section *ngIf="!!fullTableRows?.length">
    <section style="margin: 8px; display: flex; align-items: center; gap: 8px;">
      <p-selectButton
        [options]="[
          { name: 'Разница, руб', value: 1 },
          { name: 'Разница, %', value: 2 },
        ]"
        optionLabel="name"
        optionValue="value"
        selectionMode="single"
        styleClass="dark-panel"
        [ngStyle]="{ fontSize: '14px', width: '330px', display: 'block' }">
      </p-selectButton>
      <p-selectButton
        (onChange)="onDateTypeChange()"
        [(ngModel)]="selectedDateType"
        [options]="[
          { name: 'По дням', value: 1 },
          { name: 'По неделям', value: 2 },
          { name: 'По месяцам', value: 3 },
        ]"
        optionLabel="name"
        optionValue="value"
        selectionMode="single"
        styleClass="dark-panel"
        [ngStyle]="{ fontSize: '14px', width: '100%', display: 'block' }">
      </p-selectButton>
    </section>

    <section class="example-container">
      <mat-table [dataSource]="dataSource" class="mat-table">
        <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}" [sticky]="column.field === 'type'">
          <mat-header-cell [class.w250]="column.field === 'type'" [class.w100]="column.field !== 'type'"  *matHeaderCellDef>{{ column.field === "type" ? '' : column.field === "total" ? 'Итого' : column.field }}
          </mat-header-cell>
      
          <mat-cell *matCellDef="let row" [ngStyle]="{ background: row[column.field] | cssBackgroundPipe }">

            <span *ngIf="!isObject(row[column.field])">
              {{ row[column.field] }}
            </span>

            <div *ngIf="isObject(row[column.field])">
              <span>{{row[column.field].value | number: '1.0-0' | mpUnit: row[column.field].unit}}</span><br>
              <span>{{row[column.field].delta | number: '1.0-0' | mpUnit: row[column.field].unit}}</span>
            </div>

          </mat-cell>

        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

        <ng-container matColumnDef="typeHeader" sticky>
          <mat-cell style="border-right: 1px solid black;" class="w250" *matCellDef="let group">
            {{group.type}}
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>
      
        <!-- Group header -->

        <ng-container matColumnDef="groupHeader" sticky>
          <mat-cell style="border-right: 1px solid black; width: 250px; min-width: 250px;" *matCellDef="let group">
            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; font-weight: 600;">
              <span>{{group.type}}</span>
              <span style="font-size: 1.7em; cursor: pointer;">{{group.expanded ? '-' : '+'}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <mat-row class="subrow" *matRowDef="let row; columns: dateColumns; when: isGroup;" (click)="groupHeaderClick(row)"> </mat-row>
      
      </mat-table>
    </section>
  </section>
<p-toast/>

<ng-template #createFormTpl>
  <form class="plan-fact-form" [formGroup]="createPlanFormGroup">
    <div class="plan-fact-form__item">
      <label for="plan_name">Название плана</label>
      <input
        pInputText
        id="plan_name"
        aria-describedby="username-help"
        formControlName="plan_name" />
    </div>
    <div class="plan-fact-form__item">
      <label for="start_date">Дата начала плана</label>
      <input
        id="start_date"
        #datePicker="ngbDatepicker"
        readonly
        (click)="datePicker.toggle()"
        class="form-control"
        formControlName="start_date"
        ngbDatepicker
        placeholder="dd.mm.yyyy"
        style="width: 150px" 
      />
    </div>
    <div class="plan-fact-form__item">
      <label for="days">Срок, дней</label>
      <p-inputNumber id="days" formControlName="days" [min]="0" [max]="366" />
    </div>
    <div class="plan-fact-form__item">
      <label for="method">Способ планирования</label>
      <p-dropdown
        id="method"
        [options]="methods"
        formControlName="method"
        optionLabel="name"
        optionValue="code" />
    </div>
    <div class="plan-fact-form__item">
      <div
        class="plan-fact-form__item-radio-group"
        *ngFor="let type of types">
        <p-radioButton
          [inputId]="type.code"
          [value]="type.code"
          formControlName="type" />
        <label [for]="type.name">
          {{ type.name }}
        </label>
      </div>
    </div>

    <div *ngIf="selectedMethodWhenCreate === 2 && !!articlesFromBackend?.length" class="row plan-fact-form__table">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 40px;" scope="col">Убрать из планирования</th>
            <th scope="col">Артикул поставщика</th>
            <th style="width: 40px;" scope="col">Планируемая сумма (внесите значения)</th>
          </tr>
        </thead>
        <tbody>
          <tr
            formArrayName="articles"
            *ngFor="let article of articlesFromBackend; let i = index"
          >
            <ng-container [formGroupName]="i">
              <td>
                <p-checkbox
                  [binary]="true"
                  inputId="binary"
                  formControlName="remove_from_plan" />
              </td>
              <td>{{ article.name }}</td>
              <td>
                <input formControlName="plan_sum" type="number">
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="plan-fact-form__item">
      <label for="goal">Цель в рублях</label>
      <p-inputNumber
        (onInput)="onGoalInputWhenCreate($event)"
        [readonly]="selectedMethodWhenCreate === 2"
        id="goal"
        formControlName="goal_rub" />
    </div>

    <div class="plan-fact-form__footer">
      <button
        class="btn btn-primary"
        [disabled]="createPlanFormGroup.invalid"
        (click)="submitCreate()">
        Сохранить
      </button>
    </div>
  </form>
</ng-template>

<ng-template #updateFormTpl>
  <form class="plan-fact-form" [formGroup]="updatePlanFormGroup">
    <div class="plan-fact-form__item">
      <label for="plan_name">Название плана</label>
      <input
        pInputText
        id="plan_name"
        aria-describedby="username-help"
        formControlName="plan_name" />
    </div>
    <div class="plan-fact-form__item">
      <label for="start_date">Дата начала плана</label>
      <input
        id="start_date"
        #datePicker2="ngbDatepicker"
        readonly
        (click)="datePicker2.toggle()"
        class="form-control"
        formControlName="start_date"
        ngbDatepicker
        placeholder="dd.mm.yyyy"
        style="width: 150px" 
      />
    </div>
    <div class="plan-fact-form__item">
      <label for="days">Срок, дней</label>
      <p-inputNumber id="days" formControlName="days" [min]="0" [max]="366" />
    </div>
    <div class="plan-fact-form__item">
      <label for="method">Способ планирования</label>
      <p-dropdown
        id="method"
        [options]="methods"
        [readonly]="true"
        formControlName="method"
        optionLabel="name"
        optionValue="code" />
    </div>
    <div class="plan-fact-form__item">
      <div
        class="plan-fact-form__item-radio-group"
        *ngFor="let type of types">
        <p-radioButton
          [inputId]="type.code"
          [value]="type.code"
          formControlName="type" />
        <label [for]="type.name">
          {{ type.name }}
        </label>
      </div>
    </div>

    <div *ngIf="selectedMethodWhenUpdate === 2 && !!articlesFromPlan?.length" class="row plan-fact-form__table">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 40px;" scope="col">Убрать из планирования</th>
            <th scope="col">Артикул поставщика</th>
            <th style="width: 40px;" scope="col">Планируемая сумма (внесите значения)</th>
          </tr>
        </thead>
        <tbody>
          <tr
            formArrayName="articles"
            *ngFor="let article of articlesFromPlan; let i = index"
          >
            <ng-container [formGroupName]="i">
              <td>
                <p-checkbox
                  [binary]="true"
                  inputId="binary"
                  formControlName="remove_from_plan" />
              </td>
              <td>{{ article.name }}</td>
              <td>
                <input formControlName="plan_sum" type="number">
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="plan-fact-form__item">
      <label for="goal">Цель в рублях</label>
      <p-inputNumber
        (onInput)="onGoalInputWhenUpdate($event)"
        [readonly]="selectedMethodWhenUpdate === 2"
        id="goal"
        formControlName="goal_rub" />
    </div>

    <div class="plan-fact-form__footer">
      <button
        class="btn btn-primary"
        [disabled]="updatePlanFormGroup.invalid"
        (click)="submitUpdate()">
        Сохранить
      </button>
    </div>
  </form>
</ng-template>
