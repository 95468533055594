export const groups = [
  {
    "level": 1,
    "expanded": false,
    "total": 135,
    "type": "Заказы, руб",
    "01/09/2024": 135,
    "02/09/2024": 135,
    "03/09/2024": 135,
    "04/09/2024": 135,
    "05/09/2024": 135,
    "06/09/2024": 135,
    "07/09/2024": 135,
    "08/09/2024": 135,
    "09/09/2024": 135,
    "10/09/2024": 135,
    "11/09/2024": 135,
    "12/09/2024": 135,
    "13/09/2024": 135,
    "14/09/2024": 135,
    "15/09/2024": 135,
    "16/09/2024": 135,
    "17/09/2024": 135,
    "18/09/2024": 135,
    articles: [
      { type: 'Yubka 1', total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
      { type: 'Yubka 2', total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
    ]
  },
  {
    "level": 1,
    "expanded": false,
    "total": 1670,
    "type": "Заказы, шт",
    "01/09/2024": 135,
    "02/09/2024": 135,
    "03/09/2024": 135,
    "04/09/2024": 135,
    "05/09/2024": 135,
    "06/09/2024": 135,
    "07/09/2024": 135,
    "08/09/2024": 135,
    "09/09/2024": 135,
    "10/09/2024": 135,
    "11/09/2024": 135,
    "12/09/2024": 135,
    "13/09/2024": 135,
    "14/09/2024": 135,
    "15/09/2024": 135,
    "16/09/2024": 135,
    "17/09/2024": 135,
    "18/09/2024": 135,
    articles: [
      { type: 'Yubka 3', total: 835, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
      { type: 'Yubka 4', total: 835, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
    ]
  },
  {
    "level": 1,
    "expanded": false,
    "total": 135,
    "type": "Ср. цена заказа, руб",
    "01/09/2024": 135,
    "02/09/2024": 135,
    "03/09/2024": 135,
    "04/09/2024": 135,
    "05/09/2024": 135,
    "06/09/2024": 135,
    "07/09/2024": 135,
    "08/09/2024": 135,
    "09/09/2024": 135,
    "10/09/2024": 135,
    "11/09/2024": 135,
    "12/09/2024": 135,
    "13/09/2024": 135,
    "14/09/2024": 135,
    "15/09/2024": 135,
    "16/09/2024": 135,
    "17/09/2024": 135,
    "18/09/2024": 135,
    articles: [
      { type: 'Yubka 5', total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
      { type: 'Yubka 6', total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
    ]
  },
  {
    "level": 1,
    "expanded": false,
    "total": 135,
    "type": "Продажи, руб",
    "01/09/2024": 135,
    "02/09/2024": 135,
    "03/09/2024": 135,
    "04/09/2024": 135,
    "05/09/2024": 135,
    "06/09/2024": 135,
    "07/09/2024": 135,
    "08/09/2024": 135,
    "09/09/2024": 135,
    "10/09/2024": 135,
    "11/09/2024": 135,
    "12/09/2024": 135,
    "13/09/2024": 135,
    "14/09/2024": 135,
    "15/09/2024": 135,
    "16/09/2024": 135,
    "17/09/2024": 135,
    "18/09/2024": 135,
    articles: [
      { type: 'Yubka 7', total: 135, '01/09/2024': { value: 450000, delta: 5000, unit: 'rubles' }, '02/09/2024': { value: 4500, delta: -50, unit: 'rubles' }, '03/09/2024': { value: 4500, delta: null, unit: 'rubles' }, '04/09/2024': { value: 4500, delta: 0, unit: 'rubles' }, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
      { type: 'Yubka 8', total: 135, '01/09/2024': { value: 4000, delta: 400, unit: 'percent' }, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
    ]

  },
  {
    "level": 1,
    "expanded": false,
    "total": 135,
    "type": "Продажи, шт",
    "01/09/2024": 135,
    "02/09/2024": 135,
    "03/09/2024": 135,
    "04/09/2024": 135,
    "05/09/2024": 135,
    "06/09/2024": 135,
    "07/09/2024": 135,
    "08/09/2024": 135,
    "09/09/2024": 135,
    "10/09/2024": 135,
    "11/09/2024": 135,
    "12/09/2024": 135,
    "13/09/2024": 135,
    "14/09/2024": 135,
    "15/09/2024": 135,
    "16/09/2024": 135,
    "17/09/2024": 135,
    "18/09/2024": 135,
    articles: [
      { type: 7, total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
      { type: 8, total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
    ]

  },
  {
    "level": 1,
    "expanded": false,
    "total": 135,
    "type": "Ср. цена продажи, руб",
    "01/09/2024": 135,
    "02/09/2024": 135,
    "03/09/2024": 135,
    "04/09/2024": 135,
    "05/09/2024": 135,
    "06/09/2024": 135,
    "07/09/2024": 135,
    "08/09/2024": 135,
    "09/09/2024": 135,
    "10/09/2024": 135,
    "11/09/2024": 135,
    "12/09/2024": 135,
    "13/09/2024": 135,
    "14/09/2024": 135,
    "15/09/2024": 135,
    "16/09/2024": 135,
    "17/09/2024": 135,
    "18/09/2024": 135,
    articles: [
      { type: 9, total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
      { type: 10, total: 135, '01/09/2024': 45, '02/09/2024': 45, '03/09/2024': 45, '04/09/2024': 45, '05/09/2024': 45, '06/09/2024': 45,  '07/09/2024': 45, '08/09/2024': 45, '09/09/2024': 45, '10/09/2024': 45, '11/09/2024': 45, '12/09/2024': 45, '13/09/2024': 45, '14/09/2024': 45, '15/09/2024': 45, '16/09/2024': 45, '17/09/2024': 45, '18/09/2024': 45},
    ]
  },
]
