import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OAuthGuard {
  canActivate(): boolean {
    if(!!localStorage.getItem('hasBrokenToken')){
      return false;
    }
    return true;
  }
}

