<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-target icon-gradient bg-mean-fruit"></i>
      </div>
      <div style="margin-right: 8px;">
        Статистика РК
        <i class="fa fa-question-circle" aria-hidden="true"
          pTooltip="Данные на листе формируются по API Статистика РК"></i>
      </div>
      <app-mp-video-play [videoLink]="getVideoLinkByPage('adv/stats')" />
      <app-mp-ngb-range-datepicker [initialData]="selectedDate" (selectedDateEmitter)="dateChanged($event)" />
      <span style="width: 8px;"></span>
      <p-multiSelect
        (onChange)="onChange($event)"
        (onClear)="onClear()"
        [(ngModel)]="selectedNm"
        [maxSelectedLabels]="1"
        [options]="articles"
        [showClear]="true"
        [style]="{ display: 'flex', alignItems: 'center', height: '32px', width: '334px' }"
        defaultLabel="Артикул"
        emptyMessage="Артикулов не найдено"
        itemSize="30"
        optionLabel="name"
        selectedItemsLabel="Выбрано {0}" 
      />
    </div>
  </div>
</div>


<section class="chart-wrapper">
  <div *ngIf="chartOptions" style="background: #fff;">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels" 
      [chart]="{ height: 360 }"
    />
  </div>
</section>
<section style="margin-top: 16px;">
  <ag-grid-angular
    (gridReady)="onGridReady($event)"
    (filterChanged)="onGridFilterChanged()"
    [localeText]="localeTextForFilters"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [pagination]="true"
    [paginationPageSize]="15"
    [tooltipShowDelay]="0"
    [domLayout]="'autoHeight'"
    [enableCellTextSelection]="true"
    [pinnedBottomRowData]="summaryRow"
    overlayNoRowsTemplate="Нет данных для отображения"
    class="ag-theme-alpine"
    style="width: 100%;"
  />
</section>