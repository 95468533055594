import { ColGroupDef } from 'ag-grid-community';
import { ICardWidget } from '../main-test/main-test.interface';
import { IAbcRow } from './abs-analyst.interface';
import { ABC_COLOR_MAP, ABS_ANALYST_COLUMN_DEFS } from './abs-analyst.mock';

export function generateCardData(data: IAbcRow[]): ICardWidget[] {
  const TOTAL_SALES_AMOUNT = data
    .map(row => row.sales_count)
    .reduce((a, b) => {
      const safeA = !isNaN(+a) ? +a : 0;
      const safeB = !isNaN(+b) ? +b : 0;
      return safeA + safeB;
    }, 0);

  const TOTAL_SALES = data
    .map(row => row.sales)
    .reduce((a, b) => {
      const safeA = !isNaN(+a) ? +a : 0;
      const safeB = !isNaN(+b) ? +b : 0;
      return safeA + safeB;
    }, 0);

  const TOTAL_PROFIT = data
    .map(row => row.profit)
    .reduce((a, b) => {
      const safeA = !isNaN(+a) ? +a : 0;
      const safeB = !isNaN(+b) ? +b : 0;
      return safeA + safeB;
    }, 0);

  const AAA_ROWS = data.filter(row => row.abc === 'AAA');

  const CCC_ROWS = data.filter(row => row.abc === 'CCC');

  const ABC_ROWS = data.filter(row => row.abc !== 'CCC' && row.abc !== 'AAA');

  const REDUCED_AAA_ROWS = AAA_ROWS.reduce(
    (acc, curr) => {
      return {
        ...acc,
        itemsCount: (acc.itemsCount += 1),
        salesAmount: (acc.salesAmount += !isNaN(+curr.sales_count)
          ? +curr.sales_count
          : 0),
        sales: (acc.sales += !isNaN(+curr.sales) ? +curr.sales : 0),
        profit: (acc.profit += !isNaN(+curr.profit) ? +curr.profit : 0),
      };
    },
    {
      itemsCount: 0,
      salesAmount: 0,
      sales: 0,
      profit: 0,
    }
  );

  const REDUCED_ABC_ROWS = ABC_ROWS.reduce(
    (acc, curr) => {
      return {
        itemsCount: (acc.itemsCount += 1),
        salesAmount: (acc.salesAmount += !isNaN(+curr.sales_count)
          ? +curr.sales_count
          : 0),
        sales: (acc.sales += !isNaN(+curr.sales) ? +curr.sales : 0),
        profit: (acc.profit += !isNaN(+curr.profit) ? +curr.profit : 0),
      };
    },
    {
      itemsCount: 0,
      salesAmount: 0,
      sales: 0,
      profit: 0,
    }
  );

  const REDUCED_CCC_ROWS = CCC_ROWS.reduce(
    (acc, curr) => {
      return {
        itemsCount: (acc.itemsCount += 1),
        salesAmount: (acc.salesAmount += !isNaN(+curr.sales_count)
          ? +curr.sales_count
          : 0),
        sales: (acc.sales += !isNaN(+curr.sales) ? +curr.sales : 0),
        profit: (acc.profit += !isNaN(+curr.profit) ? +curr.profit : 0),
      };
    },
    {
      itemsCount: 0,
      salesAmount: 0,
      sales: 0,
      profit: 0,
    }
  );

  return [
    {
      title: 'Товарная группа AAA',
      subfields: [
        {
          name: 'Количество товаров',
          value: REDUCED_AAA_ROWS.itemsCount,
          unit: 'item',
          ratioInPercents: Math.floor(
            (REDUCED_AAA_ROWS.itemsCount / data.length) * 100
          ),
        },
        {
          name: 'Количество продаж',
          value: REDUCED_AAA_ROWS.salesAmount,
          unit: 'item',
          ratioInPercents: Math.floor(
            (REDUCED_AAA_ROWS.salesAmount / TOTAL_SALES_AMOUNT) * 100
          ),
        },
        {
          name: 'Выручка',
          value: REDUCED_AAA_ROWS.sales,
          unit: 'rubles',
          ratioInPercents: Math.floor(
            (REDUCED_AAA_ROWS.sales / TOTAL_SALES) * 100
          ),
        },
        {
          name: 'Валовая прибыль',
          value: REDUCED_AAA_ROWS.profit,
          unit: 'rubles',
          ratioInPercents: Math.floor(
            (REDUCED_AAA_ROWS.profit / TOTAL_PROFIT) * 100
          ),
          description:
            'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)',
        },
      ],
      description:
        'Наиболее ценные позиции. Они хорошо оборачиваются, приносят наибольшую прибыль, но при этом на них тратится меньше ресурсов',
      totalCount: null,
      totalSum: null,
    },
    {
      title: 'Товарная группа ABA-CBC',
      subfields: [
        {
          name: 'Количество товаров',
          value: REDUCED_ABC_ROWS.itemsCount,
          unit: 'item',
          ratioInPercents: Math.floor(
            (REDUCED_ABC_ROWS.itemsCount / data.length) * 100
          ),
        },
        {
          name: 'Количество продаж',
          value: REDUCED_ABC_ROWS.salesAmount,
          unit: 'item',
          ratioInPercents: Math.floor(
            (REDUCED_ABC_ROWS.salesAmount / TOTAL_SALES_AMOUNT) * 100
          ),
        },
        {
          name: 'Выручка',
          value: REDUCED_ABC_ROWS.sales,
          unit: 'rubles',
          ratioInPercents: Math.floor(
            (REDUCED_ABC_ROWS.sales / TOTAL_SALES) * 100
          ),
        },
        {
          name: 'Валовая прибыль',
          value: REDUCED_ABC_ROWS.profit,
          unit: 'rubles',
          ratioInPercents: Math.floor(
            (REDUCED_ABC_ROWS.profit / TOTAL_PROFIT) * 100
          ),
          description:
            'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)',
        },
      ],
      description:
        'Промежуточные позиции. Эти товары не приносят большую прибыль, но не слишком дорого обходятся бизнесу',
      totalCount: null,
      totalSum: null,
    },
    {
      title: 'Товарная группа CCC',
      subfields: [
        {
          name: 'Количество товаров',
          value: REDUCED_CCC_ROWS.itemsCount,
          unit: 'item',
          ratioInPercents: Math.floor(
            (REDUCED_CCC_ROWS.itemsCount / data.length) * 100
          ),
        },
        {
          name: 'Количество продаж',
          value: REDUCED_CCC_ROWS.salesAmount,
          unit: 'item',
          ratioInPercents: Math.floor(
            (REDUCED_CCC_ROWS.salesAmount / TOTAL_SALES_AMOUNT) * 100
          ),
        },
        {
          name: 'Выручка',
          value: REDUCED_CCC_ROWS.sales,
          unit: 'rubles',
          ratioInPercents: Math.floor(
            (REDUCED_CCC_ROWS.sales / TOTAL_SALES) * 100
          ),
        },
        {
          name: 'Валовая прибыль',
          value: REDUCED_CCC_ROWS.profit,
          unit: 'rubles',
          ratioInPercents: Math.floor(
            (REDUCED_CCC_ROWS.profit / TOTAL_PROFIT) * 100
          ),
          description:
            'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)',
        },
      ],
      description:
        'Наименее ценные позиции. Товары, которые не приносят ощутимого дохода',
      totalCount: null,
      totalSum: null,
    },
  ];
}

export function generateSummary(data: IAbcRow[]) {
  const rows = Array.isArray(data) ? data : [];
  const sales = rows.map(row => +row.sales).reduce((prev, curr) => prev + curr, 0);
  const profit = rows.map(row => +row.profit).reduce((prev, curr) => prev + curr, 0)
  return [
    {
      photo_new: 'empty',
      nmID: 'Итого',
      sales,
      orders: rows
        .map(row => +row.orders)
        .reduce((prev, curr) => prev + curr, 0),
      hranenie: rows
        .map(row => +row.hranenie)
        .reduce((prev, curr) => prev + curr, 0),
      orders_sum: rows
        .map(row => +row.orders_sum)
        .reduce((prev, curr) => prev + curr, 0),
      sales_from_orders: rows
        .map(row => +row.sales_from_orders)
        .reduce((prev, curr) => prev + curr, 0),
      sales_count: rows
        .map(row => +row.sales_count)
        .reduce((prev, curr) => prev + curr, 0),
      profit,
      cost_price: rows
        .map(row => +row.cost_price)
        .reduce((prev, curr) => prev + curr, 0),
      logistic: rows
        .map(row => +row.logistic)
        .reduce((prev, curr) => prev + curr, 0),
      commission: rows
        .map(row => +row.commission)
        .reduce((prev, curr) => prev + curr, 0),
      acquiring: rows
        .map(row => +row.acquiring)
        .reduce((prev, curr) => prev + curr, 0),
      adv: rows.map(row => +row.adv).reduce((prev, curr) => prev + curr, 0),
      other_deductions: rows
        .map(row => +row.other_deductions)
        .reduce((prev, curr) => prev + curr, 0),
      total_stocks: rows
        .map(row => +row.total_stocks)
        .reduce((prev, curr) => prev + curr, 0),
      avg_orders_day: rows
        .map(row => +row.avg_orders_day)
        .reduce((prev, curr) => prev + curr, 0),
      unit_percent_profit: (profit / sales) * 100
    },
  ];
}


export function generate_columns_defs(isOzon: boolean): ColGroupDef[] {
  const settings = localStorage.getItem('abc_settings_2');

  const WBDEF:ColGroupDef = {
    children: [
      {
        headerName: '',
        field: 'photo_new',
        width: 30,
        cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
        pinned: 'left',
        cellRenderer: (params) => {
          if(params.data.photo_new === 'empty'){
            return ''
          }
          else if(!params.data.photo_new){
            return `<img style="height: 30px; width: 30px" src="/assets/images/nophoto.jpg" />`
          }
          return `<img style="height: 30px; width: 30px" src=${params.data.photo_new} />`
        } 
      },
      {
        headerName: 'Артикул МП',
        cellStyle: { textAlign: 'left' },
        cellRenderer: (params) => {
          if(params.data.nmID === 'Итого') {
            return `<strong>Итого</strong>`
          }
          return !isOzon 
          ? `<strong><a href="https://www.wildberries.ru/catalog/${params.data.nmID}/detail.aspx?targetUrl=SP" target="blank">${params.data.nmID}</a></strong>`
          : params.data.nmID
  
        },
        field: 'nmID',
        width: 100,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Предмет',
        cellStyle: { textAlign: 'left' },
        field: 'object',
        width: 120,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Артикул поставщика',
        cellStyle: { textAlign: 'left' },
        field: 'sku',
        width: 200,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'ABC',
        field: 'abc',
        width: 70,
        filter: 'agTextColumnFilter',
        pinned: 'left',
        cellStyle: params => {
          return ABC_COLOR_MAP.get(params.value) || {'background-color': 'null'}
        }
      },
    ]
  }

  if (!settings) {
    return [
      isOzon ? ABS_ANALYST_COLUMN_DEFS[0] : WBDEF,
      ABS_ANALYST_COLUMN_DEFS[1],
      ABS_ANALYST_COLUMN_DEFS[2],
      ABS_ANALYST_COLUMN_DEFS[3],
      ABS_ANALYST_COLUMN_DEFS[4],
    ] 
  }

  const parsed = JSON.parse(settings)

  const result =  [
    {
      //@ts-ignore
      children: parsed.filter(p => p.checked && ABS_ANALYST_COLUMN_DEFS[0].children.map(child => child.field).includes(p.key)).map(r => ABS_ANALYST_COLUMN_DEFS[0].children.find(ch => ch.field === r.key))
    },
    {
      headerName: 'ABC Выручка',
      //@ts-ignore
      children: parsed.filter(p => p.checked && ABS_ANALYST_COLUMN_DEFS[1].children.map(child => child.field).includes(p.key)).map(r => ABS_ANALYST_COLUMN_DEFS[1].children.find(ch => ch.field === r.key))
    },
    {
      headerName: 'ABC Валовая прибыль',
      //@ts-ignore
      children: parsed.filter(p => p.checked && ABS_ANALYST_COLUMN_DEFS[2].children.map(child => child.field).includes(p.key)).map(r => ABS_ANALYST_COLUMN_DEFS[2].children.find(ch => ch.field === r.key))
    },
    {
      headerName: 'АВС Оборачиваемость',
      //@ts-ignore
      children: parsed.filter(p => p.checked && ABS_ANALYST_COLUMN_DEFS[3].children.map(child => child.field).includes(p.key)).map(r => ABS_ANALYST_COLUMN_DEFS[3].children.find(ch => ch.field === r.key))
    },
    {
      headerName: 'Юнит-экономика (Затраты на ед.)',
      //@ts-ignore
      children: parsed.filter(p => p.checked && ABS_ANALYST_COLUMN_DEFS[4].children.map(child => child.field).includes(p.key)).map(r => ABS_ANALYST_COLUMN_DEFS[4].children.find(ch => ch.field === r.key))
    },
  ];
  return result;
}
