import { Pipe, PipeTransform } from '@angular/core';
import {
  ICurrentTariff,
  ITariffItem,
} from '../tariff.interface';

@Pipe({
  name: 'buttonTextPipe',
})
export class ButtonTextPipe implements PipeTransform {
  transform(plan: ITariffItem, currentPLan: ICurrentTariff): string {
    switch (true) {
      case plan.name === currentPLan.name:
        return 'Продлить';
      case plan.weight >= currentPLan.value:
        return 'Перейти';
      default:
        return 'Несовместим';
    }
  }
}
