<form [formGroup]="form">
  <div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
    <div class="app-header__content">
      <div class="app-header-left">
        <app-mega-menu />
      </div>


      <div class="app-header-right">
        <img [src]="supplierMarket === 2 ? 'assets/images/ozon-logo.webp' : 'assets/images/wb-logo.jpg'"
             class="marketIcon"
             alt="logo">
        <div *ngIf="allSuppliers && !checkWidth()"
             class="wrapper-dropdown">
          <p-dropdown
            (onChange)="onSelectedChange()"
            [options]="allSuppliers"
            [showClear]="false"
            [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
            emptyMessage="Нет добавленных поставщиков"
            formControlName="activeSupplier"
            placeholder="Поставщик"
            styleClass="inner-dropdown">
            <ng-template let-supplier pTemplate="item">
              <div class="flex items-center"
                   [pTooltip]="supplier.label">
                <img
                  [src]="supplier.value?.marketplace === 2 ? 'assets/images/ozon-logo.webp' : 'assets/images/wb-logo.jpg'"
                  alt="Supplier Image"
                  style="width: 25px; margin-right: 13px; border-radius: 10px;">
                <span class="threeDots">
                  {{ supplier.label }}
                </span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="icon">
          <a href="https://t.me/+C-XyQFXtDR5mMDI6" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="assets/images/tg2.svg#tg2"></use>
            </svg>
           </a>
        </div>
        <div class="icon">
          <a href="https://rutube.ru/plst/476486/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="assets/images/University.svg#university"></use>
            </svg>
          </a>
        </div>
        <app-notification-dots></app-notification-dots>
        <app-user-box></app-user-box>
      </div>
    </div>

    <div class="app-header__mobile-menu">
      <button (click)="toggleSidebarMobile()"
              [ngClass]="{ 'is-active': globals.toggleSidebarMobile }"
              class="hamburger close-sidebar-btn hamburger--elastic"
              type="button"
      >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
      </button>
    </div>

    <div class="app-header__menu">
      <div class="wrapper-dropdown">
        <p-dropdown
          (onChange)="onSelectedChange()"
          *ngIf="checkWidth()"
          [options]="allSuppliers"
          [showClear]="false"
          [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
          emptyMessage="Нет добавленных поставщиков"
          formControlName="activeSupplier"
          placeholder="Поставщик"
          styleClass="inner-dropdown" />
      </div>
      <span>
      <button (click)="toggleHeaderMobile()"
              [ngClass]="{ 'is-active': globals.toggleHeaderMobile }"
              class="btn-icon btn-icon-only btn btn-primary"
      >
        <div class="btn-icon-wrapper">
          <fa-icon [icon]="faEllipsisV" />
        </div>
      </button>
    </span>
    </div>
  </div>
</form>





