<div class="slider-light">
  <!-- <div class="slider-intro">
    <span class="slider-intro-lg">MP SURF</span>
  </div> -->
  <ngx-slick-carousel
    class="slick-slider slick-dotted"
    #slickModal="slick-carousel"
    [config]="slideConfig2"
  >
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg">
        </div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-01.png" alt="Финансовая отчетность">
          <h5>Финансовая отчетность</h5>
          <h6>Без понимания бизнеса на уровне цифр невозможно построить стабильную компанию и регулярно выводить дивиденды. Наш сервис помогает решить эту проблему.</h6>
          <h6>Более 67% селлеров увеличило свою прибыль, внедрив управленческий учет в сервисе MP SURF.</h6>
          <h6>Наш сервис выбирают финансовые директоры компании Нескучные финансы и селлеры ТОП-100 WB.</h6>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg"></div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-02.png" alt="Финансовая отчетность">
          <h5>Воронка продаж</h5>
          <h6>Полный контроль над прибылью: самая подробная воронка продаж - от переходов до чистой прибыли (58 метрик).</h6>
          <h6>Все конверсии на каждом этапе продаж - найди точку роста прибыли.</h6>
          <h6>Сэкономь до 40 часов в неделю на сбор и сведение финансовой отчетности своего бизнеса.</h6>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg opacity-6"></div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-03.png" alt="Финансовая отчетность">
          <h5>Аналитика продаж и заказов</h5>
          <h6>Контролируй Долю Рекламных Расходов (ДРР%) от заказов каждый день, чтобы ДРР% от продаж из этих заказов не заминусил чистую прибыль.</h6>
          <h6>БЕСПЛАТНО отслеживай ДРР% по установленным лимитам в MP SURF каждый день!</h6>
          <h6>ДРР% от заказов и продаж по каждому артикулу и дню.</h6>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg opacity-6"></div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-04.png" alt="Финансовая отчетность">
          <h5>Управленческие решения</h5>
          <h6>АВС-анализ и ЮНИТ-экономика.</h6>
          <h6>Простой интерфейс, все важные метрики по выручке, прибыли, оборачиваемости: по каждому артикулу/размеру/категории + % выкупа по доехавшим, ДРР% за любой период.</h6>
          <h6>Узнай, сколько прибыли приносит тебе каждый артикул или группа товаров.</h6>
          <h6>Посмотри сколько забирает себе Маркетплейс - комиссии, логистики и реклама.</h6>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
