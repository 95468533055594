import { Component, HostBinding, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../shared/theme-options/theme-options';
import { FILTER_PARAMS_FIELDS } from '../../shared/utils/constants/filter-params-controller';
import { FormControl, FormGroup } from '@angular/forms';
import { ILabelSupplier } from './header.interface';
import { ISupplierResponse } from '../profile/users/users.interface';
import { ApiService } from 'app/shared/services/api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @select('config') public config$: Observable<any>;

  isActive: boolean;

  allSuppliers: ILabelSupplier[] = [];

  readonly form = new FormGroup({
    activeSupplier: new FormControl(null),
  });

  readonly faEllipsisV = faEllipsisV;

  supplierMarket = 1;

  constructor(public globals: ThemeOptions,private api: ApiService) {}

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  ngOnInit() {
    this.checkWidth();

    const token = localStorage.getItem('token')

    this.api.userRegister('getDataShopWB', token, '').pipe(untilDestroyed(this))
    .subscribe(list => {
      localStorage.setItem('supplier', JSON.stringify(list));
      this.allSuppliers = list.map((item: any) => ({ label: item.name, value: item }));
      if (localStorage.getItem('mpall_shop')) {
        const item = this.allSuppliers.find(
          (element) => element.value.id === JSON.parse(localStorage.getItem('mpall_shop')).shop,
        );
  
        this.supplierMarket = item.value.marketplace;
        this.form.controls.activeSupplier.patchValue(item.value);
      }
    })
  }

  onSelectedChange() {
    const supplierData = this.form.getRawValue().activeSupplier;
    if(supplierData) {
      localStorage.setItem(
        'mpall_shop',
        JSON.stringify({
          shop: supplierData?.id,
          name: supplierData?.name,
          marketPlace: supplierData?.marketplace,
        }),
      )
    } else {
      this.form.controls.activeSupplier.reset()
    }
    
    sessionStorage.removeItem(FILTER_PARAMS_FIELDS.supplierManagerFilterParams);
    sessionStorage.removeItem(FILTER_PARAMS_FIELDS.purchaseFilterParams);
    window.location.reload();
  }

  checkWidth() {
    if (window.innerWidth < 800) {
      return true;
    }
  }

  checkWidthLogo() {
    if (window.innerWidth < 400) {
      return true;
    }
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }
}
