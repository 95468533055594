import {inject, Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()

export class AuthGuard implements CanActivate, CanActivateChild {
  authService = inject(AuthService);
  router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const logged = this.authService.isAuthenticated();
    if (logged) {
      return true;
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: {accessDenited: 1}
      });
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
