import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { IPlanFactRowData } from "../plan-fact.interface";
import { PlanFactComponent } from "../plan-fact.component";

@Component({
  selector: "app-button-renderer",
  template: `
    <button (click)="onRowClick(this.id)" style="font-size: .7rem; height: 36px; margin-top: -4px" class="btn btn-primary">Показать</button>
  `,
})
export class ButtonRendererComponent implements ICellRendererAngularComp {

  id: number | null = null;

  componentParent: PlanFactComponent

  start = ''

  agInit(params: ICellRendererParams<IPlanFactRowData>): void {
    this.id = params.data.id;
    this.componentParent = params.context.componentParent;
    this.start = params.data.start_date.split('.')[1];
  }

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  onRowClick(id: number): void {
    this.componentParent.onRowClick(id, this.start)
  }
}