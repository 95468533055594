import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../shared/theme-options/theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Nullable } from '../../shared/interfaces/common.type';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  activeId = 'dashboards';
  hideBlocksForProd: Nullable<boolean> = true; // 1 - Display full menu | 0 - Display prod menu
  isAdmin = JSON.parse(localStorage.getItem('is_admin')) === 1;
  isOzon = false;
  isProd = environment.production

  private newInnerWidth: number;

  private innerWidth: number;

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.newInnerWidth = event.target.innerWidth;
    this.globals.toggleSidebar = this.newInnerWidth < 1200;
  }

  ngOnInit() {

    if(localStorage.getItem('mpall_shop')) {
      this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
      setTimeout(() => {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 1200) {
          this.globals.toggleSidebar = true;
        }
      });
      this.collapseCurrentMenuTab();
      this.checkTabMenu();
    }

  }

  closePerfectScrollBar() {
    if (window.innerWidth < 600) {
      this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
      this.globals.sidebarHover = !this.globals.sidebarHover;
    }
  }

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  navigateToHome() {
    this.router.navigate(['/dashboard/rates']);
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }

  private checkTabMenu() {
    const isTestStatus = JSON.parse(
      localStorage.getItem('is_test')
    ) as Nullable<number>;
    if (typeof isTestStatus !== 'number') {
      this.hideBlocksForProd = true;
    } else {
      this.hideBlocksForProd = isTestStatus === 0;
    }
  }

  private collapseCurrentMenuTab() {
    const URL_PATH =
      this.activatedRoute.snapshot.firstChild?.routeConfig?.path || '';
    if (URL_PATH) {
      switch (URL_PATH) {
        case '/external-analysis/compare':
          this.extraParameter = 'panel-campair';
          break;
        case 'fin/dds':
          this.extraParameter = 'fin_reports';
          break;
        case 'fin/settings/items':
        case 'fin/accounts':
        case 'fin/partners':
          this.extraParameter = 'fin_settings';
          break;
        default:
          this.extraParameter = 'guides/products';
      }
    }
  }
}
