import { ColDef } from "ag-grid-community";
import { ButtonRendererComponent } from "./buttons/button-renderer.component";
import { PlanFactControlsRendererComponent } from "./buttons/controls-renderer.component";
import { IGeneralInfoTableItem } from "./plan-fact.interface";
import { DecimalPipe } from "@angular/common";

const decimalPipe = new DecimalPipe('ru-RU')



export const GENERAL_INFO_COLUMN_DEFS: ColDef<IGeneralInfoTableItem>[] = [

    {
        headerName: '',
        field: 'name',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },
    {
        headerName: '',
        field: 'sum_day',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },
    {
        headerName: '',
        field: 'sum_today',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },
    {
        headerName: '',
        field: 'name',
        cellStyle: { textAlign: 'center' },
        width: 140,
    },

]

export const PLAN_FACT_COLUMN_DEFS: ColDef[] = [

    {
        headerName: '',
        width: 140,
        cellRenderer: ButtonRendererComponent,
    },
    {
        headerName: 'Дата формирования плана',
        field: 'created_at',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Название плана',
        field: 'plan_name',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Дата начала',
        field: 'start_date',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Срок, дней',
        field: 'days',
        width: 142,
        filter: 'agNumberColumnFilter',
    },
    {
        headerName: 'Цель',
        field: 'type',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Способ планироования',
        field: 'method',
        width: 160,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Цель в рублях',
        field: 'goal_rub',
        width: 162,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
    },
    {
        headerName: 'Статус',
        field: 'status',
        width: 100,
    },
    {
        headerName: '',
        width: 140,
        cellRenderer: PlanFactControlsRendererComponent,
    },
]