import { DecimalPipe } from "@angular/common";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { NumberSortFunc } from "app/shared/utils/functions/number-sort-func";

const decimalPipe = new DecimalPipe('ru-RU')

export function genarateColumnDefs(percent: number): ColDef[] {
    const defs: ColDef[] = [
        {
            headerName: 'Фото',
            headerClass: 'header-centered',
            field: 'photo',
            cellRenderer: params => {
              const url = params.data.photo
              return `<img style="height: 40px; width: 40px" src=${url} />`;
            },
          },
        {
            field: 'date',
            headerName: 'Дата рекламы',
            sort: 'desc',
        },
        {
            field: 'nmId',
            headerName: 'Артикул МП',
            headerClass: 'header-centered',
        },
        {
            field: 'sku',
            headerName: 'Артикул поставщика',
            headerClass: 'header-centered',
        },
        {
            field: 'subject',
            headerName: 'Наименование',
            headerClass: 'header-centered',
        },
        {
            field: 'orders_count',
            headerName: 'Заказы, шт.',
            headerClass: 'header-centered',
            comparator: NumberSortFunc,
            valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
        },
        {
            field: 'orders_sum',
            headerName: 'Заказы, руб.',
            headerClass: 'header-centered',
            comparator: NumberSortFunc,
            valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
        },
        {
            field: 'updSum',
            headerName: 'Реклама затраты, руб.',
            headerClass: 'header-centered',
            comparator: NumberSortFunc,
            valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
        },
        {
            field: 'order_price',
            headerName: 'Цена заказа (CPL)',
            headerClass: 'header-centered',
            comparator: NumberSortFunc,
            valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
        },
        {
            field: 'drr',
            headerName: 'ДРР (по заказам), %',
            headerClass: 'header-centered',
            cellStyle: params => {
                if (!params.value && +params.data.orders_sum <= 0 ) {
                    return {backgroundColor: '#e6babe'};//+
                } 
                else if (params.value >= 0 && params.value <= percent) {
                    return {backgroundColor: '#bce9de'};//+
                }
                else if (params.value > percent) {
                    return {backgroundColor: '#fceed1'};//+
                }
              return null;
            },
            comparator: NumberSortFunc,
            valueFormatter: v => decimalPipe.transform(v.value, '1.1-1')
        },
        {
            field: 'advertId',
            headerName: 'Рекламные кампании',
            headerClass: 'header-centered',
        },
    ];

    return defs

    // const storedDefs = localStorage.getItem('adv-column-defs');

    // if(!storedDefs) {
    //     return defs
    // }
    // const newDefs: ColDef[] = []
    // const parsedDefs = JSON.parse(storedDefs) as ColDef[]

    // parsedDefs.forEach(def => newDefs.push(defs.find(d => d.field === def.field)))
    // return newDefs;
}