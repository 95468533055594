import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ProductIdComponent } from './products-id/product-id.component';
import { ProductsComponent } from '../products.component';

@Component({
  selector: 'app-sebes-controls-renderer',
  template: `
    <div style="display: flex; align-items: center">
      <button
        (click)="onDeleteClick()"
        class="icon-button"
        style="border: none; background: transparent;">
        <i class="lnr-trash"></i>
      </button>
    </div>
  `,
})
export class SebesDeleteControlsRendererComponent
  implements ICellRendererAngularComp
{
  id: number | null = null;

  componentParent: ProductsComponent;

  agInit(params: ICellRendererParams<any>): void {
    this.componentParent = params.context.componentParent;
    this.id = params.data.id;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onDeleteClick(): void {
    this.componentParent.onDeleteClick(this.id);
  }
}
